export function handleFilterQueryParameter(menu, tab, params) {
  let url = `/${menu}/${tab}`;
  let firstParam = true;

  if (params.country !== null && params.country !== undefined) {
    url += firstParam ? '?' : '&';
    url += `country=${params.country}`;
    firstParam = false;
  }

  if (params.state !== null && params.state !== undefined) {
    url += firstParam ? '?' : '&';
    url += `state=${params.state}`;
    firstParam = false;
  }

  if (params.plant !== null && params.plant !== null) {
    url += firstParam ? '?' : '&';
    url += `plant=${params.plant}`;
    firstParam = false;
  }

  if (params.lineNo !== null && params.lineNo !== undefined) {
    url += firstParam ? '?' : '&';
    url += `lineNo=${params.lineNo}`;
    firstParam = false;
  }

  if (params.machineModel !== null && params.machineModel !== undefined) {
    url += firstParam ? '?' : '&';
    url += `model=${params.machineModel}`;
    firstParam = false;
  }

  if (params.serialNo !== null && params.serialNo !== undefined) {
    url += firstParam ? '?' : '&';
    url += `serialNo=${params.serialNo}`;
    firstParam = false;
  }

  if (params.connectivityStatus !== null && params.connectivityStatus !== undefined) {
    url += firstParam ? '?' : '&';
    url += `connectivityStatus=${params.connectivityStatus}`;
    firstParam = false;
  }

  return url;
}

export function handleURLRouteParameter(menu, tab) {
  let url = new URLSearchParams(window.location.search);
  const searchParameter = url.get('searchBy');
  const id = url.get('id');
  const plant = url.get('plant');
  const serialNo = url.get('serialNo');
  const model = url.get('model');
  const lineNo = url.get('lineNo');

  url = `/${menu}/${tab}`;
  if (
    searchParameter !== null
      && searchParameter !== undefined
      && menu !== 'reports'
      && menu !== 'settings'
      && menu !== 'admin'
  ) {
    url += `?searchBy=${searchParameter}`;
  }

  if (menu === 'monitoring' && id !== null && id !== undefined) {
    url += `?id=${id}`;
  }

  if (
    menu === 'monitoring'
      && (plant !== null || serialNo !== null || model !== null || lineNo !== null)
  ) {
    url = handleFilterQueryParameter(menu, tab, {
      mode: null,
      machineModel: model,
      plant,
      lineNo,
      serialNo,
      status: null,
    });
  }
  return url;
}
