import React, { useState, useContext, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LanguageContext } from '../../../LanguageContext';
import CircularIndeterminate from '../../../common/CircularIndeterminate';
import PaginationTab from '../../../common/PaginationTab/PaginationTab';
import Icon from '../../Dashboard/Sidebar/images/Icon';
import {
  fetchNotificationTemplateAsync, saveNotificationTemplateAsync, deleteNotificationTemplateAsync, getTemplatePlaceHolderAsync, getAccessRoleAsync,
} from '../../../redux/slices/notificationTemplateSlice';
import styles from './notification-template.module.css';
import ConfirmationDialog from '../../../common/ConfirmationDialog/ConfirmationDialog';
import EditNotificationTemplateModal from './edit-notification-template';
// import {
//   getUserId,
// } from '../../../services/getUserPreference.service';
import getItem from '../../../helper/getLocalStorageItem';

/* eslint no-nested-ternary:0 */
/* eslint prefer-destructuring:0 */
/* eslint no-shadow:0 */

const NotificationTemplate = () => {
  const searchBox = useRef();
  // const [dispatchAuditLog] = useContext(AuditLogContext);
  // const { setSelectedTab, setActivePage } = pc;
  // const history = useHistory();
  const dispatch = useDispatch();
  // const [Lang] = useContext(LanguageContext);

  const rowsPerPage = 15;
  const defaultCardCount = rowsPerPage;

  const [updatePopup, setUpdatePopup] = useState({ value: false });
  const [selectedData, setSelectedData] = useState({});
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  // const [load, setLoad] = useState(false);
  const [load] = useState(false);
  const [startCount, setStartCount] = useState(1);
  const [totalCount, setTotalCount] = useState(defaultCardCount);
  const [editMode, setEditMode] = useState(false);
  const [userDetails, setUserDetails] = useState(false);
  const [placeholders, setPlaceHolders] = useState(false);

  const [data, setData] = useState(1);
  const [currentData, setCurrentData] = useState(null);
  const [showConfirmation, setConfirmation] = useState({});

  useSelector((state) => {
    const dt = state.notificationTemplate.notificationTemplate;
    if (dt === data) return;
    setData(dt);
    setMaxPage(Math.ceil(dt.length / defaultCardCount));
    setCurrentData(dt.slice(0, defaultCardCount));
    // setLoad(true);
  });
  // let placeholders =  [{TemplatePlaceholder_ID:1, Name:"Machine 001", Placeholder:"@Machine001"}, {TemplatePlaceholder_ID:1, Name:"Machine 002", Placeholder:"@Machine002"}];

  const Previous = (pageNumber) => {
    if (pageNumber - 1 > 0) {
      const currentList = data.slice(
        (pageNumber - 2) * defaultCardCount,
        (pageNumber - 1) * defaultCardCount,
      );
      setCurrentData(currentList);
      setPage(pageNumber - 1);
      if (pageNumber === 2) {
        setStartCount(1);
        setTotalCount(
          data.length < defaultCardCount ? data.length : defaultCardCount,
        );
      } else {
        setStartCount(startCount - defaultCardCount);
        if (pageNumber === maxPage) {
          setTotalCount(defaultCardCount - 1 - (defaultCardCount - startCount));
        } else {
          setTotalCount(totalCount - defaultCardCount);
        }
      }
    }
  };

  const Next = (pageNumber) => {
    if (pageNumber + 1 <= maxPage) {
      const currentList = data.slice(
        pageNumber * defaultCardCount,
        (pageNumber + 1) * defaultCardCount,
      );
      setCurrentData(currentList);
      setPage(pageNumber + 1);
      if (pageNumber + 1 === maxPage) {
        setStartCount(data.length - currentList.length + 1);
        setTotalCount(data.length);
      } else {
        setStartCount(startCount + defaultCardCount);
        if (data.length > defaultCardCount + totalCount) {
          setTotalCount(totalCount + defaultCardCount);
        } else setTotalCount(data.length);
      }
    }
  };

  async function fetchData() {
    if (data !== 1) return;
    // setLoad(true);
    const searchKey = searchBox ? (searchBox.current ? searchBox.current.value : '') : '';
    dispatch(fetchNotificationTemplateAsync({
      id: '', searchKey, pageIndex: '', pageCount: '',
    }));
  }

  async function showEditMode() {
    if (!placeholders) {
      const ph = await dispatch(getTemplatePlaceHolderAsync());
      if (ph.error) setPlaceHolders([]);
      else setPlaceHolders(ph.payload);
    }
    setEditMode(true);
  }

  function onEditClicked(e) {
    const index = parseInt(e.currentTarget.parentNode.parentNode.getAttribute('index'), 10) + startCount - 1;
    setSelectedData(data[index]);
    setUpdatePopup({ value: true });
    showEditMode();
  }

  function addEntryBtnClicked() {
    setSelectedData({});
    showEditMode();
  }

  function showDialog(title, text, onDone) {
    setConfirmation({
      show: true,
      title,
      text,
      onClose: () => {
        setConfirmation({ show: false });
      },
      onDone: () => {
        onDone();
        setConfirmation({ show: false });
      },
    });
  }

  function onDeleteClicked(e) {
    const { id } = e.currentTarget.parentNode.parentNode;
    const seletedEntry = data.filter((e) => e.NotificationTemplate_ID === +id);
    showDialog('Confirm', 'Are you sure want to delete this?', async () => {
      const response = await dispatch(deleteNotificationTemplateAsync(seletedEntry[0].NotificationTemplate_ID));
      if (response.error && response.error.name.toLowerCase() === 'error') return;
      setData(1);
    });
  }

  let searchDelayTimer;
  function onSearchTextChanged(e) {
    const text = e.target.value;
    clearTimeout(searchDelayTimer);
    searchDelayTimer = setTimeout(() => {
      dispatch(fetchNotificationTemplateAsync({
        id: '', searchKey: text, pageIndex: '', pageCount: '',
      }));
    }, 500);
  }

  const onEditPopUpSaveInvoked = async (currentData, name, type, subject, content) => {
    if (data.filter((d) => d.TemplateName.toLowerCase() === name.toLowerCase()).length > (currentData.NotificationTemplate_ID ? 1 : 0)) return { Result: false, Message: 'Name already exist.' };

    let currentUser = userDetails;
    if (!currentUser) {
      const userEmail = getItem('email'); // 'sanchay.shinde@scitechcentre.com';
      // console.log('userEmail', userEmail);
      const userResponseData = await dispatch(getAccessRoleAsync(userEmail));
      // console.log('userResponseData', userResponseData);
      currentUser = userResponseData.payload.res[0];
      setUserDetails(currentUser);
    }

    const newData = {
      TemplateName: name, Subject: subject, CommunicationType: type, Contents: content,
    };
    if (currentData.NotificationTemplate_ID) {
      newData.NotificationTemplate_ID = currentData.NotificationTemplate_ID;
      // newData.ModifiedUser_ID = currentUser.UserID;
      newData.ModifiedUser_ID = localStorage.getItem('UserKey');
    } else {
      // newData.CreatedUser_ID = currentUser.UserID;
      newData.CreatedUser_ID = localStorage.getItem('UserKey');
    }
    const response = await dispatch(saveNotificationTemplateAsync(newData));
    // console.log('response', response);
    if (response.error && response.error.name.toLowerCase() === 'error') return { Result: false };
    setData(1);
    setSelectedData({});
    setEditMode(false);
    return { Result: true };
  };

  const onEditPopUpCloseInvoked = () => {
    setEditMode(false);
  };

  fetchData();
  console.log('editMode', editMode, selectedData);
  return (
    <>
      <div className="wrapper cm-wrapper">
        <div className="contentWrap conditionMonitoring monitoringcontentWrap monitoringPage notification-template">
          <div className={styles.headContainer}>
            <div className="tabArea">
              <ul id="navList" className="nav nav-tabs tabsName">
                <li className="active">
                  <a data-toggle="tab">Notification Template</a>
                </li>
              </ul>
              <div className="tab-content">
                <div className="tab-pane fade in active">
                  {/* <div className="alarmBar padTopOnly row dropRow"> */}
                  <div className="alarmBar padTopOnly cmRow dropRow">
                    <div className="input-group pull-right search-container">
                      <Icon name="searchIcon" />
                      <input
                        ref={searchBox}
                        className="form-control py-2 customForm input-md search-box"
                        type="search"
                        placeholder="Search by Communication, Subject and Template Name"
                        onChange={onSearchTextChanged}
                      />
                    </div>
                    <button type="button" className="pull-right btn btn-secondary add-entry-button" onClick={addEntryBtnClicked}>
                      <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.99951 0.666504V11.3332" stroke="#5D97F6" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M0.666504 6H11.3332" stroke="#5D97F6" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>

                      Add Entry
                    </button>
                  </div>
                  <div className="alertDivsBg">
                    <div className="alertDivs">
                      <div className={`headerTable  ${styles.headerTable}`}>
                        <span className="row-0">Communication</span>
                        <span className="row-1">Subject</span>
                        <span className="row-2">Template Name</span>
                        <span className="row-3">Actions</span>
                      </div>

                      {data.length > 0 ? (
                        <div
                          className={`pannelWrap overflowScrolls common-scroll ${styles.fullHeight}`}
                          id="pannelWrap"
                        >
                          {currentData.map((row, i) => (
                            <div className="panel panel-default alarmRows" key={i}>
                              <div className="panel-heading">
                                <h4 className="panel-title">
                                  <div id={row.NotificationTemplate_ID} index={i}>
                                    <span className="row-0">{row.CommunicationType === 'E' ? 'Email' : 'SMS'}</span>
                                    <span className="row-1">{row.Subject}</span>
                                    <span className="row-2">{row.TemplateName}</span>
                                    <div className="row-3 edit">
                                      <span onClick={onEditClicked}><Icon name="EditIcon" /></span>
                                      <span onClick={onDeleteClicked}><Icon name="DeleteIcon" /></span>
                                    </div>
                                  </div>
                                </h4>
                              </div>
                            </div>
                          ))}
                        </div>
                      ) : load ? (
                        <div className="pannelWrap overflowScrolls" id="pannelWrap">
                          <div
                            className="loaderHistoryAlarms"
                          >
                            <CircularIndeterminate />
                          </div>
                        </div>
                      ) : (
                        // <div className="noHistoryAlarms">{Lang.NoRecord}</div>
                        <div className="noHistoryAlarms">No Record Available</div>
                      )}
                    </div>
                    {data.length > defaultCardCount
                      && (
                        <div className="alarm-pagination float-container">
                          <div className="panellefttext">
                            <div className="shownotext">
                              {` ${startCount} to ${totalCount} of ${data.length} `}
                            </div>
                          </div>
                          <div className="panelrighttext">
                            <PaginationTab
                              Previous={Previous}
                              Next={Next}
                              page={page}
                              maxPage={maxPage}
                            />
                          </div>
                        </div>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
      {showConfirmation.show
        && (
          <ConfirmationDialog
            onClosed={showConfirmation.onClose}
            title={showConfirmation.title}
            text={showConfirmation.text}
            onDoneClicked={showConfirmation.onDone}
          />
        )}
      {editMode && <EditNotificationTemplateModal placeholders={placeholders} currentDetails={selectedData} onClosed={onEditPopUpCloseInvoked} onSaved={onEditPopUpSaveInvoked} needsUpdate={updatePopup} />}
    </>
  );
};

export default NotificationTemplate;
