export default function LogoutIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M4.72474 4.78853C4.98513 5.04884 4.9852 5.47095 4.7249 5.73134C3.97919 6.47729 3.47141 7.4276 3.26577 8.46212C3.06012 9.49664 3.16584 10.5689 3.56956 11.5433C3.97328 12.5178 4.65686 13.3506 5.53389 13.9366C6.41091 14.5225 7.44199 14.8353 8.49675 14.8353C9.55151 14.8353 10.5826 14.5225 11.4596 13.9366C12.3366 13.3506 13.0202 12.5178 13.4239 11.5433C13.8277 10.5689 13.9334 9.49664 13.7277 8.46212C13.5221 7.4276 13.0143 6.47729 12.2686 5.73134C12.0083 5.47095 12.0084 5.04884 12.2688 4.78853C12.5291 4.52822 12.9513 4.52829 13.2116 4.78868C14.1437 5.72112 14.7784 6.90901 15.0355 8.20216C15.2925 9.49531 15.1604 10.8356 14.6557 12.0537C14.1511 13.2717 13.2966 14.3128 12.2003 15.0452C11.104 15.7777 9.8152 16.1686 8.49675 16.1686C7.1783 16.1686 5.88945 15.7777 4.79317 15.0452C3.69689 14.3128 2.84241 13.2717 2.33776 12.0537C1.83311 10.8356 1.70096 9.49531 1.95802 8.20216C2.21508 6.90901 2.8498 5.72112 3.78193 4.78868C4.04224 4.52829 4.46435 4.52822 4.72474 4.78853Z"
          fill="#5D97F6"
        ></path>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M8.50016 1.50002C8.86835 1.50002 9.16683 1.79849 9.16683 2.16668V8.83335C9.16683 9.20154 8.86835 9.50002 8.50016 9.50002C8.13197 9.50002 7.8335 9.20154 7.8335 8.83335V2.16668C7.8335 1.79849 8.13197 1.50002 8.50016 1.50002Z"
          fill="#5D97F6"
        ></path>
      </g>
      <defs>
        <clipPath id="clipcp2">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0.5 0.833344)"
          ></rect>
        </clipPath>
      </defs>
    </svg>
  );
}
