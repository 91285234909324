export default function UserIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.47631 11.143C4.10143 10.5179 4.94928 10.1667 5.83333 10.1667H11.1667C12.0507 10.1667 12.8986 10.5179 13.5237 11.143C14.1488 11.7681 14.5 12.6159 14.5 13.5V14.8333C14.5 15.2015 14.2015 15.5 13.8333 15.5C13.4651 15.5 13.1667 15.2015 13.1667 14.8333V13.5C13.1667 12.9696 12.956 12.4609 12.5809 12.0858C12.2058 11.7107 11.6971 11.5 11.1667 11.5H5.83333C5.3029 11.5 4.79419 11.7107 4.41912 12.0858C4.04405 12.4609 3.83333 12.9696 3.83333 13.5V14.8333C3.83333 15.2015 3.53486 15.5 3.16667 15.5C2.79848 15.5 2.5 15.2015 2.5 14.8333V13.5C2.5 12.616 2.85119 11.7681 3.47631 11.143Z"
        fill="#5D97F6"
      ></path>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.49984 3.50001C7.39527 3.50001 6.49984 4.39544 6.49984 5.5C6.49984 6.60457 7.39527 7.5 8.49984 7.5C9.60441 7.5 10.4998 6.60457 10.4998 5.5C10.4998 4.39544 9.60441 3.50001 8.49984 3.50001ZM5.1665 5.5C5.1665 3.65906 6.65889 2.16667 8.49984 2.16667C10.3408 2.16667 11.8332 3.65906 11.8332 5.5C11.8332 7.34095 10.3408 8.83334 8.49984 8.83334C6.65889 8.83334 5.1665 7.34095 5.1665 5.5Z"
        fill="#5D97F6"
      ></path>
    </svg>
  );
}

