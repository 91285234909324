/* eslint no-tabs:0 */
/* eslint no-unused-vars:0 */

import React, {
  useState, useEffect, useRef,
} from 'react';
// import { AuditLogContext } from '../../../context/auditLogList';
// import { actionLog } from '../../../auditlog/events/event';
// import { logName } from '../../../config/configuration';

const DropdownFilter = ({
  filterOptions,
  setFilter,
  currentState,
  setFilterVal,
  // logNameValue,
  filter,
}) => {
  console.log('dropDownFilter', filterOptions, setFilter, currentState, setFilterVal);

  const [expanded, setExpanded] = useState(false);
  const [options, setOptions] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState(currentState);
  //   const [auditLogList, dispatchAuditLog] = useContext(AuditLogContext);
  const node = useRef();

  const handleClickRef = (e) => {
    if (!node.current.contains(e.target)) {
      setExpanded(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickRef);
    setOptions(filterOptions);

    return () => {
      document.removeEventListener('mousedown', handleClickRef);
    };
  }, [filter]);

  return options.length > 0 ? (
    <>
      <div className="dropdownheight noselect" ref={node}>
        <div className="form-group">
          <div className={`dropdown ${expanded ? 'open' : ''}`}>
            <button
              className="btn btn-default dropdown-toggle dropdown-select borderInput"
              type="button"
              id="state"
              data-toggle="dropdown"
              onClick={() => {
                setExpanded(!expanded);
              }}
            >
              <span className="drop-text">
                {currentState !== undefined
                  ? options[Number(currentState) - 1].Name
                  : options[0].Name}
              </span>
              <span className="caret" />
            </button>
            <ul
              className="dropdown-menu"
              role="menu"
              onClick={(e) => {
                setExpanded(!expanded);
                setSelectedFilter(e.target.name);
                setFilter(Number(e.target.name) + 1);
                const filterVal = e.target.name !== undefined
                  ? options[Number(e.target.name).toString()].Name
                  : options[0].Name;
                setFilterVal(filterVal);
                // dispatchAuditLog(
                //   actionLog(
                //     logNameValue,
                //     filterVal,
                //     logName.FilterAction,
                //     logName.CustomAlerts
                //   )
                // );
              }}
            >
              {options.map((option, index) => (
                <li role="presentation" key={index}>
                  <a role="menuitem" tabIndex="-1" name={index}>
                    {option.Name}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  ) : null;
};

export default DropdownFilter;
