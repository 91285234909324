function Slitter() {
    return (

<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="10" cy="15" r="3" fill="#5D97F6" stroke="#5D97F6"/>
<path d="M11.2522 14.1338L9.51985 15.8661L8.7478 15.0941" stroke="#2A2C2D" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M17.2291 13.4265C17.8075 12.206 18.0676 10.8589 17.985 9.5108C17.9024 8.16268 17.4799 6.85742 16.7567 5.71668C16.0336 4.57593 15.0334 3.63685 13.8493 2.98697C12.6653 2.33708 11.336 1.99755 9.98539 2.00001C8.63475 2.00248 7.30672 2.34687 6.12508 3.00107C4.94345 3.65528 3.94668 4.598 3.22769 5.74138C2.50871 6.88475 2.09091 8.19155 2.01324 9.53996C1.93557 10.8884 2.20056 12.2345 2.78351 13.4529L6.30106 11.7698C6.00226 11.1453 5.86643 10.4553 5.90624 9.7642C5.94605 9.07304 6.16021 8.40322 6.52873 7.81717C6.89726 7.23111 7.40817 6.7479 8.01384 6.41257C8.61951 6.07725 9.30022 5.90073 9.99251 5.89946C10.6848 5.8982 11.3662 6.07224 11.973 6.40535C12.5799 6.73846 13.0926 7.2198 13.4633 7.80451C13.8339 8.38921 14.0505 9.05825 14.0929 9.74925C14.1352 10.4403 14.0019 11.1307 13.7054 11.7563L17.2291 13.4265Z" fill="#5D97F6" stroke="#5D97F6" stroke-linejoin="round"/>
<path d="M10 2V4" stroke="#2A2C2D" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M18 10L16 10" stroke="#2A2C2D" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M4 10L2 10" stroke="#2A2C2D" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M5.75739 5.75732L4.34317 4.34311" stroke="#2A2C2D" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M15.6569 4.34314L14.2426 5.75735" stroke="#2A2C2D" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M11.646 8.05322L9.2113 10.2577C9.20804 10.2606 9.20481 10.2636 9.20198 10.2667C9.12349 10.3474 9.08232 10.4551 9.08617 10.57C9.09409 10.8061 9.29264 10.9983 9.52909 10.9983C9.64293 10.9983 9.749 10.954 9.83718 10.8628L11.9587 8.35543C12.0304 8.27071 12.0219 8.14251 11.9393 8.06262C11.8566 7.98294 11.7289 7.97875 11.646 8.05322Z" fill="#5D97F6"/>
</svg>

    );
  }
  export default Slitter;
  