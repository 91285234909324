/* eslint no-unused-vars:0 */
import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import LiHtml from '../../../../../common/horizontalMenu/horizontalMenu';
// import { DashboardContext } from '../../../../context/dashboardContext';
// import { handleURLRouteParameter } from '../../../../services/handleRoutes';
import { handleURLRouteParameter } from '../../../../../services/handleRoute';
import FailureModelisting from '../FailureModeListing';
import ImportFMEATable from '../Import-FMEA/ImportFMEATable';
import '../../../../../styles/failureMode.css';

const FMEATabs = () => {
  // const [dashboardState, SetDashboardState] = useContext(DashboardContext);
  const history = useHistory();
  const { tabType } = useParams();
  const selectedTab = tabType || 'FailureModeListing';
  const [dashboardState, SetDashboardState] = useState({
    selectedTab,
  });
  // const tabFuntions = (tabvalue, actionAudit) => {
  const tabFuntions = (tabvalue) => {
    // history.replace(handleURLRouteParameter('FailureModeListing', tabvalue));
    history.replace(handleURLRouteParameter('FMEA', tabvalue));
    SetDashboardState({ ...dashboardState, selectedTab: tabvalue });
  };

  useEffect(() => {
    tabFuntions(tabType || 'FailureModeListing');
  }, []);

  return (
    <>
      <div className="tabArea">
        <ul className="nav nav-tabs tabsName">
          <LiHtml
            tabValue="FailureModeListing"
            selectedTab={dashboardState.selectedTab}
            onClickFun={() => tabFuntions('FailureModeListing')}
            tabName="Failure Mode List"
          />
          {/* <LiHtml
            tabValue="ImportFMEA"
            selectedTab={dashboardState.selectedTab}
            onClickFun={() => tabFuntions('ImportFMEA')}
            tabName="Import FMEA"
          /> */}
        </ul>
        <div className="tab-content">
          {dashboardState.selectedTab === 'FailureModeListing' && (
          <div
            id="FailureModeListing"
            className={`tab-pane fade ${dashboardState.selectedTab === 'FailureModeListing'
              ? 'in active'
              : ''
            }`}
          >
            <FailureModelisting />
          </div>
          )}

          {/* {dashboardState.selectedTab === 'ImportFMEA' && (
          <div
            id="FailureCondition"
            className={`tab-pane fade ${dashboardState.selectedTab === 'ImportFMEA'
              ? 'in active'
              : ''
            }`}
          >
            <ImportFMEATable />
          </div>
          )} */}
        </div>
      </div>
    </>
  );
};

export default FMEATabs;
