import React, {
  useState,
} from 'react';
import ExecutionResultTable from './execution-result-table';
import ExecutionFilter from './execution-result-filter';

const ExecutionResult = () => {
  const [filterVisible, setFilterVisible] = useState(!(window.innerWidth < 769));
  // const [filterVisible, setFilterVisible] = useState(true);
  const [page, setPage] = useState(1);
  const [filterValues, setFiltervalues] = useState({});

  const Filtervaluechanged = (data) => {
    setPage(1);
    setFiltervalues(data);
  };

  const setpageValue = (data) => {
    setPage(data);
  };
  return (
    <>
      <div className="wrapper cm-wrapper executionResult">
        <div className="contentWrap conditionMonitoring monitoringcontentWrap monitoringPage execution-result">
          <div>
            <div className="tabArea">
              <ul id="navList" className="nav nav-tabs tabsName">
                <li className="active">
                  <a data-toggle="tab">Execution Result</a>
                </li>
              </ul>
              <div className="tab-content">
                <div className="exresult">
                  <div className="contentPart">
                    <ExecutionFilter setFilterVisible={setFilterVisible} filterVisible={filterVisible} onSubmited={Filtervaluechanged} />

                    {/* <div className={`${filterVisible ? "" : "t"}`}>
                                </div> */}
                    <div className={`${filterVisible ? '' : 't'} tab-pane fade no-gutters in active`}>
                      <ExecutionResultTable filterValues={filterValues} page={page} setPage={setpageValue} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ExecutionResult;
