/* eslint-disable import/prefer-default-export */
/* eslint-disable react/jsx-props-no-spreading */
import { Form, Field } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import { useDispatch, useSelector } from 'react-redux';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Tooltip from '@material-ui/core/Tooltip';
import TextField from '@material-ui/core/TextField';
import React, { useState, useEffect, useContext } from 'react';
import 'antd/dist/antd.css';
import 'antd/dist/antd.dark.css';
import {
  Drawer, Button, Spin, Dropdown, Menu,
} from 'antd';
// import { DownOutlined } from '@ant-design/icons';
import {
  fetchMachineModelAsync,
  fetchMachineSectionAsync,
  fetchMachineFunctionAsync,
  fetchSeverityCriteriaAsync,
  saveFailureModeAsync,
} from '../../../../../redux/slices/failureModeAddSlice';
import { LanguageContext } from '../../../../../LanguageContext';
import { FailureIndicatorsView } from '../../failure-indicators/FailureIndicatorsView';
import { FailureIndicatorAddComponent } from '../../failure-indicators/FailureIndicatorsAdd';
import { setEditPotentialCause } from '../../../../../redux/slices/failureModeDetailsSlice';
import { PotentialCausesAdd } from '../../potential-causes/PotentialCauseAdd';
// import { RecommendedAction } from '../../../recommended-actions/RecommendedAction';
import { ReactComponent as AlertIcon } from '../../../../../images/alert-circle.svg';
// import '../../../styles/failureMode.css';
import { ReactComponent as Edit } from '../../../../../images/editSpoc.svg';
import { ReactComponent as Plus } from '../../../../../images/plus.svg';
// import Icon from '../../../../Dashboard/Sidebar/images/Icon';
/* eslint no-unused-vars:0 */
/* eslint no-shadow:0 */
/* eslint no-use-before-define:0 */
export const FailureModelAddComponent = ({ visible, onSaveFailureMode, onAddEntryClose }) => {
  const dispatch = useDispatch();

  const [model, setModel] = useState('');
  const [section, setSection] = useState('');
  const [showViewFailureIndicators, setShowViewFailureIndicators] = useState(false);
  const [addViewFailureIndicators, setAddViewFailureIndicators] = useState(false);
  const [addPotentialCauses, setAddPotentialCauses] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [errorFields, setErrorFields] = useState([]);
  const [failureMode, setFailureMode] = useState('');
  const [potentialCause, setPotentialCause] = useState('');

  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  const validate = () => {
    const errors = [];
    if (!failureMode) errors.push('failureMode');

    if (!potentialCause) errors.push('potentialCause');
    if (JSON.stringify(errorFields) !== JSON.stringify(errors)) {
      setErrorFields(errors);
    }
    return errors.length === 0;
  };

  const onSubmit = async (values) => {
    dispatch(saveFailureModeAsync(values)).then(() => {
      onSaveFailureMode();
      if (params) {
        onAddEntryClose();
      }
    });
  };

  const validateForm = () => {
    const errors = [];
    if (!failureMode) errors.push('failureMode');

    if (!potentialCause) errors.push('potentialCause');
    if (JSON.stringify(errorFields) !== JSON.stringify(errors)) {
      setErrorFields(errors);
    }
    return errors.length === 0;
  };

  const onEditPotentialCauseClick = (potentialCauseId) => {
    dispatch(setEditPotentialCause(potentialCauseId));
    setAddPotentialCauses(true);
  };

  const onClick = () => {
    // window.alert(JSON.stringify(values, 0, 2));
    setShowViewFailureIndicators(true);
    // <FailureIndicatorsView/>
  };

  const onAddFailureIndicator = () => {
    setAddViewFailureIndicators(true);
  };

  const onAddFailureIndicatorClose = () => {
    setAddViewFailureIndicators(false);
  };

  const onAddPotentialCauses = () => {
    setAddPotentialCauses(true);
  };

  const required = (value) => (value ? undefined : 'Required');

  let editFailure = useSelector(
    (state) => state?.failureModeDetails?.editFailureMode,
  );

  // try {
  //   editFailure = useSelector(
  //     (state) => state.failureModeDetails.editFailureMode,
  //   );
  // } catch (err) {
  //   console.log(err);
  // }

  const editPotentialCause = useSelector((state) => {
    const id = state.failureModeDetails.editPotentialCauseId;
    if (id) {
      if (
        state.failureModeDetails.editFailureMode.potentialcauses
        && state.failureModeDetails.editFailureMode.potentialcauses.length > 0
      ) {
        return state.failureModeDetails.editFailureMode.potentialcauses.find(
          (p) => p.PotentialCause_ID === id,
        );
      }
    }
  });

  const onPotentialCauseClose = () => {
    setAddPotentialCauses(false);
    dispatch(setEditPotentialCause());
  };

  const params = ((editFailure?.failureindicators && editFailure?.failureindicators.length > 0)
    && (editFailure?.potentialcauses && editFailure?.potentialcauses.some((p) => p.causualparameters && p.causualparameters.length > 0)));
  const disable = editFailure && editFailure.FailureMode_ID;

  useEffect(() => {
    dispatch(fetchMachineModelAsync());
  }, []);

  const machineModels = useSelector(
    (state) => state.failureModeEntry.machineModels,
  );

  useEffect(() => {
    if (model) {
      dispatch(fetchMachineSectionAsync(model));
    }
  }, [model]);

  const machineSections = useSelector(
    (state) => state.failureModeEntry.machineSections,
  );

  useEffect(() => {
    if (section) {
      dispatch(fetchMachineFunctionAsync(section));
    }
  }, [section]);

  const machineFunctions = useSelector(
    (state) => state.failureModeEntry.machineFunctions,
  );

  useEffect(() => {
    dispatch(fetchSeverityCriteriaAsync());
  }, []);

  const severityCriteria = useSelector(
    (state) => state.failureModeEntry.severityCriteria,
  );

  const potentialCauses = useSelector(
    (state) => state.failureModeDetails.editFailureMode.potentialcauses,
  );

  const onFailureModeChange = (element) => {
    setFailureMode(element.target.value);
  };

  const onPotentialCause = (element) => {
    setPotentialCause(element.target.value);
  };

  const [saveVisible, setSaveVisible] = useState();

  const CustomMenu = ({ form, handleSubmit }) => (
    <Menu>
      <Button
        type="primary"
        htmlType="submit"
        onClick={(e) => {
          form.change('Status', 'Completed');
          setSaveVisible(false);
          handleSubmit(e);
        }}
      >
        Save
      </Button>
    </Menu>
  );

  const spinning = useSelector(
    (state) => state.failureModeDetails.editFailureModeStatus === 'loading'
      || state.failureModeEntry.machineModelStatus === 'loading'
      || state.failureModeEntry.machineFunctionStatus === 'loading'
      || state.failureModeEntry.machineSectionStatus === 'loading'
      || state.failureModeEntry.severityCriteriaStatus === 'loading'
      || state.failureModeEntry.failureModeStatus === 'loading'
      || state.recommendedActionsEntry.recommendedActionsStatus === 'loading',
  );

  // const [Lang] = useContext(LanguageContext);

  return (
    <>
      <Spin spinning={spinning}>
        <Form
          onSubmit={onSubmit}
          validate={validate}
          initialValues={editFailure}
          render={({ handleSubmit, form }) => (
            <form onSubmit={handleSubmit} id="failure-mode-form" className="fm">
              <Field
                name="FailureMode_ID"
                className="form-control"
                component="input"
                type="hidden"
              />
              <div className="form-group">
                <div className="input-control">
                  <label className="font10">
                    {/* {Lang.MachineModel} */}
                    Machine Model
                    <span className="redStar">*</span>
                  </label>
                  <Tooltip
                    PopperProps={{ className: 'MuiTooltip-popper MuiTooltip-popperInteractive toolstips' }}
                    arrow
                    title={(
                      <div className="fia-desc">
                        <div className="fia-desc-head">Points to consider when configuring machine model:</div>
                        <br />
                        <span>The failure mode can be configured for any machine model selected</span>
                        <br />
                      </div>
)}
                  >
                    <AlertIcon />
                    {/* <Icon name="AlertIcon" /> */}
                  </Tooltip>
                  <div className="input-group">
                    <div>
                      <Field
                        name="MachineModel_ID"
                        className="form-control"
                        validate={required}
                        placeholder="Select"
                      >
                        {(props) => (

                          <Autocomplete
                            value={(machineModels && machineModels.find((m) => m.MachineModel_ID === props.input.value)) || ''}
                            getOptionSelected={(option, value) => option.MachineModel_ID === value.MachineModelID}
                            onChange={(event, value) => {
                              props.input.onChange(value ? value.MachineModel_ID : '');
                            }}
                            onBlur={props.input.onBlur}
                            options={machineModels}
                            getOptionLabel={(option) => option.MachineModel_Name || ''}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder="Please Select Machine Model"
                                className={`${props.input.value === '' && props.meta.touched
                                  ? 'error-field'
                                  : ''
                                }`}
                                variant="outlined"
                              />
                            )}
                            disabled={disable}
                            disablePortal
                          />
                        )}

                      </Field>

                      <OnChange name="MachineModel_ID">
                        {(value, previous) => {
                          setModel(value);
                          if (!(editFailure && editFailure.FailureMode_ID)) {
                            setSection('');
                            form.change('MachineSection_ID', '');
                            form.change('MachineFunction_ID', '');
                          }
                        }}
                      </OnChange>
                      <span id="machinemodel" className="caret" />
                    </div>

                  </div>
                </div>

              </div>
              <div className="form-group">
                <div className="input-control">
                  <label className="font10">
                    {/* {Lang.MachineSection} */}
                    Machine Section
                  </label>
                  {/* <span className="redStar">*</span> */}
                  <Tooltip
                    PopperProps={{ className: 'MuiTooltip-popper MuiTooltip-popperInteractive toolstips' }}
                    arrow
                    title={(
                      <div className="fia-desc">
                        <div className="fia-desc-head">Points to consider when configuring machine model:</div>
                        <br />
                        <span>The failure mode can be configured against a section in the machine model selected</span>
                        <br />
                      </div>
)}
                  >
                    <AlertIcon />
                    {/* <Icon name="AlertIcon" /> */}
                  </Tooltip>
                  <div className="input-group">
                    <div>
                      <Field
                        name="MachineSection_ID"
                        className="form-control"
                        // validate={required}
                      >
                        {(props) => (

                          <Autocomplete
                            value={(machineSections && machineSections.find((m) => m.MachineSection_ID === props.input.value)) || ''}
                            getOptionSelected={(option, value) => option.MachineSection_ID === value.MachineSectionID}
                            onChange={(event, value) => {
                              props.input.onChange(value ? value.MachineSection_ID : '');
                            }}
                            onBlur={props.input.onBlur}
                            options={machineSections}
                            getOptionLabel={(option) => option.MachineSection_Name || ''}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder="Please Select Machine Section"
                                // className={`${props.input.value === '' && props.meta.touched
                                //   ? 'error-field'
                                //   : ''
                                // }`}
                                variant="outlined"
                              />
                            )}
                            disabled={disable}
                            disablePortal

                          />
                        )}
                      </Field>
                      <OnChange name="MachineSection_ID">
                        {(value, previous) => {
                          setSection(value);
                          if (!(editFailure && editFailure.FailureMode_ID)) {
                            form.change('MachineFunction_ID', '');
                          }
                        }}
                      </OnChange>
                      <span id="machinemodel" className="caret" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <div className="input-control">
                  <label className="font10">Machine Component </label>
                  {/* <span className="redStar">*</span> */}
                  <Tooltip
                    PopperProps={{ className: 'MuiTooltip-popper MuiTooltip-popperInteractive toolstips' }}
                    arrow
                    title={(
                      <div className="fia-desc">
                        <div className="fia-desc-head">Points to consider when configuring machine model:</div>
                        <br />
                        <span>The failure mode can be configured against a section in the machine model selected</span>
                        <br />
                      </div>
)}
                  >
                    <AlertIcon />
                    {/* <Icon name="AlertIcon" /> */}
                  </Tooltip>
                  <div className="input-group">
                    <div>
                      <Field
                        name="MachineFunction_ID"
                        className="form-control"
                        // validate={required}
                      >
                        {(props) => (

                          <Autocomplete
                            value={(machineFunctions && machineFunctions.find((m) => m.MachineFunction_ID === props.input.value)) || ''}
                            getOptionSelected={(option, value) => option.MachineFunction_ID === value.MachineFunctionID}
                            onChange={(event, value) => {
                              props.input.onChange(value ? value.MachineFunction_ID : '');
                            }}
                            onBlur={props.input.onBlur}
                            options={machineFunctions}
                            getOptionLabel={(option) => option.MachineFunction_Name || ''}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder="Please Select Machine Component"
                                // className={`${props.input.value === '' && props.meta.touched
                                //   ? 'error-field'
                                //   : ''
                                // }`}
                                variant="outlined"
                              />
                            )}
                            disabled={disable}
                            disablePortal

                          />
                        )}
                      </Field>

                      <span id="machinemodel" className="caret" />
                    </div>
                  </div>
                </div>
              </div>

              <div className="form-group">
                <label className="font10">
                  Failure Mode
                  <span className="redStar">*</span>

                </label>
                <span>
                  <Tooltip
                    PopperProps={{ className: 'MuiTooltip-popper MuiTooltip-popperInteractive toolstips' }}
                    arrow
                    title={(
                      <div className="fia-desc">
                        <div className="fia-desc-head">Points to consider when configuring machine failure:</div>
                        <br />
                        <span>Failure mode defines the manner in which an equipment or machine failure can occur</span>
                        <br />
                      </div>
)}
                  >
                    <AlertIcon />
                    {/* <Icon name="AlertIcon" /> */}
                  </Tooltip>
                </span>

                <Field
                  name="Description"
                  validate={required}
                  className="form-control"
                >
                  {({ input, meta }) => (
                    <div style={{ display: 'block' }}>
                      <input
                        className={`form-control customForm input-md ${meta.error && meta.touched && 'error-field'
                        }`}
                        {...input}
                        type="text"
                        placeholder="Failure Mode"
                      />
                    </div>
                  )}
                </Field>
              </div>

              <div
                className="form-group pull-right add-casual-paramater-button"
                onClick={() => {
                  if (editFailure && editFailure.FailureMode_ID) {
                    onAddFailureIndicator();
                  }
                }}
              >
                <label
                  className={` link 
                    ${editFailure && editFailure.FailureMode_ID
                    ? ''
                    : 'disabled'
                    }`}
                >
                  {/* <Icon name="Add" /> */}
                  <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8 3.68457V13.0179" stroke="#5D97F6" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M3.3335 8.35156H12.6668" stroke="#5D97F6" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                  {/* <Plus /\> */}
                  {' '}
                  Add Failure Indicators
                </label>
              </div>

              <div className="clearfix" />

              {editFailure
                && editFailure.failureindicators
                && editFailure.failureindicators.length > 0 && (
                  <div className="form-group">
                    <div className="dotted-box">
                      <label
                        onClick={() => {
                          onClick();
                        }}
                      >
                        {' '}
                        View Added Failure Indicators
                      </label>
                    </div>
                  </div>
              )}
              <div className="form-group">
                <label className="font10">
                  Potential Effects of Failure
                  <span className="redStar">*</span>
                </label>
                <span>
                  <Tooltip
                    PopperProps={{ className: 'MuiTooltip-popper MuiTooltip-popperInteractive toolstips' }}
                    arrow
                    title={(
                      <div className="fia-desc">
                        <div className="fia-desc-head">Points to consider when configuring potential effect:</div>
                        <br />
                        <span>The potential effect that can happen because of the failure mode configured is defined</span>
                        <br />
                      </div>
)}
                  >
                    <AlertIcon />
                    {/* <Icon name="AlertIcon" /> */}
                  </Tooltip>
                </span>
                <Field
                  name="PotentialEffect"
                  className="form-control"
                  // component="input"
                  validate={required}
                // placeholder="Potential Effects of Failure"
                >
                  {({ input, meta }) => (
                    <div style={{ display: 'block' }}>
                      <input
                        className={`form-control customForm input-md ${meta.error && meta.touched && 'error-field'
                        }`}
                        {...input}
                        type="text"
                        placeholder="Potential Effects of Failure"
                      />
                    </div>
                  )}
                </Field>
              </div>
              <div className="form-group">
                <div className="input-control">
                  <label className="font10">
                    Severity Criteria
                    <span className="redStar">*</span>
                  </label>
                  <span>
                    <Tooltip
                      PopperProps={{ className: 'MuiTooltip-popper MuiTooltip-popperInteractive toolstips' }}
                      arrow
                      title={(
                        <div className="fia-desc">
                          <div className="fia-desc-head">Points to consider when configuring Ranking number:</div>
                          <br />
                          <span>Ranking number associated with the most serious effect for a given failure mode</span>
                          <br />
                        </div>
)}
                    >
                      <AlertIcon />
                      {/* <Icon name="AlertIcon" /> */}
                    </Tooltip>
                  </span>
                  <div className="input-group">
                    <div>
                      <Field
                        name="SeverityCriteria_ID"
                        className="form-control"
                        component="select"
                        validate={required}
                        placeholder="Severity Criteria"
                      >
                        {(props) => (

                          <Autocomplete
                            value={(severityCriteria && severityCriteria.find((m) => m.SeverityCriteria_ID === props.input.value)) || ''}
                            getOptionSelected={(option, value) => option.SeverityCriteria_ID === value.SeverityCriteriaID}
                            onChange={(event, value) => {
                              props.input.onChange(value ? value.SeverityCriteria_ID : '');
                            }}
                            onBlur={props.input.onBlur}
                            options={severityCriteria}
                            getOptionLabel={(option) => (option ? (`${option.Scale}-${option.Description}`) : '')}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder="Please Select Severity Criteria"
                                className={`${props.input.value === '' && props.meta.touched
                                  ? 'error-field'
                                  : ''
                                }`}
                                variant="outlined"
                              />
                            )}
                            disabled={disable}
                            disablePortal

                          />
                        )}
                      </Field>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="form-group pull-right add-casual-paramater-button"
                onClick={() => {
                  if (editFailure && editFailure.FailureMode_ID) {
                    onAddPotentialCauses();
                  }
                }}
              >
                <label
                  className={` link 
                    ${editFailure && editFailure.FailureMode_ID
                    ? ''
                    : 'disabled'
                    }`}
                >
                  <Plus />
                  {' '}
                  Add Potential Causes
                </label>
              </div>

              <div className="clearfix" />

              {potentialCauses
                && potentialCauses.map((pot) => (
                  <div
                    className="form-group potential-cause-card"

                  >
                    <div className="row">
                      <div className="col-md-9">
                        <div className="grid-label">Potential Cause</div>
                        <p>{pot.Description}</p>
                      </div>
                      <div className="col-md-3 link edit text-right">

                        <Edit onClick={() => {
                          onEditPotentialCauseClick(pot.PotentialCause_ID);
                        }}
                        />
                        {/* <Icon
                          name="EditIcon"
                          onClick={() => {
                            onEditPotentialCauseClick(pot.PotentialCause_ID);
                          }}
                        /> */}
                      </div>
                    </div>
                    <p />
                    <div className="row">
                      <div className="col-md-6">
                        <label>Occurrence Frequency</label>
                        <p>
                          {pot.occurencecriterium?.Scale}
                          -
                          {pot.occurencecriterium?.Description}
                        </p>
                      </div>
                      <div className="col-md-6">
                        <label>Ease of detection</label>
                        <p>
                          {pot.detectioncriterium?.Scale}
                          -
                          {pot.detectioncriterium?.Description}
                        </p>
                      </div>
                    </div>
                    {pot.recommendedactions && pot.recommendedactions.length !== 0
                    && (
                    <div className="row">
                      <div className="col-md-12 recomendedactionlist">
                        <label>
                          Recommended Action (
                          {pot.recommendedactions.length}
                          )
                        </label>
                        <div className="recomendedaction rows">{pot.recommendedactions.map((e) => (<div className="card">{e.Description}</div>))}</div>
                      </div>
                    </div>
                    )}
                  </div>
                ))}

              <div className="clearfix" />

              <div className="pull-right" style={{display: 'flex'}}>
                <Button
                  type="button"
                  style={{ marginRight: '8px' }}
                  // onClick={form.reset}
                  onClick={onAddEntryClose}
                >
                  Cancel
                </Button>
                <Dropdown.Button
                  type="primary"
                  className="fm_draft"
                  visible={saveVisible}
                  icon={<span className="saveDraftBtn" />}
                  overlay={(
                    <CustomMenu
                      form={form}
                      handleSubmit={handleSubmit}
                    />
                  )}
                  trigger="click"
                  htmlType="submit"
                  onClick={() => {
                    form.change('Status', 'Draft');
                  }}
                  onVisibleChange={(visible) => setSaveVisible(visible)}
                >
                  Save as Draft
                </Dropdown.Button>
              </div>
            </form>
          )}
        />
      </Spin>

      {showViewFailureIndicators && (
        <Drawer
          title="View Failure Indicators"
          placement="right"
          height="100%"
          closable
          visible={showViewFailureIndicators}
          closeIcon={<div className="closepopup pointer"> &#x2715;</div>}
          className="failuremodulePage"
          onClose={() => {
            setShowViewFailureIndicators(false);
          }}
          push={{ distance: 378 }}
        >
          <FailureIndicatorsView />
        </Drawer>
      )}

      {addViewFailureIndicators && (
        <Drawer
          title="Add Failure Indicators"
          placement="right"
          height="100%"
          closable
          className="failuremodulePage"
          closeIcon={<div className="closepopup pointer"> &#x2715;</div>}
          visible={addViewFailureIndicators}
          onClose={() => {
            onAddFailureIndicatorClose();
          }}
          push={{ distance: 378 }}
        >
          <FailureIndicatorAddComponent
            modelID={editFailure.MachineModel_ID}
            sectionID={editFailure.MachineSection_ID}
            functionID={editFailure.MachineFunction_ID}
            failureModeID={editFailure.FailureMode_ID}
            onAddFailureIndicatorClose={onAddFailureIndicatorClose}
          />
        </Drawer>
      )}

      {addPotentialCauses && (
        <Drawer
          title="Add Potential Causes"
          placement="right"
          height="100%"
          closable
          className="failuremodulePage"
          closeIcon={<div className="closepopup pointer"> &#x2715;</div>}
          visible={addPotentialCauses}
          onClose={onPotentialCauseClose}
          push={{ distance: 378 }}
        >
          <PotentialCausesAdd
            failureModeID={editFailure.FailureMode_ID}
            potentialCause={editPotentialCause}
            onPotentialCauseClose={onPotentialCauseClose}
          />
        </Drawer>
      )}
    </>
  );
};
