const authHeader = () => {
    const token = JSON.parse(localStorage.getItem("token"));
  
    if (token) {
      return {
        "x-access-token": token,
        refreshToken: token,
      };
    } else {
      return {};
    }
  };
  
  export default authHeader;
  