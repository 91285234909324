// import axios from 'axios';
import { message } from 'antd';
// import { user_id, APIUrl } from '../../../configs/config';
import { APIUrl } from '../../../../../helper/constants';
// import authHeader from '../../../../../config/auth.header';
import CallAPI from '../../../../../services/conditionMonitoring.service';
import CallUploadAPI from '../../../../../services/UploadFile.service';

// const headers = {
//   headers: authHeader(),
// };

export function fetchFailureModeImport(page = 1, size = 10, search) {
  const apiInfo = {
    url: `${APIUrl}/api/fetchImportDetails?page=${page}&size=${size}&search=${search}`,
    method: 'GET',
    isMultiPart: false,
  };
  return CallAPI(apiInfo).then((res) => res.data);
  // return axios
  //   .get(`${APIUrl}/api/fetchImportDetails?page=${page}&size=${size}&search=${search}`, headers)
  //   .then((res) => res.data);
}

export function getFMEAImportNotification() {
  const apiInfo = {
    url: `${APIUrl}/api/getFMEAImportNotification`,
    method: 'GET',
    isMultiPart: false,
  };
  return CallAPI(apiInfo).then((res) => res.data);
  // return axios
  //   .get(`${APIUrl}/api/getFMEAImportNotification`, headers)
  //   .then((res) => res.data);
}

export function downloadImportedFile(id) {
  const apiInfo = {
    url: `${APIUrl}/api/getImportFile?id=${id}`,
    method: 'GET',
    isMultiPart: true,
  };
  return CallAPI(apiInfo).then(async (response) => {
    const apiData = {
      url: `${APIUrl}/api/fetchImportDetails?id=${id}`,
      method: 'GET',
      isMultiPart: false,
    };
    const importData = await CallAPI(apiData);
    // const importData = await axios.get(`${APIUrl}/api/fetchImportDetails?id=${id}`, headers);
    console.log('imagesss', response.data);
    const url = window.URL.createObjectURL(response.data);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', importData.data.Details[0].FileName);
    document.body.appendChild(link);
    link.click();
    return 'Downloaded';
  }).catch((err) => {
    console.log(err);
    message.error('File not exists.');
  });

  // return axios
  //   .get(`${APIUrl}/api/getImportFile?id=${id}`, { responseType: 'blob', ...headers })
  //   .then(async (response) => {
  //     const importData = await axios.get(`${APIUrl}/api/fetchImportDetails?id=${id}`, headers);
  //     const url = window.URL.createObjectURL(response.data);
  //     const link = document.createElement('a');
  //     link.href = url;
  //     link.setAttribute('download', importData.data.Details[0].FileName);
  //     document.body.appendChild(link);
  //     link.click();
  //     return 'Downloaded';
  //   }).catch((err) => {
  //     console.log(err);
  //     message.error('File not exists.');
  //   });
}

export function downloadTemplateFile() {
  const apiInfo = {
    url: `${APIUrl}/api/getTemplateFile`,
    method: 'GET',
    isMultiPart: true,
  };
  return CallAPI(apiInfo).then((response) => {
    const url = window.URL.createObjectURL(response.data);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'FMEATemplate.xlsx');
    document.body.appendChild(link);
    link.click();
    return 'Downloaded';
  }).catch((e) => {
    const fr = new FileReader();
    fr.onload = (event) => {
      console.log(JSON.parse(event.target.result));
    };
    fr.readAsText(e.response.data);
    message.error('Unable to download.');
  });
  // return axios
  //   .get(`${APIUrl}/api/getTemplateFile`, { responseType: 'blob', ...headers })
  //   .then((response) => {
  //     const url = window.URL.createObjectURL(response.data);
  //     const link = document.createElement('a');
  //     link.href = url;
  //     link.setAttribute('download', 'FMEATemplate.xlsx');
  //     document.body.appendChild(link);
  //     link.click();
  //     return 'Downloaded';
  //   }).catch((e) => {
  //     const fr = new FileReader();
  //     fr.onload = (event) => {
  //       console.log(JSON.parse(event.target.result));
  //     };
  //     fr.readAsText(e.response.data);
  //     message.error('Unable to download.');
  //   });
}

export function uploadFailureModeFile(formData) {
  // formData.append('User_ID', userId);
  // console.log('domrdada', ...formData);
  // const data = formData;
  const apiInfo = {
    url: `${APIUrl}/api/uploadFailureModeFile`,
    method: 'POST',
    postData: formData,
    isMultiPart: true,
    UserID: true,
  };
  return CallUploadAPI(apiInfo).then((res) => res.data);

  // return axios({
  //   method: 'post',
  //   url: `${APIUrl}/api/uploadFailureModeFile`,
  //   data: formData,
  //   headers: { 'Content-Type': 'multipart/form-data', ...headers.headers },
  // }).then((res) => res.data);
}
