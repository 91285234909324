import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import { removeRecommendedAction, setRecommendedAction } from './failureModeDetailsSlice';
import { saveRecommendedActions, deleteRecommendedActions } from '../../components/ConditionMonitoring/recommended-actions/recommendedActionApi';
// import { Success_Message, Error_Message } from '../../configs/config';
import { SuccessMessage, ErrorMessage } from '../../helper/constants';

const initialState = {
  // recommendedActions: [],
  recommendedActionsStatus: 'idle',
};

export const saveRecommendedActionsAsync = createAsyncThunk(
  'saveRecommendedAction/saveRecommendedActions',
  async (recommendedAction, thunkApi) => {
    if (recommendedAction.PotentialCause_ID) {
      const response = await saveRecommendedActions(recommendedAction);
      thunkApi.dispatch(setRecommendedAction(response));
      return response;
    }

    thunkApi.dispatch(setRecommendedAction(recommendedAction));
    return recommendedAction;
  },
);

export const deleteRecommendedActionsAsync = createAsyncThunk(
  'deleteRecommendedAction/deleteRecommendedActions',
  async ({
    PotentialCause_ID: PotentialCauseID, RecommendedAction_ID: recommendedActionId,
  }, thunkApi) => {
    const response = await deleteRecommendedActions(recommendedActionId);
    if (response === 'OK') {
      thunkApi.dispatch(removeRecommendedAction({
        PotentialCause_ID: PotentialCauseID,
        RecommendedAction_ID: recommendedActionId,
      }));
    }
    return response;
  },
);

export const recommendedActionsEntry = createSlice({
  name: 'recommendedActionAdd',
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(saveRecommendedActionsAsync.pending, (state) => {
        state.recommendedActionsStatus = 'loading';
      })
      // .addCase(saveRecommendedActionsAsync.fulfilled, (state, action) => {
      .addCase(saveRecommendedActionsAsync.fulfilled, (state) => {
        state.recommendedActionsStatus = 'idle';
        // state.recommendedActions = action.payload;
        message.success(SuccessMessage);
      })
      .addCase(saveRecommendedActionsAsync.rejected, (state) => {
        state.recommendedActionsStatus = 'idle';
        message.error(ErrorMessage);
      })
      .addCase(deleteRecommendedActionsAsync.pending, (state) => {
        state.recommendedActionsStatus = 'loading';
      })
      // .addCase(deleteRecommendedActionsAsync.fulfilled, (state, action) => {
      .addCase(deleteRecommendedActionsAsync.fulfilled, (state) => {
        state.recommendedActionsStatus = 'idle';
        // state.recommendedActions = action.payload;
      });
  },
});

export default recommendedActionsEntry.reducer;
