/* eslint-disable import/prefer-default-export */
import { compileExpression } from 'filtrex';

export function validateFormula(newValue) {
  const validTokens = ['AND', 'OR', 'NOT', '<', '>', '<=', '>=', '+', '-', '*', '/', '%', '==', '='];
  // var paramCount = newValue.indexof(/@{[^@{}]*}/gm);
  const params = newValue.match(/@{[^@{}]*}/gm);
  if (params === null || params === undefined || params.length === 0) {
    return false;
  }
  const newPlainTextValue = newValue.replace(/@{[^@{}]*}/gm, 'dummy');

  const temp = newPlainTextValue.replace(/\(/gm, ' ').replace(/\)/gm, ' ').replace(/\n/gm, ' ');
  const tokens = temp.split(' ');
  // const validString = tokens.every((t) => {
  //   const isValidToken = validTokens.some((vt) => vt === t.toUpperCase());
  //   // var isParam = machineParameter.some(mp => mp.ParameterName.toUpperCase() == t.toUpperCase());
  //   if (isValidToken || isFinite(t) || t === 'dummy') {
  //     return true;
  //   }
  //   return false;
  // });

  const find = tokens.filter((item) => validTokens.find((item2) => item === item2));
  if (!find || find.length === 0) {
    return false;
  }

  // if (!validString) {
  //   // setValidFormula(false);
  //   return false;
  // }

  try {
    compileExpression(newPlainTextValue.toLowerCase());
    const unitFormula = newPlainTextValue.toLowerCase().split(/ and | or | not /);
    for (const f of unitFormula) {
      const check = f.split('').filter((item) => validTokens.find((item2) => (item === item2)));
      if (check.length === 0) {
        return false;
      }
      compileExpression(f);
    }
    // setValidFormula(true);
    return true;
  } catch (ex) {
    return false;
    // setValidFormula(false);
  }
}
