// import axios from 'axios';
// import { userId, APIUrl } from '../../../app/configs/config';
import { APIUrl } from '../../../helper/constants';
// import authHeader from '../../../config/auth.header';
import CallAPI from '../../../services/conditionMonitoring.service';
// const headers = authHeader();

export function fetchExecutionResult(from, to) {
  const apiInfo = {
    url: `${APIUrl}/api/getExecutionOutput?datefrom=${from}&dateto=${to}`,
    method: 'GET',
    isMultiPart: false,
  };
  return CallAPI(apiInfo).then((res) => res.data);
  // return axios
  //   .get(
  //     `${APIUrl}/api/getExecutionOutput?datefrom=${from}&dateto=${to}`, { headers },
  //   )
  //   .then((res) => res.data);
}

export function fetchExecutionDetails(page = 1, size = 10, from, to) {
  const apiInfo = {
    url: `${APIUrl}/api/getExecutionDetails?page=${page}&size=${size}&datefrom=${from}&dateto=${to}`,
    method: 'GET',
    isMultiPart: false,
  };
  return CallAPI(apiInfo).then((res) => res.data);
  // return axios
  //   .get(
  //     `${APIUrl}/api/getExecutionDetails?page=${page}&size=${size}&datefrom=${from}&dateto=${to}`, { headers },
  //   )
  //   .then((res) => res.data);
}

export function fetchConditionWise(from, to) {
  const apiInfo = {
    url: `${APIUrl}/api/getConditionWiseOutput?datefrom=${from}&dateto=${to}`,
    method: 'GET',
    isMultiPart: false,
  };
  return CallAPI(apiInfo).then((res) => res.data);
  // return axios
  //   .get(
  //     `${APIUrl}/api/getConditionWiseOutput?datefrom=${from}&dateto=${to}`, { headers },
  //   )
  //   .then((res) => res.data);
}

export function fetchModelWise(from, to) {
  const apiInfo = {
    url: `${APIUrl}/api/getModelWiseOutput?datefrom=${from}&dateto=${to}`,
    method: 'GET',
    isMultiPart: false,
  };
  return CallAPI(apiInfo).then((res) => res.data);
  // return axios
  //   .get(
  //     `${APIUrl}/api/getModelWiseOutput?datefrom=${from}&dateto=${to}`, { headers },
  //   )
  //   .then((res) => res.data);
}

export function fetchModelWiseDetails(page, size, from, to) {
  const apiInfo = {
    url: `${APIUrl}/api/getModelWiseDetails?page=${page}&size=${size}&datefrom=${from}&dateto=${to}`,
    method: 'GET',
    isMultiPart: false,
  };
  return CallAPI(apiInfo).then((res) => res.data);
  // return axios
  //   .get(
  //     `${APIUrl}/api/getModelWiseDetails?page=${page}&size=${size}&datefrom=${from}&dateto=${to}`, { headers },
  //   )
  //   .then((res) => res.data);
}

export function fetchSeverityWise(from, to) {
  const apiInfo = {
    url: `${APIUrl}/api/getSeverityWise?datefrom=${from}&dateto=${to}`,
    method: 'GET',
    isMultiPart: false,
  };
  return CallAPI(apiInfo).then((res) => res.data);
  // return axios
  //   .get(
  //     `${APIUrl}/api/getSeverityWise?datefrom=${from}&dateto=${to}`, { headers },
  //   )
  //   .then((res) => res.data);
}

export function fetchSeverityWiseDetails(page, size, from, to) {
  const apiInfo = {
    url: `${APIUrl}/api/getSeverityDetails?page=${page}&size=${size}&datefrom=${from}&dateto=${to}`,
    method: 'GET',
    isMultiPart: false,
  };
  return CallAPI(apiInfo).then((res) => res.data);
  // return axios
  //   .get(
  //     `${APIUrl}/api/getSeverityDetails?page=${page}&size=${size}&datefrom=${from}&dateto=${to}`, { headers },
  //   )
  //   .then((res) => res.data);
}

export function fetchCustomerWise(from, to) {
  const apiInfo = {
    url: `${APIUrl}/api/getCustomerWise?datefrom=${from}&dateto=${to}`,
    method: 'GET',
    isMultiPart: false,
  };
  return CallAPI(apiInfo).then((res) => res.data);
  // return axios
  //   .get(
  //     `${APIUrl}/api/getCustomerWise?datefrom=${from}&dateto=${to}`, { headers },
  //   )
  //   .then((res) => res.data);
}

export function fetchCustomerDetails(page, size, from, to) {
  const apiInfo = {
    url: `${APIUrl}/api/getCustomerDetails?page=${page}&size=${size}&datefrom=${from}&dateto=${to}`,
    method: 'GET',
    isMultiPart: false,
  };
  return CallAPI(apiInfo).then((res) => res.data);
  // return axios
  //   .get(
  //     `${APIUrl}/api/getCustomerDetails?page=${page}&size=${size}&datefrom=${from}&dateto=${to}`, { headers },
  //   )
  //   .then((res) => res.data);
}

export function fetchFailureResolution(from, to) {
  const apiInfo = {
    url: `${APIUrl}/api/getFailureResolution?datefrom=${from}&dateto=${to}`,
    method: 'GET',
    isMultiPart: false,
  };
  return CallAPI(apiInfo).then((res) => res.data);
  // return axios
  //   .get(
  //     `${APIUrl}/api/getFailureResolution?datefrom=${from}&dateto=${to}`, { headers },
  //   )
  //   .then((res) => res.data);
}

export function fetchMaintenanceResolution(from, to) {
  const apiInfo = {
    url: `${APIUrl}/api/getMaintenanceResolution?datefrom=${from}&dateto=${to}`,
    method: 'GET',
    isMultiPart: false,
  };
  return CallAPI(apiInfo).then((res) => res.data);
  // return axios
  //   .get(
  //     `${APIUrl}/api/getMaintenanceResolution?datefrom=${from}&dateto=${to}`, { headers },
  //   )
  //   .then((res) => res.data);
}

export function fetchFailureResolutionDetails(page, size, from, to) {
  const apiInfo = {
    url: `${APIUrl}/api/getFailureResolutionDetails?page=${page}&size=${size}&datefrom=${from}&dateto=${to}`,
    method: 'GET',
    isMultiPart: false,
  };
  return CallAPI(apiInfo).then((res) => res.data);
  // return axios
  //   .get(
  //     `${APIUrl}/api/getFailureResolutionDetails?page=${page}&size=${size}&datefrom=${from}&dateto=${to}`, { headers },
  //   )
  //   .then((res) => res.data);
}

export function fetchMaintenanceResolutionDetails(page, size, from, to) {
  const apiInfo = {
    url: `${APIUrl}/api/getMaintenanceResolutionDetails?page=${page}&size=${size}&datefrom=${from}&dateto=${to}`,
    method: 'GET',
    isMultiPart: false,
  };
  return CallAPI(apiInfo).then((res) => res.data);
  // return axios
  //   .get(
  //     `${APIUrl}/api/getMaintenanceResolutionDetails?page=${page}&size=${size}&datefrom=${from}&dateto=${to}`, { headers },
  //   )
  //   .then((res) => res.data);
}

export function fetchFailureAging(from, to) {
  const apiInfo = {
    url: `${APIUrl}/api/getFailureAging?datefrom=${from}&dateto=${to}`,
    method: 'GET',
    isMultiPart: false,
  };
  return CallAPI(apiInfo).then((res) => res.data);
  // return axios
  //   .get(
  //     `${APIUrl}/api/getFailureAging?datefrom=${from}&dateto=${to}`, { headers },
  //   )
  //   .then((res) => res.data);
}

export function fetchFailureAgingDetails(page, size, from, to) {
  const apiInfo = {
    url: `${APIUrl}/api/getFailureAgingDetails?page=${page}&size=${size}&datefrom=${from}&dateto=${to}`,
    method: 'GET',
    isMultiPart: false,
  };
  return CallAPI(apiInfo).then((res) => res.data);
  // return axios
  //   .get(
  //     `${APIUrl}/api/getFailureAgingDetails?page=${page}&size=${size}&datefrom=${from}&dateto=${to}`, { headers },
  //   )
  //   .then((res) => res.data);
}

export function fetchMaintenanceAging(from, to) {
  const apiInfo = {
    url: `${APIUrl}/api/getMaintenanceAging?datefrom=${from}&dateto=${to}`,
    method: 'GET',
    isMultiPart: false,
  };
  return CallAPI(apiInfo).then((res) => res.data);
  // return axios
  //   .get(
  //     `${APIUrl}/api/getMaintenanceAging?datefrom=${from}&dateto=${to}`, { headers },
  //   )
  //   .then((res) => res.data);
}

export function fetchMaintenanceAgingDetails(page, size, from, to) {
  const apiInfo = {
    url: `${APIUrl}/api/getMaintenanceAgingDetails?page=${page}&size=${size}&datefrom=${from}&dateto=${to}`,
    method: 'GET',
    isMultiPart: false,
  };
  return CallAPI(apiInfo).then((res) => res.data);
  // return axios
  //   .get(
  //     `${APIUrl}/api/getMaintenanceAgingDetails?page=${page}&size=${size}&datefrom=${from}&dateto=${to}`, { headers },
  //   )
  //   .then((res) => res.data);
}
