function Logo() {
    return (
      <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsxlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 354.14 113.89"
        className="svgLogo"
      >
        <defs>
          <clipPath id="clip-path" transform="translate(-0.08 0)">
            <rect class="cls-1" width="354.25" height="113.89" />
          </clipPath>
        </defs>
        <polygon
          class="cls-2"
          points="103.21 42.51 103.21 67.37 81.56 67.37 77.19 72.39 64.24 87.28 103.21 87.28 103.21 112.01 127.61 112.01 127.61 14.48 103.21 42.51"
        />
        <polygon
          class="cls-2"
          points="96.08 1.08 0 112.01 31.54 112.01 127.62 1.08 96.08 1.08"
        />
        <g class="cls-3">
          <path
            class="cls-2"
            d="M229.71,78.66a61.93,61.93,0,0,1-13,9.43q-6.54,3.47-16,3.47a30.29,30.29,0,0,1-23-10.23,34.64,34.64,0,0,1-6.53-11A37.58,37.58,0,0,1,168.83,57v-.32a37,37,0,0,1,2.36-13.29,34.35,34.35,0,0,1,6.6-10.93,30.44,30.44,0,0,1,23-10.07,33.44,33.44,0,0,1,15,3.3,58.53,58.53,0,0,1,13.14,9.13L244.33,17a64.66,64.66,0,0,0-8.17-6.92,50.26,50.26,0,0,0-9.6-5.35A58.55,58.55,0,0,0,215,1.26,72.76,72.76,0,0,0,200.92,0a58.81,58.81,0,0,0-23.28,4.48,54.65,54.65,0,0,0-18.09,12.27,56.79,56.79,0,0,0-11.8,18.09A57.51,57.51,0,0,0,143.5,57v.31a58,58,0,0,0,4.25,22.18,54.62,54.62,0,0,0,11.8,18,55.3,55.3,0,0,0,17.93,12,57.11,57.11,0,0,0,22.5,4.4,71.72,71.72,0,0,0,14.47-1.33,51.29,51.29,0,0,0,21.86-10.15,79,79,0,0,0,7.85-7.16L229.74,78.69Z"
            transform="translate(-0.08 0)"
          />
          <path
            class="cls-2"
            d="M303.17,69.53h27.61V85.37Q321.5,92,308,92a33.78,33.78,0,0,1-13.45-2.6A31.6,31.6,0,0,1,284,82.1,33.1,33.1,0,0,1,277.06,71a38.74,38.74,0,0,1-2.44-14v-.31a36.1,36.1,0,0,1,2.44-13.32,34,34,0,0,1,6.76-10.88,33.32,33.32,0,0,1,10.07-7.41,28.1,28.1,0,0,1,12.35-2.76,44.57,44.57,0,0,1,8.65.78,38.58,38.58,0,0,1,7.32,2.21,41.23,41.23,0,0,1,6.52,3.46,61,61,0,0,1,5.91,4.4L349.7,15.81l.58-.71a73.1,73.1,0,0,0-8.8-6.45,53.32,53.32,0,0,0-9.76-4.72A64.65,64.65,0,0,0,320.47,1,81.21,81.21,0,0,0,307,0,58.42,58.42,0,0,0,283.9,4.48,56.43,56.43,0,0,0,249.29,57v.31a59.27,59.27,0,0,0,4.25,22.65,53.25,53.25,0,0,0,11.88,17.94,54.31,54.31,0,0,0,18.24,11.8,62.84,62.84,0,0,0,23.52,4.24q15.1,0,26.82-5.11a80.57,80.57,0,0,0,20.22-12.35V48.61H321.29Z"
            transform="translate(-0.08 0)"
          />
        </g>
      </svg>
    );
  }
  export default Logo;
  