function HomeIcon(params) {
  return (
    <>
     <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10.0325 15.375C12.7939 15.375 15.0325 13.1364 15.0325 10.375C15.0325 7.61358 12.7939 5.375 10.0325 5.375C7.27105 5.375 5.03247 7.61358 5.03247 10.375C5.03247 13.1364 7.27105 15.375 10.0325 15.375Z" fill="#5D97F6" stroke="#5D97F6" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M10.5324 2.375C10.5324 2.09886 10.3086 1.875 10.0324 1.875C9.75627 1.875 9.53241 2.09886 9.53241 2.375H10.5324ZM9.53241 3.875C9.53241 4.15114 9.75627 4.375 10.0324 4.375C10.3086 4.375 10.5324 4.15114 10.5324 3.875H9.53241ZM9.53241 2.375V3.875H10.5324V2.375H9.53241Z" fill="#5D97F6"/>
<path d="M10.5324 16.875C10.5324 16.5989 10.3086 16.375 10.0324 16.375C9.75627 16.375 9.53241 16.5989 9.53241 16.875H10.5324ZM9.53241 18.375C9.53241 18.6511 9.75627 18.875 10.0324 18.875C10.3086 18.875 10.5324 18.6511 10.5324 18.375H9.53241ZM9.53241 16.875V18.375H10.5324V16.875H9.53241Z" fill="#5D97F6"/>
<path d="M18.0324 10.875C18.3086 10.875 18.5324 10.6511 18.5324 10.375C18.5324 10.0989 18.3086 9.875 18.0324 9.875L18.0324 10.875ZM16.5324 9.875C16.2563 9.875 16.0324 10.0989 16.0324 10.375C16.0324 10.6511 16.2563 10.875 16.5324 10.875L16.5324 9.875ZM18.0324 9.875L16.5324 9.875L16.5324 10.875L18.0324 10.875L18.0324 9.875Z" fill="#5D97F6"/>
<path d="M3.53241 10.875C3.80855 10.875 4.03241 10.6511 4.03241 10.375C4.03241 10.0989 3.80855 9.875 3.53241 9.875L3.53241 10.875ZM2.03241 9.875C1.75627 9.875 1.53241 10.0989 1.53241 10.375C1.53241 10.6511 1.75627 10.875 2.03241 10.875L2.03241 9.875ZM3.53241 9.875L2.03241 9.875L2.03241 10.875L3.53241 10.875L3.53241 9.875Z" fill="#5D97F6"/>
<path d="M15.6789 16.7286C15.8741 16.9238 16.1907 16.9238 16.386 16.7286C16.5812 16.5333 16.5812 16.2167 16.386 16.0214L15.6789 16.7286ZM15.3253 14.9608C15.13 14.7655 14.8135 14.7655 14.6182 14.9608C14.4229 15.156 14.4229 15.4726 14.6182 15.6679L15.3253 14.9608ZM16.386 16.0214L15.3253 14.9608L14.6182 15.6679L15.6789 16.7286L16.386 16.0214Z" fill="#5D97F6"/>
<path d="M4.73953 5.78861C4.93479 5.98387 5.25137 5.98387 5.44663 5.78861C5.64189 5.59335 5.64189 5.27677 5.44663 5.08151L4.73953 5.78861ZM4.38597 4.02084C4.19071 3.82558 3.87413 3.82558 3.67887 4.02084C3.4836 4.21611 3.4836 4.53269 3.67887 4.72795L4.38597 4.02084ZM5.44663 5.08151L4.38597 4.02084L3.67887 4.72795L4.73953 5.78861L5.44663 5.08151Z" fill="#5D97F6"/>
<path d="M3.67886 16.0214C3.48359 16.2167 3.48359 16.5333 3.67886 16.7286C3.87412 16.9238 4.1907 16.9238 4.38596 16.7286L3.67886 16.0214ZM5.44662 15.6679C5.64189 15.4726 5.64189 15.156 5.44662 14.9608C5.25136 14.7655 4.93478 14.7655 4.73952 14.9608L5.44662 15.6679ZM4.38596 16.7286L5.44662 15.6679L4.73952 14.9608L3.67886 16.0214L4.38596 16.7286Z" fill="#5D97F6"/>
<path d="M14.6182 5.08212C14.4229 5.27738 14.4229 5.59396 14.6182 5.78922C14.8134 5.98448 15.13 5.98448 15.3253 5.78922L14.6182 5.08212ZM16.386 4.72856C16.5812 4.5333 16.5812 4.21672 16.386 4.02146C16.1907 3.82619 15.8741 3.82619 15.6788 4.02146L16.386 4.72856ZM15.3253 5.78922L16.386 4.72856L15.6788 4.02146L14.6182 5.08212L15.3253 5.78922Z" fill="#5D97F6"/>
<path d="M10.4907 7.375L8.53241 10.391H11.5324" stroke="#2A2C2D" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M9.59491 13.375L11.5324 10.391" stroke="#2A2C2D" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
    </>
  );
}
export default HomeIcon;
