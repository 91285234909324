import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import {
  fetchNotificationGroupDetails, saveNotificationGroup, saveNotificationGroupDetails, deleteNotificationGroupDetails, fetchRole, replaceRoles, deleteRolesInNotificationGroupDetails,
} from '../../components/ConditionMonitoring/NotificationGroup/update-notification-group/notificationGroupDetailsAPI';
/* eslint max-len:0 */
const initialState = {
  notificationGroupDetails: [],
  notificationGroupDetailsStatus: 'idle',
  notificationGroup: {},
  notificationGroupSaveStatus: 'idle',
  saveDetails: { isNew: false },
  notificationGroupDetailsSaveStatus: 'idle',
  roles: [],
  deleteStatus: 'idle',
};

export const fetchNotificationGroupDetailsAsync = createAsyncThunk('fetchNotificationGroupDetails/fetchNotificationGroupDetails', async ({
  id, searchKey, pageIndex, pageCount,
}) => {
  const response = await fetchNotificationGroupDetails(id, searchKey, pageIndex, pageCount);
  return response;
});

export const saveNotificationGroupAsync = createAsyncThunk('saveNotificationGroup/saveNotificationGroup', async (notificationGroup) => {
  const response = await saveNotificationGroup(notificationGroup);
  return response;
});

export const saveNotificationGroupDetailsAsync = createAsyncThunk('saveNotificationGroupDetails/saveNotificationGroupDetails', async (notificationGroup) => {
  const response = await saveNotificationGroupDetails(notificationGroup);
  return response;
});

export const deleteNotificationGroupDetailsAsync = createAsyncThunk('deleteNotificationGroupDetails/deleteNotificationGroupDetails', async (notificationGroup) => {
  const response = await deleteNotificationGroupDetails(notificationGroup);
  return response;
});

export const deleteRolesInNotificationGroupDetailsAsync = createAsyncThunk('deleteRolesInNotificationGroupDetails/deleteRolesInNotificationGroupDetails', async (notificationGroup) => {
  const response = await deleteRolesInNotificationGroupDetails(notificationGroup);
  return response;
});

export const fetchRoleAsync = createAsyncThunk('notificationGroupDetails/fetchRole', async () => {
  const response = await fetchRole();
  return response;
});

export const replaceRolesAsync = createAsyncThunk('notificationGroupDetails/replaceRoles', async (roles) => {
  const response = await replaceRoles(roles);
  return response;
});

export const notificationGroupDetails = createSlice({
  name: 'notificationGroupDetails',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchNotificationGroupDetailsAsync.pending, (state) => {
        state.notificationGroupDetailsStatus = 'loading';
      })
      .addCase(fetchNotificationGroupDetailsAsync.fulfilled, (state, action) => {
        state.notificationGroupDetailsStatus = 'idle';
        state.notificationGroupDetails = action.payload;
      })
      .addCase(replaceRolesAsync.pending, (state) => {
        console.log(state);
      })
      // .addCase(replaceRolesAsync.fulfilled, (state, action) => {
      .addCase(replaceRolesAsync.fulfilled, () => {
        message.success('Record updated successfully');
      })
      .addCase(fetchRoleAsync.pending, (state) => {
        console.log(state);
      })
      .addCase(fetchRoleAsync.fulfilled, (state, action) => {
        state.roles = action.payload ? action.payload : [];
      })
      .addCase(saveNotificationGroupAsync.pending, (state) => {
        state.notificationGroupSaveStatus = 'loading';
      })
      .addCase(saveNotificationGroupAsync.fulfilled, (state, action) => {
        state.notificationGroup = action.payload;
        state.notificationGroupSaveStatus = 'idle';
        message.success('Record saved successfully');
      })
      // .addCase(saveNotificationGroupAsync.rejected, (state, action) => {
      .addCase(saveNotificationGroupAsync.rejected, (state) => {
        state.notificationGroupSaveStatus = 'idle';
        message.error('Unable save record');
      })
      .addCase(saveNotificationGroupDetailsAsync.pending, (state) => {
        state.notificationGroupDetailsSaveStatus = 'loading';
      })
      .addCase(saveNotificationGroupDetailsAsync.fulfilled, (state, action) => {
        state.saveDetails = action.payload;
        state.notificationGroupDetailsSaveStatus = 'idle';
        message.success('Record saved successfully');
      })
      // .addCase(saveNotificationGroupDetailsAsync.rejected, (state, action) => {
      .addCase(saveNotificationGroupDetailsAsync.rejected, (state) => {
        state.notificationGroupDetailsSaveStatus = 'idle';
        message.error('Unable save record');
      })
      .addCase(deleteNotificationGroupDetailsAsync.pending, (state) => {
        state.deleteStatus = 'loading';
      })
      // .addCase(deleteNotificationGroupDetailsAsync.fulfilled, (state, action) => {
      .addCase(deleteNotificationGroupDetailsAsync.fulfilled, (state) => {
        state.deleteStatus = 'idle';
        message.success('Record deleted successfully');
      })
      // .addCase(deleteNotificationGroupDetailsAsync.rejected, (state, action) => {
      .addCase(deleteNotificationGroupDetailsAsync.rejected, (state) => {
        state.notificationGroupSaveStatus = 'idle';
        message.error('Unable to delete the record');
      })
      .addCase(deleteRolesInNotificationGroupDetailsAsync.pending, (state) => {
        state.deleteStatus = 'loading';
      })
      // .addCase(deleteRolesInNotificationGroupDetailsAsync.fulfilled, (state, action) => {
      .addCase(deleteRolesInNotificationGroupDetailsAsync.fulfilled, (state) => {
        state.deleteStatus = 'idle';
        message.success('Record deleted succesfully');
      })
      // .addCase(deleteRolesInNotificationGroupDetailsAsync.rejected, (state, action) => {
      .addCase(deleteRolesInNotificationGroupDetailsAsync.rejected, (state) => {
        state.notificationGroupSaveStatus = 'idle';
        message.error('Unable to delete the record');
      });
  },
});

export default notificationGroupDetails.reducer;
