import { useEffect, useState } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { ToastContainer } from 'react-toastify';
import AppRoutes from './config/api.config.json';
import Login from "./components/Login/Login";
import Dashboard from "./components/Dashboard/Dashboard";
import ChangePassword from "./components/Change Password/ChangePassword";
import Report from "./components/Report/Report";
import Slitter from "./components/Report/sllitter";
import Enery from "./components/Report/enery";
import ConditionMonitoringComponent from './components/ConditionMonitoring/Condition-Monitoring/condition-monitoring';
import AddGeneralCondition from './components/ConditionMonitoring/general-condition/AddGeneralCondition';
import AddFailureCondition from './components/ConditionMonitoring/failure-notification/AddFailureNotification';
import AddMaintainaceMaster from './components/ConditionMonitoring/maintenance-condition/AddMaintenanceCondition';
import NotificationGroupComponent from './components/ConditionMonitoring/NotificationGroup/notification-group';
import UpdateNotificationGroupComponent from './components/ConditionMonitoring/NotificationGroup/update-notification-group/update-notification-group';
// import ConditionMonitoringDashboard from './components/ConditionMonitoring/CmDashboard/conditionMonitoringDashboard';
import NotificationTemplateComponent from './components/ConditionMonitoring/NotificationTemplate/notification-template';
import ExecutionResultComponent from './components/ConditionMonitoring/ExecutionResult/execution-result';
import AlertNotificationLogComponent from './components/ConditionMonitoring/alerts-notifications/alertnotifications';
import FmeaRepository from './components/ConditionMonitoring/FMEA/failure-mode-listing/FMEA_Repository';
import { FailureModeDetails } from './components/ConditionMonitoring/FMEA/failure-mode-details/FailureModeDetails';

import './App.css';
import './styles/Home.css';
import './styles/dashboard.css';
import './styles/events.css';
import './styles/filter.css';
import './styles/breadcrum.css';
import './styles/cm.css';

function App(props) {
  // useEffect(() => {
  //   window.addEventListener("beforeunload", (ev) => {
  //     ev.preventDefault();
  //     return (ev.returnValue = "Are you sure you want to close?");
  //   });
  // }, []);
  // GETTING ROUTES
const {
  CmDashboard, NotificationGroup, NotificationTemplate, UpdateNotificationGroup,
  ExecutionResult, AlertNotificationLog, CmGeneralConditionAdd,
  CmGeneralConditionActions, CmFailureConditionAdd, CmFailureConditionActions, ConditionMasters,
  CmMaintainanceConditionAdd, CmMaintainanceConditionActions,
  FailureModeDetails: FailureModeDetailsRoute, FailureModeListingTabs
} = AppRoutes;
  return (
    <div className="">
      <BrowserRouter>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          {/* <LanguageContext.Provider
            value={[Language, EnglishConverter, HindiConverter]}
          > */}
            <header className=""></header>
            <Switch>

              <Route exact path="/home">
                <Dashboard>
                  <Report />
                </Dashboard>
              </Route>
              <Route exact path="/slitter">
                <Dashboard>
                  <Slitter />
                </Dashboard>
              </Route>
              <Route exact path="/en1m">
                <Dashboard>
                  <Enery />
                </Dashboard>
              </Route>

              {/* <Route exact path={CmDashboard}>
                <Dashboard>
                  {Menus.COND !== undefined ? <ConditionMonitoringDashboard /> : ''}
                </Dashboard>
              </Route> */}
              <Route exact path={NotificationGroup}>
                <Dashboard>
                  {/* {Menus.COND !== undefined ? <NotificationGroupComponent /> : ''} */}
                  <NotificationGroupComponent />
                </Dashboard>
              </Route>
              <Route exact path={UpdateNotificationGroup}>
                <Dashboard>
                  {/* {Menus.COND !== undefined ? <UpdateNotificationGroupComponent /> : ''} */}
                  <UpdateNotificationGroupComponent />
                </Dashboard>
              </Route>
              <Route exact path={NotificationTemplate}>
                <Dashboard>
                  {/* {Menus.COND !== undefined ? <NotificationTemplateComponent /> : ''} */}
                  <NotificationTemplateComponent />
                </Dashboard>
              </Route>
              <Route exact path={ConditionMasters}>
                <Dashboard>
                  {/* {Menus.COND !== undefined ? <ConditionMonitoringComponent /> : ''} */}
                  <ConditionMonitoringComponent />
                </Dashboard>
              </Route>
              <Route exact path={CmGeneralConditionAdd}>
                <Dashboard>
                  {/* {Menus.COND !== undefined ? <AddGeneralCondition /> : ''} */}
                  <AddGeneralCondition />
                </Dashboard>
              </Route>
              <Route exact path={CmGeneralConditionActions}>
                <Dashboard>
                  {/* {Menus.COND !== undefined ? <AddGeneralCondition /> : ''} */}
                  <AddGeneralCondition />
                </Dashboard>
              </Route>
              <Route exact path={CmFailureConditionAdd}>
                <Dashboard>
                  {/* {Menus.COND !== undefined ? <AddFailureCondition /> : ''} */}
                  <AddFailureCondition />
                </Dashboard>
              </Route>
              <Route exact path={CmFailureConditionActions}>
                <Dashboard>
                  {/* {Menus.COND !== undefined ? <AddFailureCondition /> : ''} */}
                  <AddFailureCondition />
                </Dashboard>
              </Route>
              <Route exact path={CmMaintainanceConditionAdd}>
                <Dashboard>
                  {/* {Menus.COND !== undefined ? <AddMaintainaceMaster /> : ''} */}
                  <AddMaintainaceMaster />
                </Dashboard>
              </Route>
              <Route exact path={CmMaintainanceConditionActions}>
                <Dashboard>
                  {/* {Menus.COND !== undefined ? <AddMaintainaceMaster /> : ''} */}
                  <AddMaintainaceMaster />
                </Dashboard>
              </Route>
              <Route exact path={ExecutionResult}>
                <Dashboard>
                  {/* {Menus.COND !== undefined ? <ExecutionResultComponent /> : ''} */}
                  <ExecutionResultComponent />
                </Dashboard>
              </Route>
              <Route exact path={AlertNotificationLog}>
                <Dashboard>
                  {/* {Menus.COND !== undefined ? <AlertNotificationLogComponent /> : ''} */}
                  <AlertNotificationLogComponent />
                </Dashboard>
              </Route>
              <Route exact path={FailureModeListingTabs}>
                <Dashboard>
                  {/* {Menus.FMEA !== undefined ? <FmeaRepository /> : ''} */}
                  <FmeaRepository />
                </Dashboard>
              </Route>
              <Route exact path={FailureModeDetailsRoute}>
                <Dashboard>
                  <FailureModeDetails />
                </Dashboard>
              </Route>
              <Route exact path="/settings/Change-password">
                <Dashboard>
                  <ChangePassword />
                </Dashboard>
              </Route>
              <Route exact path="/" component={Login} />

            </Switch>
          {/* </LanguageContext.Provider> */}
        </MuiPickersUtilsProvider>
        <div className="content" />
        <footer></footer>
      </BrowserRouter>
    </div>
  );
}

export default App;

// "react-dom": "^17.0.2",
// "react-router-dom": "^6.0.2",
