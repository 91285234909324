import React from 'react';
// import FocusTrap from "focus-trap-react";
import '../../components/ConditionMonitoring/Condition-Monitoring/condition-monitoring.css';

const ConfirmationDialog = ({
  title, text, onDoneClicked, onClosed,
}) => {
  const onSaveBtnClicked = () => {
    onDoneClicked();
  };
  const style = { modelBox: { height: 'auto', width: '40rem' } };
  return (
    <div className="modelboxopen confirmPin delete-modal">
      <div className="modelboxopenInnner" style={style.modelBox} id="modelboxopenInnner4">
        <div className="wrapContent">
          <div className="containerIner2">
            <div className="popupCloseButton bbt">
              <h3 className="titleDiv">{title}</h3>
              <div
                className="closepopup pointer"
                onClick={onClosed}
              >
                &#x2715;
              </div>
            </div>
            <div className="contentPopup">
              <div className="innerContentPopup">
                <div className="descriptionCreate">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group formgroup">
                        <div className="enterpins placeColor">
                          <div className="font14">{text}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="footerPopupWithBtn">
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <button
                      type="button"
                      id="cancelBtn"
                      name="cancel"
                      className="btn btn-default cancelBtn"
                      onClick={onClosed}
                    >
                      No
                    </button>
                    <button
                      type="button"
                      id="saveBtn"
                      name="Save"
                      className="btn btn-default saveBtn"
                      onClick={onSaveBtnClicked}
                    >
                      Yes
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationDialog;
