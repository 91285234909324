import { APIUrl } from '../../../../helper/constants';
import CallAPI from '../../../../services/conditionMonitoring.service';

export function fetchNotificationGroupDetails(id, searchKey, pageindex, pageCount) {
  const apiInfo = {
    url: `${APIUrl}/api/getNotificationGroupDetails?id=${id}&searchKey=${searchKey}&pageindex=${pageindex}&pageCount=${pageCount}`,
    method: 'GET',
    isMultiPart: false,
  };
  return CallAPI(apiInfo).then((res) => res.data);
  // return axios
  //   .get(
  //     `${APIUrl}/api/getNotificationGroupDetails?id=${id}&searchKey=${searchKey}&pageindex=${pageindex}&pageCount=${pageCount}`, { headers },
  //   )
  //   .then((res) => {
  //     console.log('>>>>>>>>> ', res.data);
  //     return res.data;
  //   });
}

export function fetchRole() {
  const apiInfo = {
    url: `${APIUrl}/api/getRole`,
    method: 'GET',
    isMultiPart: false,
  };
  return CallAPI(apiInfo).then((res) => res.data);
  // return axios.get(`${APIUrl}/api/getRole`, { headers }).then((res) => res.data);
}

export function saveNotificationGroup(notificationGroup) {
  const apiInfo = {
    url: `${APIUrl}/api/saveNotificationGroup`,
    method: 'POST',
    postData: notificationGroup,
    isMultiPart: false,
  };
  return CallAPI(apiInfo).then((res) => res.data);
  // return axios.post(`${APIUrl}/api/saveNotificationGroup`, notificationGroup, { headers }).then((res) => res.data);
}

export function saveNotificationGroupDetails(notificationGroup) {
  const apiInfo = {
    url: `${APIUrl}/api/saveNotificationGroupDetails`,
    method: 'POST',
    postData: notificationGroup,
    isMultiPart: false,
  };
  return CallAPI(apiInfo).then((res) => res.data);
  // return axios.post(`${APIUrl}/api/saveNotificationGroupDetails`, notificationGroup, { headers }).then((res) => res.data);
}

export function deleteNotificationGroupDetails(id) {
  const apiInfo = {
    url: `${APIUrl}/api/deleteNotificationGroupDetails?id=${id}`,
    method: 'GET',
    isMultiPart: false,
  };
  return CallAPI(apiInfo).then((res) => res.data);
  // return axios.get(`${APIUrl}/api/deleteNotificationGroupDetails?id=${id}`, { headers }).then((res) => res.data);
}

export function deleteRolesInNotificationGroupDetails(id) {
  const apiInfo = {
    url: `${APIUrl}/api/deleteRolesInNotificationGroupDetails?notificationGroupDetailID=${id}`,
    method: 'GET',
    isMultiPart: false,
  };
  return CallAPI(apiInfo).then((res) => res.data);
  // return axios.get(`${APIUrl}/api/deleteRolesInNotificationGroupDetails?notificationGroupDetailID=${id}`, { headers }).then((res) => res.data);
}

export function replaceRoles(roles) {
  const apiInfo = {
    url: `${APIUrl}/api/replaceRoles`,
    method: 'POST',
    postData: { Roles: roles },
    isMultiPart: false,
  };
  return CallAPI(apiInfo).then((res) => res.data);
  // return axios.post(`${APIUrl}/api/replaceRoles`, { Roles: roles }, { headers }).then((res) => res.data);
}
