import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import {
  fetchNotificationTemplate, saveNotificationTemplate, deleteNotificationTemplate, getTemplatePlaceHolder, getAccessRole,
} from '../../components/ConditionMonitoring/NotificationTemplate/notificationTemplateAPI';

const initialState = {
  notificationTemplate: [],
  notificationTemplateStatus: 'idle',
  savedNotificationTemplate: {},
  notificationTemplateSaveStatus: 'idle',
  deleteStatus: 'idle',
  accessRoleStatus: 'idle',
  accessRoleValue: {},
  templatePlaceholder: [],
};

export const fetchNotificationTemplateAsync = createAsyncThunk('notificationTemplate/fetchNotificationTemplate', async ({
  id, searchKey, pageIndex, pageCount,
}) => {
  const result = await fetchNotificationTemplate(id, searchKey, pageIndex, pageCount);
  return result;
});

export const saveNotificationTemplateAsync = createAsyncThunk('notificationTemplate/saveNotificationTemplate', async (data) => {
  const result = await saveNotificationTemplate(data);
  return result;
});

export const deleteNotificationTemplateAsync = createAsyncThunk('notificationTemplate/deleteNotificationTemplate', async (id) => {
  const result = await deleteNotificationTemplate(id);
  return result;
});

export const getAccessRoleAsync = createAsyncThunk('notificationTemplate/getAccessRole', async (id) => {
  const result = await getAccessRole(id);
  return result;
});

export const getTemplatePlaceHolderAsync = createAsyncThunk('notificationTemplate/getTemplatePlaceHolder', async (id) => {
  console.log(id);
  const result = await getTemplatePlaceHolder();
  return result;
});

export const notificationTemplate = createSlice({
  name: 'notificationTemplate',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchNotificationTemplateAsync.pending, (state) => {
        state.notificationTemplateStatus = 'loading';
      })
      .addCase(fetchNotificationTemplateAsync.fulfilled, (state, action) => {
        state.notificationGroupDetailsStatus = 'idle';
        state.notificationTemplate = action.payload;
      })
      .addCase(getAccessRoleAsync.pending, (state) => {
        state.accessRoleStatus = 'loading';
      })
      .addCase(getAccessRoleAsync.fulfilled, (state, action) => {
        state.accessRoleStatus = 'idle';
        state.accessRoleValue = action.payload;
      })
      .addCase(saveNotificationTemplateAsync.fulfilled, (state, action) => {
        message.success('Record saved successfully');
        state.savedNotificationTemplate = action.payload ? action.payload : [];
      })
      .addCase(saveNotificationTemplateAsync.pending, (state) => {
        state.notificationTemplateSaveStatus = 'loading';
      })
      .addCase(saveNotificationTemplateAsync.rejected, (state) => {
        state.notificationTemplateSaveStatus = 'idle';
        message.error('Unable to save record');
      })
      .addCase(deleteNotificationTemplateAsync.pending, (state) => {
        state.deleteStatus = 'loading';
      })
      .addCase(deleteNotificationTemplateAsync.fulfilled, (state) => {
        state.deleteStatus = 'idle';
        message.success('Record deleted successfully');
      })
      .addCase(deleteNotificationTemplateAsync.rejected, (state, action) => {
        state.notificationGroupSaveStatus = 'idle';
        if (action.error && action.error.message.includes('512')) {
          message.error('Unable to delete the Template, as it is associated with other conditions');
        } else {
          message.error('Unable to delete the Template');
        }
      })
      .addCase(getTemplatePlaceHolderAsync.fulfilled, (state, action) => {
        state.templatePlaceholder = action.payload ? action.payload : [];
      })
      .addCase(getTemplatePlaceHolderAsync.rejected, (state, action) => {
        console.log(state, action);
      })
      .addCase(getTemplatePlaceHolderAsync.pending, (state) => {
        console.log(state);
      });
  },
});

export default notificationTemplate.reducer;
