import axios from 'axios';
// import { user_id, APIUrl } from '../../../../app/configs/config';
import { APIUrl } from '../../../helper/constants';
// A mock function to mimic making an async request for data
import authHeader from '../../../config/auth.header';

const headers = {
  // headers: authHeader(),
  headers: {
    "x-access-token": localStorage.getItem('token'),
    refreshToken: localStorage.getItem('token'),
  }
};
export function fetchMachineModel() {
  return axios
    .get(

      `${APIUrl}/api/getMachineModel`, headers,
    )
    .then((res) => res.data);
}

export function fetchMachineModelName(modelName) {
  return axios
    .get(

      `${APIUrl}/api/getMachineModelDetails?modelName=${modelName}`, headers,
    )
    .then((res) => res.data);
}

export function fetchCustomerName() {
  return axios
    .get(

      `${APIUrl}/api/getCustomerForMachine`, headers,
    )
    .then((res) => res.data);
}

export function fetchExecutionResult(page, size, model, machine, customer, type, from, to) {
  return axios
    .get(
      `${APIUrl}/api/getExecutionResult?page=${page}&size=${size}&machinemodel=${model}&machine=${machine}&customer=${customer}&conditiontype=${type}&datefrom=${from}&dateto=${to}`, headers,
    )
    .then((res) => res.data);
}

export function fetchConditionWise(model, machine, customer, type, from, to) {
  return axios
    .get(
      `${APIUrl}/api/getConditionWise?machinemodel=${model}&machine=${machine}&customer=${customer}&conditiontype=${type}&datefrom=${from}&dateto=${to}`, headers,
    )
    .then((res) => res.data);
}

export function fetchModelWise(model, machine, customer, from, to) {
  return axios
    .get(
      `${APIUrl}/api/getModelWise?machinemodel=${model}&machine=${machine}&customer=${customer}&datefrom=${from}&dateto=${to}`, headers,
    )
    .then((res) => res.data);
}

export function fetchMachineWise(model, machine, customer, from, to) {
  return axios
    .get(
      `${APIUrl}/api/getMachineWise?machinemodel=${model}&machine=${machine}&customer=${customer}&datefrom=${from}&dateto=${to}`, headers,
    )
    .then((res) => res.data);
}

export function fetchFrequencyWise(model, machine, customer, type, from, to) {
  return axios
    .get(
      `${APIUrl}/api/getFrequencyWise?machinemodel=${model}&machine=${machine}&customer=${customer}&conditiontype=${type}&datefrom=${from}&dateto=${to}`, headers,
    )
    .then((res) => res.data);
}
