import React from 'react';
// import Autocomplete from '@material-ui/lab/Autocomplete';
// import { Field } from 'react-final-form';
import './condition-monitoring.css';
// import { useHistory } from 'react-router-dom';
// import DataTable from './Components/DataTable';
// import DropdownFilter from './Components/DropdownFilter';
import Tabs from '../Components/tabs';
// import { DashboardContext } from '../../../context/dashboardContext';
// import { handleURLRouteParameter } from '../../../services/handleRoutes';
// import AccordianControl from './Components/AccordianControl';

const ConditionMonitoring = () => (
  <>
    <div className="wrapper cm-wrapper">
      <div className="contentWrap conditionMonitoring monitoringcontentWrap monitoringPage">
        <Tabs />
      </div>
    </div>
  </>
);
export default ConditionMonitoring;
