import { makeStyles } from '@material-ui/styles';
import { createTheme } from '@material-ui/core/styles';

export const theme = createTheme({
  overrides: {
    MuiPaper: {
      root: {
        backgroundColor: 'transparent',
      },
    },
  },
  typography: {
    htmlFontSize: 16,
    fontSize: 14,
    fontFamily: 'Montserrat-Medium',
  },
  palette: {
    action: {
      disabledBackground: '#3b5b8f',
      disabled: '#A0A0A0',
    },
  },
});

export const useStyles = makeStyles({
  removeUnderline: {
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
      borderColor: ' #1f1f1f',
    },
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      border: 'none',
      borderColor: '#1f1f1f',
    },
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input':
      {
        padding: 0,
      },
  },
  dialog: {
    '& .MuiDialog-paperWidthSm': {
      maxWidth: '800px',
    },
  },
  paper: { minWidth: '450px' },
  UploadPaper: { minWidth: '450px', width: '600px' },
  dialogTitle: {
    '& .MuiTypography-body1': {
      fontSize: '16px',
      color: ({ currentTheme }) => (currentTheme === 'dark' ? '#e0e0e0' : '#3B414B'),
    },

    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: ({ currentTheme }) => (currentTheme === 'dark' ? '#1f1f1f' : '#fff'),
    color: ({ currentTheme }) => (currentTheme === 'dark' ? '#e0e0e0' : '#3B414B'),
    borderBottom: ({ currentTheme }) => (currentTheme === 'dark' ? '1px solid #313131' : '1px solid #eeeeee'),
  },

  dialogContent: {
    padding: '30px 30px 40px 30px',
    backgroundColor: ({ currentTheme }) => (currentTheme === 'dark' ? '#1f1f1f' : '#fff'),
    color: ({ currentTheme }) => (currentTheme === 'dark' ? 'white' : 'black'),
    display: 'flex',
    flexDirection: 'column',
  },
  confDialogContent: {
    // display: 'flex',
    // flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '30px 30px 40px 30px',
    backgroundColor: ({ currentTheme }) => (currentTheme === 'dark' ? '#1f1f1f' : '#fff'),
    color: ({ currentTheme }) => (currentTheme === 'dark' ? 'white' : 'black'),
    display: 'flex',
    flexDirection: 'column',
  },
  uploadConfDialogContent: {
    // justifyContent: "center",
    alignItems: 'center',
    padding: '30px 0 40px 0',
    backgroundColor: ({ currentTheme }) => (currentTheme === 'dark' ? '#1f1f1f' : '#fff'),
    color: ({ currentTheme }) => (currentTheme === 'dark' ? 'white' : 'black'),
  },

  dialogActions: {
    display: 'flex',
    padding: '2.5rem',
    // paddingBottom: "3rem",
    // paddingRight: "3rem",
    backgroundColor: ({ currentTheme }) => (currentTheme === 'dark' ? '#1f1f1f' : '#fff'),
    borderTop: ({ currentTheme }) => (currentTheme === 'dark' ? '#313131' : '1px solid #eeeeee'),
    boxShadow: ({ currentTheme }) => (currentTheme === 'dark' ? '0 -5px 5px -5px #2f2f2f' : 'none'),
  },
  act: {
    display: 'flex',
    padding: '2.5rem',
    // paddingBottom: "3rem",
    // paddingRight: "3rem",
    backgroundColor: ({ currentTheme }) => (currentTheme === 'dark' ? '#1f1f1f' : '#fff'),
  },

  phDown: {
    '& .MuiList-root': {
      backgroundColor: ({ currentTheme }) => (currentTheme === 'dark' ? '#1f1f1f' : '#fff'),
      border: '1px solid #313131',
      color: ({ currentTheme }) => (currentTheme === 'dark' ? '#e0e0e0' : '#3b414b'),
    },
  },
  phInput: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    padding: '.5rem 0',
    border: ({ currentTheme }) => (currentTheme === 'dark'
      ? '0.5px solid rgba(204, 204, 204, 0.6)'
      : '1px solid #1f1f1f'),
    borderRadius: '4px',
    '& .MuiInputBase-root.Mui-disabled': {
      color: '#3b414b',
    },
    '&.Mui-focused': {
      borderColor: 'rgba(204, 204, 204, 0.6)',
    },
    '& .MuiPhoneNumber-flagButton': {
      all: 'unset',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: ({ currentTheme }) => (currentTheme === 'dark' ? '#1f1f1f' : '#fff'),

      height: '34px',
      minWidth: '40px',
      cursor: 'pointer',
    },
    marginBottom: '3rem',
  },
  checkBox: {
    color: ({ currentTheme }) => (currentTheme === 'dark' ? '#e0e0e0' : '#3B414B'),
    '& .MuiSvgIcon-root': {
      fontSize: '2rem',
    },
    '& .MuiIconButton-label': {
      color: '#5d97f6',
    },
    '& .MuiTypography-body1': {
      fontSize: '1.2rem',
    },
  },
  input: {
    all: 'unset',
    border: ({ currentTheme }) => (currentTheme === 'dark' ? '1px solid #1f1f1f' : '1px solid #1f1f1f'),
    borderRadius: '4px',
    '&.Mui-focused': {
      borderColor: '#e0e0e0',
    },
  },
  tField: {
    padding: '4px 6px',
    marginBottom: '3rem',
    border: ({ currentTheme }) => (currentTheme === 'dark'
      ? '0.5px solid rgba(204, 204, 204, 0.6)'
      : '1px solid #1f1f1f'),
    borderRadius: '4px',
    '& .MuiInputBase-root.Mui-disabled': {
      color: '#3b414b',
    },
  },
  tFieldAuto: {
    width: '100%',
    padding: '4px 0px',
    marginBottom: '3rem',
    border: ({ currentTheme }) => (currentTheme === 'dark'
      ? '0.5px solid rgba(204, 204, 204, 0.6)'
      : '1px solid #1f1f1f'),
    borderRadius: '4px',

    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input':
      {
        padding: '6px',
      },
  },

  cancelBtn: {
    fontSize: '14px',
    height: '40px',
    width: '100px',
    textTransform: 'none',
    color: ({ currentTheme }) => (currentTheme === 'dark' ? '#e0e0e0' : '#fff'),
    backgroundColor: ({ currentTheme }) => (currentTheme === 'dark' ? '#2f2f2f' : '#b6b6b7'),
    borderColor: '#fff',
    '&:hover': {
      backgroundColor: ({ currentTheme }) => (currentTheme === 'dark' ? '#2f2f2f' : '#3e3e3e'),
      borderColor: '#2f2f2f',
      boxShadow: 'none',
    },
    boxShadow: 'none',
  },
  saveBtn: {
    fontSize: '14px',
    height: '40px',
    width: '100px',
    color: '#e0e0e0',
    textTransform: 'none',
    backgroundColor: '#3b5b8f',
    borderColor: '#3b5b8f',
    '&:hover': {
      backgroundColor: '#5d97f6',
      borderColor: '#5d97f6',
    },
    boxShadow: 'none',
  },

  // removeUnderline: {
  //   underline: {
  //     "&&&:before": {
  //       borderBottom: "none",
  //     },
  //     "&&:after": {
  //       borderBottom: "none",
  //     },
  //   },
  // },
  delPopupTitle: {
    color: ({ currentTheme }) => (currentTheme === 'dark' ? '#e0e0e0' : '#3B414B'),
    fontSize: '1.5rem',
    fontWeight: '400',
    marginBottom: '1rem',
  },
  delPopupRadio: {
    color: ({ currentTheme }) => (currentTheme === 'dark' ? '#e0e0e0' : '#3B414B'),

    '& .MuiRadio-root ': {
      color: ({ currentTheme }) => (currentTheme === 'dark' ? '#5d97f6' : '#5d97f6'),
      fontSize: '1.2rem',
    },

    '& .MuiIconButton-label': {
      color: ({ currentTheme }) => (currentTheme === 'dark' ? '#5d97f6' : '#5d97f6'),
    },

    '& .MuiTypography-body1': {
      fontSize: '1.2rem',
      fontWeight: 200,
    },
  },
  autoComplete: {
    '& .MuiAutocomplete-endAdornment': {
      top: '50%',
      transform: 'translateY(-50%)',
    },
  },
  autocompleteOptions: {
    width: '100%',
    color: '#e0e0e0',
    padding: '0',
  },
  popper: {
    marginTop: '10px',
  },
  listBox: {
    width: '101.3%',
    position: 'absolute',
    top: '0',
    left: '-1.5px',
    backgroundColor: ({ currentTheme }) => (currentTheme === 'dark' ? '#1f1f1f' : '#fff'),
    opacity: 1,
  },
  clearIcon: {
    marginTOp: '10px',
  },
  autocompleteCard: {
    backgroundColor: ({ currentTheme }) => (currentTheme === 'dark' ? '#252525' : '#fff'),
    width: '100%',
    borderRadius: '0px',
    color: ({ currentTheme }) => (currentTheme === 'dark' ? '#e0e0e0' : '#58585a'),

    marginBottom: '0',
    '&:hover': {
      backgroundColor: ({ currentTheme }) => (currentTheme === 'dark' ? '#2a2c2d' : '#e8f1fd'),
    },
  },
  optionAvatar: {
    fontSize: '9px',
    width: '25.92px',
    height: '25.92px',
    color: ({ currentTheme }) => (currentTheme === 'dark' ? '#e0e0e0' : '#3b414b'),
    backgroundColor: ({ currentTheme }) => (currentTheme === 'dark' ? '#414346' : '#CECECE'),
  },
  optionHeader: {
    position: 'relative',
    padding: '10px 14px',
  },
  optionHeaderTitle: {
    fontSize: '10px',
  },
  optionSubheader: {
    fontSize: '9px',
    color: ({ currentTheme }) => (currentTheme === 'dark' ? '#a0a0a0' : '#58585a'),
  },

  arrow: {
    fontSize: '14px',
    color: ({ currentTheme }) => (currentTheme === 'dark' ? '#2f2f2f' : '#D2E3FC'),
  },

  tooltipAvatar: {
    position: 'absolute',
    top: '19.5px',
    left: '15.73px',
    fontSize: '9px',
    width: '33.33px',
    height: '33.33px',
    color: ({ currentTheme }) => (currentTheme === 'dark' ? '#e0e0e0' : '#3B414B'),
    backgroundColor: ({ currentTheme }) => (currentTheme === 'dark' ? '#414346' : '#CECECE'),
  },
  tooltipDiv: {
    width: '100%',
    height: 'auto',
    padding: '10px',
    fontSize: '12px',
    lineHeight: '18px',
    backgroundColor: ({ currentTheme }) => (currentTheme === 'dark' ? '#2f2f2f' : '#D2E3FC'),
    borderRadius: '4px',
    color: ({ currentTheme }) => (currentTheme === 'dark' ? '#e0e0e0' : '#3B414B'),
  },
  tooltipPopper: {
    padding: '0px',
  },
  tooltipCard: {
    padding: '0px',
    backgroundColor: ({ currentTheme }) => (currentTheme === 'dark' ? '#2f2f2f' : '#D2E3FC'),
    minWidth: '200px',
    borderRadius: '4px',
    color: ({ currentTheme }) => (currentTheme === 'dark' ? '#e0e0e0' : '#3B414B'),

    marginBottom: '0',
  },
  tooltipOptionHeader: {
    position: 'relative',
    padding: '19.5px 15.73px',
  },
  tooltipOptionHeaderTitle: {
    fontWeight: '500',
    marginLeft: '27px',
    fontSize: '12px',
  },
  tooltipOptionSubheader: {
    fontWeight: '400',
    marginLeft: '27px',
    fontSize: '10px',
    color: ({ currentTheme }) => (currentTheme === 'dark' ? '#e0e0e0' : '#3B414B'),
  },
});
