/* eslint-disable import/prefer-default-export */
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Drawer } from 'antd';
import { deleteFailureIndicatorAsync } from '../../../../redux/slices/failureIndicatorsViewSlice';
import { FailureIndicatorAddComponent } from './FailureIndicatorsAdd';
import '../../../../styles/failureMode.css';
import { ReactComponent as Edit } from '../../../../images/editSpoc.svg';
import { ReactComponent as Delete } from '../../../../images/deleteIcon.svg';
// import Icon from '../../../Dashboard/Sidebar/images/Icon';

export const FailureIndicatorsView = () => {
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [confirmLoading, setConfirmLoading] = useState(false);
  // const [modalText, setModalText] = useState('Are you sure to delete this?');
  const modalText = 'Are you sure to delete this?';
  const [editItem, setEditItem] = useState();
  const [editVisible, setEditVisible] = useState(false);

  const onDeleteIndicatorClick = (id) => {
    setDeleteId(id);
    setVisible(true);
  };

  const onEditIndicatorClick = (item) => {
    setEditItem(item);
    setEditVisible(true);
  };

  const handleOk = () => {
    dispatch(deleteFailureIndicatorAsync(deleteId));
    setDeleteId(undefined);
    setConfirmLoading(true);
    setTimeout(() => {
      setVisible(false);
      setConfirmLoading(false);
    }, 2000);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const editFailure = useSelector(
    (state) => state.failureModeDetails.editFailureMode,
  );

  const indicatorValues = useSelector(
    (state) => state.failureModeDetails.editFailureMode.failureindicators,
  );

  return (
    <>
      <div className="recommended-action-list">
        {indicatorValues && indicatorValues.length > 0 && (
        <div className="sub-grid-label">FORMULA</div>)}

        <div>
          {indicatorValues
            && indicatorValues.map((item) => (
              <div key={item.FailureIndicator_ID} className="card">

                <label>
                  {item.IndirectFormula}
                </label>
                {' '}

                <div className="pull-right edit">
                  <label
                    className="col-sm-1"
                    onClick={() => {
                      onEditIndicatorClick(item);
                    }}
                  >
                    {/* <Icon name="EditIcon" /> */}
                    <Edit />
                  </label>

                  <label
                    className="col-sm-1"
                    onClick={() => {
                      onDeleteIndicatorClick(item.FailureIndicator_ID);
                    }}
                  >
                    {/* <Icon name="DeleteIcon" /> */}
                    <Delete />
                  </label>
                </div>
              </div>
            ))}
          {(indicatorValues === undefined || indicatorValues.length === 0) && (
            <div>
              <p>No Records Available</p>
            </div>
          )}
        </div>
      </div>
      <Modal
        title="Delete"
        visible={visible}
        width={300}
        onOk={handleOk}
        okText="Delete"
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        className="failuremodulePage"
      >
        <p>{modalText}</p>
      </Modal>

      {editVisible && (
        <Drawer
          title="Add Failure Indicators"
          placement="right"
          height="100%"
          closable
          closeIcon={<div className="closepopup pointer"> &#x2715;</div>}
          visible={editVisible}
          className="failuremodulePage"
          onClose={() => {
            setEditVisible(false);
          }}
          push={{ distance: 378 }}
        >
          <FailureIndicatorAddComponent
            modelID={editFailure.MachineModel_ID}
            sectionID={editFailure.MachineSection_ID}
            functionID={editFailure.MachineFunction_ID}
            failureModeID={editFailure.FailureMode_ID}
            item={editItem}
            onAddFailureIndicatorClose={() => {
              setEditVisible(false);
            }}
          />
        </Drawer>
      )}
    </>
  );
};
