// import axios from 'axios';
// import { user_id, API_Url } from '../../configs/config';
import { APIUrl } from '../../../../helper/constants';
// import authHeader from '../../../../config/auth.header';
import CallAPI from '../../../../services/conditionMonitoring.service';
// const headers = {
//   headers: authHeader(),
// };

export function detailsOfFailureModes(search) {
  const apiInfo = {
    url: `${APIUrl}/api/failureModeDetails?search=${search}`,
    method: 'GET',
    isMultiPart: false,
  };
  return CallAPI(apiInfo).then((res) => res.data);
  // return axios
  //   .get(
  //     `${APIUrl}/api/failureModeDetails?search=${search}`, headers,
  //   )
  //   .then((res) => res.data);
}

export function editFailureModes(id) {
  const apiInfo = {
    url: `${APIUrl}/api/editFailureModeDetails?search=${id}`,
    method: 'GET',
    isMultiPart: false,
  };
  return CallAPI(apiInfo).then((res) => res.data);
  // return axios
  //   .get(`${APIUrl}/api/editFailureModeDetails?search=${id}`, headers)
  //   .then((res) => res.data);
}

export function getPotentialCauses() {
  const apiInfo = {
    url: `${APIUrl}/api/getPotentialCause?failureModeId=80`,
    method: 'GET',
    isMultiPart: false,
  };
  return CallAPI(apiInfo).then((res) => res.data);
  // return axios
  //   .get(`${APIUrl}/api/getPotentialCause?failureModeId=80`, headers)
  //   .then((res) => res.data);
}
