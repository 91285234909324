/* eslint-disable import/prefer-default-export */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import { Form, Field } from 'react-final-form';
// import { OnChange } from 'react-final-form-listeners';
import { useDispatch, useSelector } from 'react-redux';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import {
  Drawer, Button, Spin,
} from 'antd';
import { CausualParametersView } from '../causual-parameters/CausualParametersView';
import { setEditCausalParameter } from '../../../../redux/slices/failureModeDetailsSlice';
import { CausualParameterAdd } from '../causual-parameters/CausualParametersAdd';
// import { ReactComponent as Plus } from "../../../../src/components/dashboard/reports/ECR/svgs/plus.svg";
import Icon from '../../../Dashboard/Sidebar/images/Icon';
import {
  potentialCausesAddAsync,
  fetchOccurenceCriteriaAsync,
  fetchDetectionCriteriaAsync,
} from '../../../../redux/slices/potentialCauseAddSlice';
import '../../../../styles/failureMode.css';
import { RecommendedAction } from '../../recommended-actions/RecommendedAction';

const validate = () => { };
/* eslint no-unused-vars:0 */
/* eslint no-return-assign:0 */

export const PotentialCausesAdd = ({ failureModeID, potentialCause, onPotentialCauseClose }) => {
  const dispatch = useDispatch();
  // const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  const [showViewCausualParameters, setShowViewCausualParameters] = useState(false);

  const [addCausualParameters, setAddCausualParameters] = useState(false);
  // const [errorFields, setErrorFields] = useState([]);
  // const [model, setModel] = useState('');
  // const [section, setSection] = useState('');

  const recommendedAction = potentialCause?.recommendedactions || [];
  let localActions = [];

  const onSubmit = async (values) => {
    const newValue = { ...values, FailureMode_ID: failureModeID };
    newValue.recommendedactions = localActions.map((l) => ({ Description: l.Description }));
    // values.FailureMode_ID = failureModeID;
    // values.recommendedactions = localActions.map(l => ({ Description: l.Description }))
    // values = { ...values, FailureMode_ID: failureModeID };
    dispatch(potentialCausesAddAsync(newValue));
  };

  const onAddCausualParametersClose = () => {
    setAddCausualParameters(false);
    dispatch(setEditCausalParameter());
  };

  const onClick = () => {
    // window.alert(JSON.stringify(values, 0, 2));
    setShowViewCausualParameters(true);
  };

  useEffect(() => {
    dispatch(fetchOccurenceCriteriaAsync());
  }, []);

  const occurenceFrequency = useSelector(
    (state) => state.potentialCausesAdd.occurenceCriteria,
  );

  useEffect(() => {
    dispatch(fetchDetectionCriteriaAsync());
  }, []);

  const detectionViability = useSelector(
    (state) => state.potentialCausesAdd.detectionCriteria,
  );

  const required = (value) => (value ? undefined : 'Required');

  const onAddCausualParameters = () => {
    setAddCausualParameters(true);
    dispatch(setEditCausalParameter());
  };

  const spinning = useSelector(
    (state) => state.potentialCausesAdd.occurenceCriteria.occurenceCriteriaStatus
      === 'loading'
      || state.potentialCausesAdd.detectionCriteria.detectionCriteriaStatus
      === 'loading'
      || state.potentialCausesAdd.potentialCausesStatus === 'loading',
  );

  return (
    <>
      <Spin spinning={spinning}>
        <Form
          onSubmit={onSubmit}
          validate={validate}
          initialValues={potentialCause || {}}
          render={({ handleSubmit, form }) => (
            <form onSubmit={handleSubmit} className="fm failuremodulePage">

              <div className="form-group">
                <label className="font10">
                  Potential Cause
                  <span className="redStar">*</span>
                </label>
                <Field
                  name="Description"
                  className="form-control"
                  validate={required}
                // component="input"
                // placeholder="Enter"
                >
                  {({ input, meta }) => (
                    <div style={{ display: 'block' }}>
                      <input
                        className={`form-control customForm input-md ${meta.error && meta.touched && 'error-field'
                        }`}
                        {...input}
                        type="text"
                        placeholder="Potential Cause Description"
                      />
                    </div>
                  )}
                </Field>
              </div>
              <div className="form-group">
                <div className="input-control">
                  <label className="font10">
                    Occurrence Frequency
                  </label>
                  <div className="input-group">
                    <div>
                      <Field
                        name="OccurenceCriteria_ID"
                        className="form-control"
                        // validate={required}
                        placeholder="Occurrence Frequency"
                      >
                        {(props) => (

                          <Autocomplete
                            value={(occurenceFrequency && occurenceFrequency.find((m) => m.OccurenceCriteria_ID === props.input.value)) || ''}
                            getOptionSelected={(option, value) => option.OccurenceCriteria_ID === value.OccurenceCriteriaID}
                            onChange={(event, value) => {
                              props.input.onChange(value ? value.OccurenceCriteria_ID : null);
                            }}
                            onBlur={props.input.onBlur}
                            options={occurenceFrequency}
                            getOptionLabel={(option) => (option ? (`${option.Scale}-${option.Description}`) : '')}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder="Please Select Occurrence Frequency"
                                className={`${props.input.value === '' && props.meta.touched
                                  ? ''
                                  : ''
                                }`}
                                variant="outlined"
                              />
                            )}
                            disabled={false}
                            disablePortal
                          />
                        )}
                      </Field>
                    </div>
                  </div>
                </div>

              </div>
              <div className="form-group">
                <div className="input-control">
                  <label className="font10">
                    Ease of detection
                  </label>
                  <div className="input-group">
                    <div>
                      <Field
                        name="DetectionCriteria_ID"
                        className="form-control"
                        // validate={required}
                        placeholder="Ease of detection"
                      >
                        {(props) => (

                          <Autocomplete
                            value={(detectionViability && detectionViability.find((m) => m.DetectionCriteria_ID === props.input.value)) || ''}
                            getOptionSelected={(option, value) => option.DetectionCriteria_ID === value.DetectionCriteriaID}
                            onChange={(event, value) => {
                              props.input.onChange(value ? value.DetectionCriteria_ID : null);
                            }}
                            onBlur={props.input.onBlur}
                            options={detectionViability}
                            getOptionLabel={(option) => (option ? (`${option.Scale}-${option.Description}`) : '')}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder="Please Select Ease of detection"
                                className={`${props.input.value === '' && props.meta.touched
                                  ? ''
                                  : ''
                                }`}
                                variant="outlined"
                              />
                            )}
                            disabled={false}
                            disablePortal
                          />
                        )}
                      </Field>
                    </div>

                  </div>
                </div>

              </div>
              <div
                className="form-group pull-right add-casual-paramater-button"
                onClick={() => {
                  if (potentialCause && potentialCause.PotentialCause_ID) {
                    onAddCausualParameters();
                  }
                }}
              >
                <label
                  className={`link 
                    ${potentialCause && potentialCause.PotentialCause_ID
                    ? ''
                    : 'disabled'
                    }`}
                >
                  {/* <Icon name="addIcon" /> */}
                  <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8 3.68457V13.0179" stroke="#5D97F6" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M3.3335 8.35156H12.6668" stroke="#5D97F6" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                  {' '}
                  Add Causal Parameters
                </label>
              </div>

              <div className="clearfix" />

              {potentialCause
                && potentialCause.causualparameters
                && potentialCause.causualparameters.length > 0 && (
                  <div className="form-group">
                    <div className="dotted-box">
                      <label
                        onClick={() => {
                          onClick();
                        }}
                      >
                        {' '}
                        View Added Causal Parameters
                      </label>
                    </div>
                  </div>
              )}

              <RecommendedAction
                recommendedAction={recommendedAction}
                potentialCauseId={potentialCause?.PotentialCause_ID}
                onLocalActionsChanged={(l) => (localActions = l)}
              />
              <div className="pull-right">
                <Button
                  type="button"
                  style={{ marginRight: '8px' }}
                  // onClick={form.reset}
                  onClick={onPotentialCauseClose}
                >
                  Cancel
                </Button>
                <Button htmlType="submit" type="primary">
                  Save
                </Button>
              </div>
            </form>
          )}
        />
      </Spin>

      {addCausualParameters && (
        <Drawer
          title="Add Causal Parameter"
          placement="right"
          height="100%"
          closable
          visible={addCausualParameters}
          closeIcon={<div className="closepopup pointer"> &#x2715;</div>}
          className="failuremodulePage"
          onClose={() => {
            onAddCausualParametersClose();
          }}
          push={{ distance: 378 }}
        >
          <CausualParameterAdd
            potentialCauseID={potentialCause.PotentialCause_ID}
            onCausalParameterClose={() => onAddCausualParametersClose()}
          />
        </Drawer>
      )}

      {showViewCausualParameters && (
        <Drawer
          title="View Causal Parameters"
          placement="right"
          height="100%"
          closable
          visible={showViewCausualParameters}
          closeIcon={<div className="closepopup pointer"> &#x2715;</div>}
          className="failuremodulePage"
          onClose={() => {
            setShowViewCausualParameters(false);
          }}
          push={{ distance: 378 }}
        >
          <CausualParametersView potentialCause={potentialCause} />
        </Drawer>
      )}
    </>
  );
};
