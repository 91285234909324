// import axios from 'axios';
// import { user_id, APIUrl } from "../../configs/config";
import { userId, APIUrl } from '../../../../helper/constants';
// A mock function to mimic making an async request for data
// import authHeader from '../../../../config/auth.header';
import CallAPI from '../../../../services/conditionMonitoring.service';
// const headers = authHeader();

export function fetchOccurenceCriteria() {
  const apiInfo = {
    url: `${APIUrl}/api/getOccurenceCriteria`,
    method: 'GET',
    isMultiPart: false,
  };
  return CallAPI(apiInfo).then((res) => res.data);
  // return axios
  //   .get(`${APIUrl}/api/getOccurenceCriteria`, { headers })
  //   .then((res) => res.data);
}

export function fetchDetectionCriteria() {
  const apiInfo = {
    url: `${APIUrl}/api/getDetectionCriteria`,
    method: 'GET',
    isMultiPart: false,
  };
  return CallAPI(apiInfo).then((res) => res.data);
  // return axios
  //   .get(`${APIUrl}/api/getDetectionCriteria`, { headers })
  //   .then((res) => res.data);
}

export function savePotentialCauses(potentialCause) {
  potentialCause.CreatedUser_ID = userId;
  const apiInfo = {
    url: `${APIUrl}/api/savePotentialCause`,
    method: 'POST',
    postData: potentialCause,
    isMultiPart: false,
    CreatedUserID: true,
  };
  return CallAPI(apiInfo).then((res) => res.data);
  // return axios

  //   .post(`${APIUrl}/api/savePotentialCause`, potentialCause, { headers })

  //   .then((res) => res.data);
}
