import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import {
  getExecutionResultList, saveExecutionResult, fetchMachineModel, fetchMachineName,
} from '../../components/ConditionMonitoring/ExecutionResult/executionResultAPI';

const initialState = {
  executionResultList: [],
  executionResultListStatus: 'idle',
  savedExecutionResult: {},
  executionResultSaveStatus: 'idle',
  machineModels: [],
  machineModelStatus: 'idle',
  machineNames: [],
  machineNameStatus: 'idle',
};

export const fecthExecutionResultListAsync = createAsyncThunk(
  'executionResult/getExecutionResultList',
  async ({
    search, page, size, name, from, to, resolved,
  }) => {
    const result = await getExecutionResultList(search, page, size, name, from, to, resolved);
    return result;
  },
);

export const saveExecutionResultAsync = createAsyncThunk('executionResult/saveExecutionResult', async (data) => {
  const result = await saveExecutionResult(data);
  return result;
});

export const fetchMachineModelAsync = createAsyncThunk(
  'executionResult/fetchMachineModel',
  async () => {
    const response = await fetchMachineModel();
    return response;
  },
);

export const fetchMachineNameAsync = createAsyncThunk(
  'executionResult/fetchMachineName',
  async () => {
    const response = await fetchMachineName();
    return response;
  },
);

export const executionResultList = createSlice({
  name: 'executionResultList',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(fecthExecutionResultListAsync.pending, (state) => {
        state.executionResultListStatus = 'loading';
      })
      .addCase(fecthExecutionResultListAsync.fulfilled, (state, action) => {
        state.executionResultList = action.payload;
        state.executionResultListStatus = 'idle';
      })
      .addCase(saveExecutionResultAsync.fulfilled, (state, action) => {
        message.success('Record saved successfully');
        state.savedExecutionResult = action.payload ? action.payload : [];
      })
      .addCase(saveExecutionResultAsync.pending, (state) => {
        state.executionResultSaveStatus = 'loading';
      })
      // .addCase(saveExecutionResultAsync.rejected, (state, action) => {
      .addCase(saveExecutionResultAsync.rejected, (state) => {
        state.executionResultSaveStatus = 'idle';
        message.error('Unable save record');
      })
      .addCase(fetchMachineModelAsync.pending, (state) => {
        state.machineModelStatus = 'loading';
      })
      .addCase(fetchMachineModelAsync.fulfilled, (state, action) => {
        state.machineModelStatus = 'idle';
        state.machineModels = action.payload;
      })
      .addCase(fetchMachineNameAsync.pending, (state) => {
        state.machineNameStatus = 'loading';
      })
      .addCase(fetchMachineNameAsync.fulfilled, (state, action) => {
        state.machineNameStatus = 'idle';
        state.machineNames = action.payload;
      });
  },
});

export default executionResultList.reducer;
