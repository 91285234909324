import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import {
  fetchMachineModel, fetchMachineSection, fetchMachineFunction, fetchSeverityCriteria, saveFailureMode,
} from '../../components/ConditionMonitoring/FMEA/failure-mode/add/failureModeAddApi';

import { setEditDetails } from './failureModeDetailsSlice';
// import { Success_Message, Error_Message } from '../../../configs/config';
import { SuccessMessage, ErrorMessage } from '../../helper/constants';

const initialState = {
  machineModels: [],
  machineModelStatus: 'idle',
  machineSections: [],
  machineSectionStatus: 'idle',
  machineFunctions: [],
  machineFunctionStatus: 'idle',
  severityCriteria: [],
  severityCriteriaStatus: 'idle',
  failureModeStatus: 'idle',
};

export const saveFailureModeAsync = createAsyncThunk(
  'saveFailureMode/saveFailureModeDetails',
  async (body, thunkApi) => {
    const response = await saveFailureMode(body);

    thunkApi.dispatch(setEditDetails(response));
    return response;
  },
);

export const fetchMachineModelAsync = createAsyncThunk(
  'failureModeAdd/fetchMachineModel',
  async () => {
    const response = await fetchMachineModel();
    return response;
  },
);

export const fetchSeverityCriteriaAsync = createAsyncThunk(
  'failureModeAdd/fetchSeverityCriteria',
  async () => {
    const response = await fetchSeverityCriteria();
    return response;
  },
);

export const fetchMachineSectionAsync = createAsyncThunk(
  'failureModeAdd/fetchMachineSection',
  async (modelId) => {
    const response = await fetchMachineSection(modelId);
    return response;
  },
);

export const fetchMachineFunctionAsync = createAsyncThunk(
  'failureModeAdd/fetchMachineFunction',
  async (sectionId) => {
    const response = await fetchMachineFunction(sectionId);
    return response;
  },
);

export const failureModeEntry = createSlice({
  name: 'failureModeAdd',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchMachineModelAsync.pending, (state) => {
        state.machineModelStatus = 'loading';
      })
      .addCase(fetchMachineModelAsync.fulfilled, (state, action) => {
        state.machineModelStatus = 'idle';
        state.machineModels = action.payload;
      })

      .addCase(fetchSeverityCriteriaAsync.pending, (state) => {
        state.severityCriteriaStatus = 'loading';
      })
      .addCase(fetchSeverityCriteriaAsync.fulfilled, (state, action) => {
        state.severityCriteriaStatus = 'idle';
        state.severityCriteria = action.payload;
      })

      .addCase(fetchMachineSectionAsync.pending, (state) => {
        state.machineSectionStatus = 'loading';
        state.machineSections = [];
        state.machineFunctions = [];
      })
      .addCase(fetchMachineSectionAsync.fulfilled, (state, action) => {
        state.machineSectionStatus = 'idle';
        state.machineSections = action.payload;
      })

      .addCase(fetchMachineFunctionAsync.pending, (state) => {
        state.machineFunctionStatus = 'loading';
      })
      .addCase(fetchMachineFunctionAsync.fulfilled, (state, action) => {
        state.machineFunctionStatus = 'idle';
        state.machineFunctions = action.payload;
      })

      .addCase(saveFailureModeAsync.pending, (state) => {
        state.failureModeStatus = 'loading';
      })
      // .addCase(saveFailureModeAsync.fulfilled, (state, action) => {
      .addCase(saveFailureModeAsync.fulfilled, (state) => {
        state.failureModeStatus = 'idle';
        setTimeout(() => {
          message.success(SuccessMessage);
        }, 100);
      })
      .addCase(saveFailureModeAsync.rejected, (state) => {
        state.failureModeStatus = 'idle';
        setTimeout(() => {
          message.error(ErrorMessage);
        }, 100);
      });
  },
});

export default failureModeEntry.reducer;
