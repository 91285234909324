function Enery() {
    return (

<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9.70001 2V3" stroke="#5D97F6" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M14.8314 3.97351L14.1352 4.6914" stroke="#5D97F6" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M4.89996 3.97351L5.59612 4.6914" stroke="#5D97F6" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M17.7 9.18579L16.7 9.18579" stroke="#5D97F6" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M3.30005 9.18579L2.30005 9.18579" stroke="#5D97F6" stroke-linecap="round" stroke-linejoin="round"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M11.3277 14.7932C13.3935 14.1788 14.9 12.2653 14.9 10C14.9 7.23858 12.6614 5 9.89996 5C7.13854 5 4.89996 7.23858 4.89996 10C4.89996 12.3091 6.46527 14.2526 8.59267 14.8274V16.0311C9.4623 16.4226 10.4581 16.4226 11.3277 16.0311V14.7932Z" fill="#5D97F6"/>
<path d="M11.3277 14.7932L11.1852 14.3139C10.9731 14.377 10.8277 14.5719 10.8277 14.7932H11.3277ZM8.59267 14.8274H9.09267C9.09267 14.6014 8.94117 14.4036 8.72307 14.3447L8.59267 14.8274ZM8.59267 16.0311H8.09267C8.09267 16.2278 8.20803 16.4062 8.38741 16.487L8.59267 16.0311ZM11.3277 16.0311L11.533 16.487C11.7124 16.4062 11.8277 16.2278 11.8277 16.0311H11.3277ZM14.4 10C14.4 12.038 13.0447 13.7608 11.1852 14.3139L11.4703 15.2724C13.7423 14.5967 15.4 12.4926 15.4 10H14.4ZM9.89996 5.5C12.3852 5.5 14.4 7.51472 14.4 10H15.4C15.4 6.96243 12.9375 4.5 9.89996 4.5V5.5ZM5.39996 10C5.39996 7.51472 7.41468 5.5 9.89996 5.5V4.5C6.8624 4.5 4.39996 6.96243 4.39996 10H5.39996ZM8.72307 14.3447C6.80822 13.8274 5.39996 12.0775 5.39996 10H4.39996C4.39996 12.5407 6.12231 14.6779 8.46227 15.3101L8.72307 14.3447ZM9.09267 16.0311V14.8274H8.09267V16.0311H9.09267ZM11.1225 15.5752C10.3834 15.9079 9.53704 15.9079 8.79793 15.5751L8.38741 16.487C9.38756 16.9373 10.5328 16.9373 11.533 16.487L11.1225 15.5752ZM10.8277 14.7932V16.0311H11.8277V14.7932H10.8277Z" fill="#5D97F6"/>
<path d="M6.39996 10C6.39996 10 7.67269 7.5 9.89996 7.5C12.1272 7.5 13.4 10 13.4 10C13.4 10 12.1272 12.5 9.89996 12.5C7.67269 12.5 6.39996 10 6.39996 10Z" fill="#2A2C2D" stroke="#2A2C2D" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M9.89996 11C10.4522 11 10.9 10.5523 10.9 10C10.9 9.44772 10.4522 9 9.89996 9C9.34768 9 8.89996 9.44772 8.89996 10C8.89996 10.5523 9.34768 11 9.89996 11Z" fill="#5D97F6" stroke="#5D97F6" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M8.5 17.5469L8.90337 17.6687C9.62005 17.8852 10.3831 17.8962 11.1057 17.7005L11.5 17.5938" stroke="#5D97F6" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

    );
  }
  export default Enery;
  