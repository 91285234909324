import React from 'react';
import ActiveIcon from './ActiveIcons';
import InActiveIcon from './InActiveIcons';
/* eslint  react/prop-types:0 */
function Icon({ name }) {
  return (
    <>
      <div className="hideOnHover">
        <InActiveIcon icon={name} />
      </div>
      <div className="showOnHover">
        <ActiveIcon icon={name} />
      </div>
    </>
  );
}

export default Icon;
