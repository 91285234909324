import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
// import DataTable from "./DataTable";
import LiHtml from '../../../common/horizontalMenu/horizontalMenu';
// import { DashboardContext } from "../../../../context/dashboardContext";
import { handleURLRouteParameter } from '../../../services/handleRoute';
import GCDataTable from '../general-condition/GCDataTable';
import FNDataTable from '../failure-notification/FNDataTable';
import MCDataTable from '../maintenance-condition/MCDataTable';
import SchedulingModal from '../settings/SchedulingModal';
// import settings from '../../../images/settings.svg';
// import { ReactComponent as Settings } from '../../../images/settings.svg';
/* eslint no-undef:0 */
const Tabs = () => {
  // const [dashboardState, SetDashboardState] = useContext(DashboardContext);
  const history = useHistory();
  const { tabType } = useParams();
  console.log('tabType', tabType);
  const selectedTab = tabType || 'General-Conditions';
  const [dashboardState, SetDashboardState] = useState({
    selectedTab,
  });
  const [settingsOpen, setSettingsOpen] = useState(false);

  const tabFuntions = (tabvalue) => {
    history.replace(handleURLRouteParameter('ConditionMasters', tabvalue));
    SetDashboardState({ ...dashboardState, selectedTab: tabvalue });
  };

  useEffect(() => {
    tabFuntions(tabType || 'General-Conditions');
  }, []);

  return (
    <>
      <div className="tabArea">
        <ul className="nav nav-tabs tabsName">
          <LiHtml
            tabValue="General-Conditions"
            selectedTab={dashboardState.selectedTab}
            onClickFun={() => tabFuntions('General-Conditions')}
            tabName="General Condition"
          />
          <LiHtml
            tabValue="Failure-Conditions"
            selectedTab={dashboardState.selectedTab}
            onClickFun={() => tabFuntions('Failure-Conditions')}
            tabName="Failure Condition"
          />
          <LiHtml
            tabValue="Maintenance-Masters"
            selectedTab={dashboardState.selectedTab}
            onClickFun={() => tabFuntions('Maintenance-Masters')}
            tabName="Maintenance Master"
          />
        </ul>
        {/* <button type="button" className="pull-right tab-settingbtn" onClick={() => setSettingsOpen(true)}>
          <Settings />
        </button> */}
        <div className="tab-content">
          {dashboardState.selectedTab === 'General-Conditions' && (
          <div
            id="GeneralCondition"
            className={`tab-pane fade ${dashboardState.selectedTab === 'General-Conditions'
              ? 'in active'
              : ''
            }`}
          >
            <GCDataTable />
          </div>
          )}
          {dashboardState.selectedTab === 'Failure-Conditions' && (
          <div
            id="FailureCondition"
            className={`tab-pane fade ${dashboardState.selectedTab === 'Failure-Conditions'
              ? 'in active'
              : ''
            }`}
          >
            <FNDataTable />
          </div>
          )}
          {dashboardState.selectedTab === 'Maintenance-Masters' && (
          <div
            id="MaintenanceCondition"
            className={`tab-pane fade ${dashboardState.selectedTab === 'Maintenance-Masters'
              ? 'in active'
              : ''
            }`}
          >
            <MCDataTable />
          </div>
          )}
        </div>
        {settingsOpen
        && <SchedulingModal showPopup={settingsOpen} setShowPopup={setSettingsOpen} />}
      </div>
    </>
  );
};
export default Tabs;
