import React from 'react';
import '../../Condition-Monitoring/condition-monitoring.css';
// import { useHistory } from 'react-router-dom';
import Tabs from './Components/tab';
// import { DashboardContext } from '../../../context/dashboardContext';
// const [dashboardState, SetDashboardState] = useContext(DashboardContext);
// const history = useHistory();
const FmeaRepository = () => (
  <>
    <div className="wrapper cm-wrapper">
      <div className="contentWrap conditionMonitoring monitoringcontentWrap monitoringPage failuremodulePage">
        <Tabs />
      </div>
    </div>
  </>
);
export default FmeaRepository;
