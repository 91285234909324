// import axios from 'axios';
// import { user_id, API_Url } from "../../configs/config";
import { userId, APIUrl } from '../../../../helper/constants';
// A mock function to mimic making an async request for data
// import authHeader from '../../../../config/auth.header';
import CallAPI from '../../../../services/conditionMonitoring.service';
// const headers = authHeader();

export function fetchMachineParameters(modelID, sectionID, functionID) {
  const apiInfo = {
    url: `${APIUrl}/api/getMachineParameters?machinemodelID=${modelID}&machinesectionID=${sectionID}&machinefunctionID=${functionID}`,
    method: 'GET',
    isMultiPart: false,
  };
  return CallAPI(apiInfo).then((res) => res.data);
  // return axios
  //   .get(
  //     `${APIUrl}/api/getMachineParameters?machinemodelID=${modelID}&machinesectionID=${sectionID}&machinefunctionID=${functionID}`, { headers },
  //   )
  //   .then((res) => res.data);
}

export function saveCausualParameter(causualParameter) {
  causualParameter.CreatedUser_ID = userId;
  causualParameter.Indicator = '';
  const apiInfo = {
    url: `${APIUrl}/api/saveCausualParameter`,
    method: 'POST',
    postData: causualParameter,
    CreatedUserID: true,
    isMultiPart: false,
  };
  return CallAPI(apiInfo).then((res) => res.data);
  // causualParameter.PotentialCause_ID = 1;
  // return axios
  //   .post(`${APIUrl}/api/saveCausualParameter`, causualParameter, { headers })
  //   .then((res) => res.data);
}
