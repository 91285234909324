/* eslint-disable import/prefer-default-export */
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect } from 'react';
import 'antd/dist/antd.css';
import 'antd/dist/antd.dark.css';
import {
  fetchMachineParametersAsync,
  saveFailureIndicatorAsync,
} from '../../../../redux/slices/failureIndicatorsAddSlice';
import { FormulaField } from '../../../../common/FormulaField';
// import { defaultStyle } from '../../mentions';
import '../../../../styles/failureMode.css';

export const FailureIndicatorAddComponent = ({
  modelID,
  sectionID,
  functionID,
  failureModeID,
  item,
  onAddFailureIndicatorClose,
}) => {
  const dispatch = useDispatch();
  const machineParameter = useSelector((state) => state.failureIndicatorsAdd.machineParameters);
  const spinning = useSelector(
    (state) => state.failureIndicatorsAdd.machineParametersStatus === 'loading'
      || state.failureIndicatorsAdd.failureIndicatorsStatus === 'loading',
  );
  const onSubmit = (values, formula, validFormula) => {
  //   if (formula && validFormula){
  //   values.FailureMode_ID = failureModeID;
  //   values.IndirectFormula = formula;
  //   dispatch(saveFailureIndicatorAsync(values)).then(() => {
  //     onAddFailureIndicatorClose();
  //   });
  // }
    let parameterDSeriesFormula = formula;
    if (validFormula && (formula !== undefined && formula !== '')) {
      const parameters = formula.match(/@{[^@{}]*}/gm);
      if (parameters.length > 0) {
        for (const p of parameters) {
          if (p) {
            const dbParam = machineParameter.find((mp) => mp.ParameterName === p.substring(2, p.length - 1));
            parameterDSeriesFormula = parameterDSeriesFormula.replace(p, dbParam.ParameterDSeriesName);
          }
        }
      }

      dispatch(saveFailureIndicatorAsync({
        ...values, FailureMode_ID: failureModeID, IndirectFormula: formula, FailureIndicatorDSeries: parameterDSeriesFormula,
      }))
        .then(() => {
          onAddFailureIndicatorClose();
        });
    }
  };

  useEffect(() => {
    dispatch(fetchMachineParametersAsync({ modelID, sectionID, functionID }));
  }, []);

  return (
    <FormulaField
      spinning={spinning}
      onClose={onAddFailureIndicatorClose}
      machineParameter={machineParameter}
      item={item}
      onSubmit={onSubmit}
    />

  );
};
