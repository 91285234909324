// import axios from 'axios';
// import { userId, APIUrl } from "../../../configs/config";
import { userId, APIUrl } from '../../../helper/constants';
// import authHeader from '../../../config/auth.header';
import CallAPI from '../../../services/conditionMonitoring.service';

// A mock function to mimic making an async request for data
export function fetchMachineModel() {
  const apiInfo = {
    url: `${APIUrl}/api/getMachineModel`,
    method: 'GET',
    isMultiPart: false,
  };
  return CallAPI(apiInfo).then((res) => res.data);
  // return axios
  //   .get(
  //     `${APIUrl}/api/getMachineModel`, { headers },
  //   )
  //   .then((res) => res.data);
}

export function fetchMachineSection(modelId) {
  const apiInfo = {
    url: `${APIUrl}/api/getMachineSection?machinemodelID=${modelId}`,
    method: 'GET',
    isMultiPart: false,
  };
  return CallAPI(apiInfo).then((res) => res.data);

  // return axios
  //   .get(
  //     `${APIUrl}/api/getMachineSection?machinemodelID=${modelId}`, { headers },
  //   )
  //   .then((res) => res.data);
}

export function fetchMachineFunction(sectionId) {
  const apiInfo = {
    url: `${APIUrl}/api/getMachineFunction?machinesectionID=${sectionId}`,
    method: 'GET',
    isMultiPart: false,
  };
  return CallAPI(apiInfo).then((res) => res.data);
  // return axios
  //   .get(
  //     `${APIUrl}/api/getMachineFunction?machinesectionID=${sectionId}`, { headers },
  //   )
  //   .then((res) => res.data);
}

export function fetchNotificationTemplate() {
  // const headers = authHeader();
  const apiInfo = {
    url: `${APIUrl}/api/getNotificationTemplate`,
    method: 'GET',
    isMultiPart: false,
  };
  return CallAPI(apiInfo).then((res) => res.data);
  // return axios
  //   .get(

  //     `${APIUrl}/api/getNotificationTemplate`, { headers },
  //   )
  //   .then((res) => res.data);
}

export function fetchNotificationGroup() {
  const apiInfo = {
    url: `${APIUrl}/api/getNotificationGroup`,
    method: 'GET',
    isMultiPart: false,
  };
  return CallAPI(apiInfo).then((res) => res.data);
  // return axios
  //   .get(

  //     `${APIUrl}/api/getNotificationGroup`, { headers },
  //   )
  //   .then((res) => res.data);
}

export function fetchMachineParameters(modelID, sectionID, functionID) {
  const apiInfo = {
    url: `${APIUrl}/api/getMachineParameters?machinemodelID=${modelID}&machinesectionID=${sectionID}&machinefunctionID=${functionID}`,
    method: 'GET',
    isMultiPart: false,
  };
  return CallAPI(apiInfo).then((res) => res.data);
  // return axios
  //   .get(
  //     `${APIUrl}/api/getMachineParameters?machinemodelID=${modelID}&machinesectionID=${sectionID}&machinefunctionID=${functionID}`, { headers },
  //   )
  //   .then((res) => res.data);
}

export function editGeneralCondition(id) {
  // const headers = authHeader();
  const apiInfo = {
    url: `${APIUrl}/api/editGeneralConditionDetails?search=${id}`,
    method: 'GET',
    isMultiPart: false,
  };
  return CallAPI(apiInfo).then((res) => res.data);

  // return axios
  //   .get(

  //     `${APIUrl}/api/editGeneralConditionDetails?search=${id}`, { headers },
  //   )
  //   .then((res) => res.data);
}

export async function saveGeneralCondition(generalCondition) {
  // const headers = authHeader();
  generalCondition.CreatedUser_ID = userId;
  // generalCondition.GeneralConditionDSeriesFormula = 1;

  if (generalCondition.MachineSection_ID === '') {
    delete generalCondition.MachineSection_ID;
  }
  if (generalCondition.MachineFunction_ID === '') {
    delete generalCondition.MachineFunction_ID;
  }

  const apiInfo = {
    url: `${APIUrl}/api/saveGeneralCondition`,
    method: 'POST',
    postData: generalCondition,
    isMultiPart: false,
    CreatedUserID: true,
  };
  return CallAPI(apiInfo).then((res) => res.data);

  // return axios.post(`${APIUrl}/api/saveGeneralCondition`, generalCondition, { headers })
  //   .then((res) => res.data);
}
