import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  fetchMachineModel, fetchMachineModelName, fetchCustomerName, fetchExecutionResult, fetchConditionWise,
  fetchModelWise, fetchMachineWise, fetchFrequencyWise,
} from '../../components/Report/condition-monitoring/conditionMonitoringApi';

const initialState = {
  machineModels: [],
  machineModelStatus: 'idle',
  machineModelNames: [],
  machineModelNameStatus: 'idle',
  machineCustomers: [],
  machineCustomerStatus: 'idle',
  executionResults: [],
  executionResultStatus: 'idle',
  conditionWiseResults: [],
  conditionWiseResultStatus: 'idle',
  ModelWiseResults: [],
  ModelWiseResultStatus: 'idle',
  MachineWiseResults: [],
  MachineWiseResultStatus: 'idle',
  FrequencyWiseResults: [],
  FrequencyWiseResultStatus: 'idle',
};

export const fetchMachineModelAsync = createAsyncThunk(
  'conditionMonitoringReport/fetchMachineModel',
  async () => {
    const response = await fetchMachineModel();
    return response;
  },
);

export const fetchMachineModelNameAsync = createAsyncThunk(
  'conditionMonitoringReport/fetchMachineModelName',
  async (modelName) => {
    const response = await fetchMachineModelName(modelName);
    return response;
  },
);

export const fetchCustomerNameAsync = createAsyncThunk(
  'conditionMonitoringReport/fetchCustomerName',
  async () => {
    const response = await fetchCustomerName();
    return response;
  },
);

export const fetchExecutionResultAsync = createAsyncThunk(
  'conditionMonitoringReport/fetchExecutionResult',
  async ({
    page, size, model, machine, customer, type, from, to,
  }) => {
    const response = await fetchExecutionResult(
      page, size, model, machine, customer, type, from, to,
    );
    return response;
  },
);

export const fetchConditionWiseAsync = createAsyncThunk(
  'conditionMonitoringReport/fetchConditionWise',
  async ({
    model, machine, customer, type, from, to,
  }) => {
    const response = await fetchConditionWise(
      model, machine, customer, type, from, to,
    );
    return response;
  },
);

export const fetchModelWiseAsync = createAsyncThunk(
  'conditionMonitoringReport/fetchModelWise',
  async ({
    model, machine, customer, from, to,
  }) => {
    const response = await fetchModelWise(
      model, machine, customer, from, to,
    );
    return response;
  },
);

export const fetchMachineWiseAsync = createAsyncThunk(
  'conditionMonitoringReport/fetchMachineWise',
  async ({
    model, machine, customer, from, to,
  }) => {
    const response = await fetchMachineWise(
      model, machine, customer, from, to,
    );
    return response;
  },
);

export const fetchFrequencyWiseAsync = createAsyncThunk(
  'conditionMonitoringReport/fetchFrequencyWise',
  async ({
    model, machine, customer, type, from, to,
  }) => {
    const response = await fetchFrequencyWise(
      model, machine, customer, type, from, to,
    );
    return response;
  },
);

export const conditionMonitoringReports = createSlice({
  name: 'conditionMonitoringReport',
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(fetchMachineModelAsync.pending, (state) => {
        state.machineModelStatus = 'loading';
      })
      .addCase(fetchMachineModelAsync.fulfilled, (state, action) => {
        state.machineModelStatus = 'idle';
        state.machineModels = action.payload;
      })

      .addCase(fetchMachineModelNameAsync.pending, (state) => {
        state.machineModelNameStatus = 'loading';
      })
      .addCase(fetchMachineModelNameAsync.fulfilled, (state, action) => {
        state.machineModelNameStatus = 'idle';
        state.machineModelNames = action.payload;
      })

      .addCase(fetchCustomerNameAsync.pending, (state) => {
        state.machineCustomerStatus = 'loading';
      })
      .addCase(fetchCustomerNameAsync.fulfilled, (state, action) => {
        state.machineCustomerStatus = 'idle';
        state.machineCustomers = action.payload;
      })

      .addCase(fetchExecutionResultAsync.pending, (state) => {
        state.executionResultStatus = 'loading';
      })
      .addCase(fetchExecutionResultAsync.fulfilled, (state, action) => {
        state.executionResultStatus = 'idle';
        state.executionResults = action.payload;
      })

      .addCase(fetchConditionWiseAsync.pending, (state) => {
        state.conditionWiseResultStatus = 'loading';
      })
      .addCase(fetchConditionWiseAsync.fulfilled, (state, action) => {
        state.conditionWiseResultStatus = 'idle';
        state.conditionWiseResults = action.payload;
      })

      .addCase(fetchModelWiseAsync.pending, (state) => {
        state.ModelWiseResultStatus = 'loading';
      })
      .addCase(fetchModelWiseAsync.fulfilled, (state, action) => {
        state.ModelWiseResultStatus = 'idle';
        state.ModelWiseResults = action.payload;
      })

      .addCase(fetchMachineWiseAsync.pending, (state) => {
        state.MachineWiseResultStatus = 'loading';
      })
      .addCase(fetchMachineWiseAsync.fulfilled, (state, action) => {
        state.MachineWiseResultStatus = 'idle';
        state.MachineWiseResults = action.payload;
      })

      .addCase(fetchFrequencyWiseAsync.pending, (state) => {
        state.FrequencyWiseResultStatus = 'loading';
      })
      .addCase(fetchFrequencyWiseAsync.fulfilled, (state, action) => {
        state.FrequencyWiseResultStatus = 'idle';
        state.FrequencyWiseResults = action.payload;
      });
  },
});

export default conditionMonitoringReports.reducer;
