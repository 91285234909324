import React, { useState, useEffect } from 'react';
import { Form } from 'react-final-form';
import { useDispatch } from 'react-redux';
// import { OnChange } from 'react-final-form-listeners';
// import { Modal, Tooltip } from 'antd';
import {
  saveRecommendedActionsAsync,
  deleteRecommendedActionsAsync,
} from '../../../redux/slices/maintenanceAddSlice';
// import add from '../../../../images/plus.svg';
// import { ReactComponent as Edit } from '../../../../../src/components/dashboard/reports/ECR/svgs/editSpoc.svg';
// import { ReactComponent as Delete } from '../../../../../src/components/dashboard/reports/ECR/svgs/delete.svg';
import Icon from '../../Dashboard/Sidebar/images/Icon';

const validate = () => { };

const MCRecommendedAction = ({
  recommendedAction, maintenanceId, onLocalActionsChanged, viewOnly,
}) => {
  const dispatch = useDispatch();
  const [description, setDescription] = useState('');
  const [editItem, setEditItem] = useState();
  const [visible, setVisible] = useState(false);
  const [deleteId, setDeleteId] = useState();
  // const [confirmLoading, setConfirmLoading] = useState(false);
  // const [modalText, setModalText] = useState('Are you sure to delete this?');
  const modalText = 'Are you sure to delete this?';
  const [localActions, setLocalActions] = useState(recommendedAction || []);

  useEffect(() => {
    setLocalActions(recommendedAction || []);
  }, [recommendedAction]);

  useEffect(() => {
    onLocalActionsChanged(localActions);
  }, [localActions]);

  useEffect(() => {
    setDescription(editItem && editItem.Description);
  }, [editItem]);

  const onAddRecommendedAction = () => {
    if (description) {
      if (maintenanceId) {
        dispatch(
          saveRecommendedActionsAsync({
            Description: description,
            MaintenanceCondition_ID: maintenanceId,
            RecommendedAction_ID: editItem && editItem.RecommendedAction_ID,
          }),
        ).then((res) => {
          console.log(res);
          setEditItem();
          setEditItem({ Description: '' });
        });
      } else {
        let temp = [...localActions];
        if (editItem && editItem.RecommendedAction_ID) {
          const edited = temp.find((item) => item.RecommendedAction_ID === editItem.RecommendedAction_ID);
          if (edited && edited.RecommendedAction_ID) {
            edited.Description = description;
          }
        } else {
          temp = [...temp, { RecommendedAction_ID: temp.length + 1, Description: description }];
        }
        setLocalActions(temp);
        setEditItem();
        setEditItem({ Description: '' });
        setDescription('');
      }
    }
  };

  const handleOk = () => {
    if (maintenanceId) {
      if (editItem && (deleteId === editItem.RecommendedAction_ID)) {
        setEditItem();
        setEditItem({ Description: '' });
      }
      dispatch(deleteRecommendedActionsAsync(deleteId));
    } else {
      if (deleteId === editItem.RecommendedAction_ID) {
        setEditItem();
        setEditItem({ Description: '' });
      }
      setLocalActions(localActions.filter((action) => action.RecommendedAction_ID !== deleteId));
    }
    setDeleteId(undefined);
    // setConfirmLoading(true);
    setTimeout(() => {
      setVisible(false);
      // setConfirmLoading(false);
    }, 2000);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const onEditActionClick = (r) => {
    setEditItem(r);
  };

  const onDeleteActionClick = (id) => {
    setDeleteId(id);
    setVisible(true);
  };
  return (
    <>
      <Form
        onSubmit={() => { }}
        validate={validate}
        initialValues={editItem || {}}
        // render={({ handleSubmit, form }) => (
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <div className="form-group recommended">
              <label className="font12">Recommended Action</label>
              <div className="card">
                <textarea
                  name="Description"
                  className="form-control customForm recommended"
                  component="input"
                  placeholder="Recommended Action"
                  maxLength={2000}
                  onChange={(e) => { setDescription(e.target.value); }}
                  value={description}
                  disabled={viewOnly}
                />

                <button type="button" className="btn btn-secondary" onClick={onAddRecommendedAction}>
                  {/* <img src={add} alt="" title="" /> */}
                  <Icon name="Add" />
                </button>

                <div className="clearfix" />

                <div className="recommended-action-list">
                  {localActions
                    && localActions.map((r) => (
                      <div className="row">
                        <div className="col-md-12">
                          <div className="card">
                            <label value={r.RecommendedAction_ID}>
                              {r.Description}
                            </label>
                            <div className="pull-right edit">
                              <label
                                className="col-md-1"
                                onClick={() => {
                                  if (!viewOnly) {
                                    onEditActionClick(r);
                                  }
                                }}
                              >
                                <Icon name="EditIcon" />
                              </label>
                              <label
                                className="col-md-1"
                                onClick={() => {
                                  if (!viewOnly) {
                                    onDeleteActionClick(r.RecommendedAction_ID);
                                  }
                                }}
                              >
                                <Icon name="DeleteIcon" />
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </form>
        )}
      />

      <div
        className={`modelboxopen2 delete confirmPin ${visible ? '' : 'hidden'
        }`}
      >
        <div className="modelboxopenInnner" id="modelboxopenInnner6">
          <div className="wrapContent">
            <div className="containerIner2">
              <div className="popupCloseButton bbt">
                <h3 className="titleDiv">Confirm</h3>
                <a
                  className="closepopup"
                  onClick={handleCancel}
                >
                  &#x2715;
                </a>
              </div>

              <div className="contentPopup">
                <div className="innerContentPopup">
                  <div className="descriptionCreate">
                    <div>
                      <div className="col-md-12 mb-8">
                        <div>
                          <div className="enterpins placeColor">
                            <div className="font14">{modalText}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="footerPopupWithBtn">
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <button
                        id="cancelBtn"
                        name="cancel"
                        className="btn btn-default cancelBtn"
                        onClick={handleCancel}
                        type="button"
                      >
                        Cancel
                      </button>
                      <button
                        id="saveBtn"
                        name="Save"
                        className="btn btn-default saveBtn"
                        type="button"
                        onClick={handleOk}
                      >
                        OK
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  );
};

export default MCRecommendedAction;
