import { useState,memo, useCallback, useEffect, useRef } from "react";
import { useHistory } from "react-router";
import {Link} from "react-router-dom";
import HomeIcon from "./images/HomeIcon";
import Enery from "./images/eg1";
import Slitter  from "./images/slitter";
import Logo from "./images/Logo";
import SettingIcon from "./images/SettingIcon";
import Styles from "./style.module.css";
// import Icon from "./images/Icon";
// import { getSubMenuRoute } from '../../../helper/constants';
import AppRoutes from '../../../config/api.config.json';
import ActiveIcon from "./images/ActiveIcons";

function Sidebar() {
  const [isOpen, setIsOpen] = useState(false);
  const history = useHistory();
  const location = history.location.pathname.split("/");
  console.log('location', location);
  let pagename = "";
  if(location.length === 4){
    pagename = location[location.length-3]
  } else if(location.length === 3){
    pagename = location[location.length-2]
  } else {
    pagename = location[location.length-1]
  }
  const siderbar = useRef()
  console.log('pageNAme', pagename);
  const {
    FTR: FTR_ROUTE, FTR_UPLOAD, IOMATRIX, REPORTS: REPORTS_ROUTE, CHANGE_PASSWORD, CmDashboard,
    NotificationGroup, NotificationTemplate, CmGeneralCondition, ExecutionResult, AlertNotificationLog,
    ENERGY_METER, FailureModeListing, ADMIN_PANEL, DIGITALMAINTENANCE,
    DIGITALMAINTENANCESETTINGS,
  } = AppRoutes;

  const toggleMenu = (e)=>{
    setIsOpen(!isOpen)
  }
  
  const handelDocumentClick = (e)=> {
    if(siderbar.current.contains(e.target) === false){
      setIsOpen(false)
    }
  }

  useEffect(()=>{
    document.addEventListener("click",handelDocumentClick)
    return ()=>{
      document.removeEventListener("click",handelDocumentClick)
    }
  },[])

  return (
    <div className={`${Styles.sidebar} ${isOpen ? `${Styles.openMenu}` : ""}`} ref={siderbar}>
      <ul className={`${Styles.sideNav}`}>
        <li className={`${Styles.navItem} ${Styles.menuIcon}`}>
          <div className={`${Styles.navAnchor}`}>
            <div className={`${Styles.icon}`} onClick={toggleMenu}>
              <div className={`${Styles.customeMenu}`}>
                <div className={`${Styles.menuBtn}`}>
                  <span className={`${Styles.topLine}`}></span>
                  <span className={`${Styles.middleLine}`}></span>
                  <span className={`${Styles.bottomLine}`}></span>
                </div>
              </div>
            </div>
            <div className={`${Styles.logo}`}>
              <Link to="/home">
                <Logo />
              </Link>
            </div>
          </div>
        </li>
        {(localStorage.getItem('SolarPlant') == 'true')? <>
        <li className={`${Styles.navItem} ${pagename === "home" ? `${Styles.active} active` : ""} toggleIconOnHover`}>
          <Link className={`${Styles.navAnchor}`} to="/home">
            <div className={`${Styles.icon}`}>
              <HomeIcon />
            </div>
            <div className={`${Styles.text}`}>Home</div>
          </Link>
        </li>
        </>:'' }
        {(localStorage.getItem('SlitterOEE') == 'true')? <>
        <li className={`${Styles.navItem} ${pagename === "slitter" ? `${Styles.active} active` : ""} toggleIconOnHover`}>
          <Link className={`${Styles.navAnchor}`} to="/slitter">
            <div className={`${Styles.icon}`}>
              <Slitter />
            </div>
            <div className={`${Styles.text}`}>Slitter OEE</div>
          </Link>
        </li>
        </>:'' }
        
        {(localStorage.getItem('EnergyManagement') == 'true')? <>
        <li className={`${Styles.navItem} ${pagename === "en1m" ? `${Styles.active} active` : ""} toggleIconOnHover`}>
          <Link className={`${Styles.navAnchor}`} to="/en1m">
            <div className={`${Styles.icon}`}>
              <Enery />
            </div>
            <div className={`${Styles.text}`}>Energy Monitoring</div>
          </Link>
        </li>


        </>:'' }
        <li
              className={`${Styles.navItem} ${['Dashboard', 'NotificationGroup', 'NotificationTemplate', 'ConditionMasters', 'ExecutionResult',
                'AlertNotificationLog', 'Details',
              ].includes(pagename)
                ? `${Styles.active} active`
                : ''
              } toggleIconOnHover`}
            >
              <Link className={`${Styles.navAnchor}`} to="#">
                <div className={`${Styles.icon}`}>
                  {/* <Icon name="ConditionMonitoring" /> */}
                  <ActiveIcon icon="ConditionMonitoring" />
                </div>
                <div className={`${Styles.text}`}>Condition Monitoring</div>
              </Link>
              <ul className={`${Styles.subMenu}`}>
                {/* {getSubmenu()} */}
                {/* <li
                  className={`${pagename === item.MenuName.replaceAll(' ', '') ? `${Styles.active} active` : ''
                  }`}
                >
                  <Link to={getSubMenuRoute(item.MenuCode)}>{item.MenuName}</Link>
                </li> */}
                <li
                  className={`${pagename.toLowerCase().includes('notificationgroup') ? `${Styles.active} active` : ''
                  }`}
                >
                  <Link to={NotificationGroup}>Notification Group</Link>
                </li>
                <li
                  className={`${pagename.toLowerCase().includes('notificationtemplate') ? `${Styles.active} active` : ''
                  }`}
                >
                  <Link to={NotificationTemplate}>Notification Template</Link>
                </li>
                <li
                  className={`${location.toString().toLowerCase().includes('conditionmaster') ? `${Styles.active} active` : ''
                  }`}
                >
                  <Link to={CmGeneralCondition}>Condition Master</Link>
                </li>
                <li
                  className={`${pagename.toLowerCase().includes('executionresult') ? `${Styles.active} active` : ''
                  }`}
                >
                  <Link to={ExecutionResult}>Execution Result</Link>
                </li>
                <li
                  className={`${pagename.toLowerCase().includes('alertnotificationlog') ? `${Styles.active} active` : ''
                  }`}
                >
                  <Link to={AlertNotificationLog}>Alert/Notification Log</Link>
                </li>
              </ul>
            </li>
            <li
              className={`${Styles.navItem} ${['FMEA'].includes(pagename) ? `${Styles.active} active` : 'non-active'} 
            toggleIconOnHover`}
              id="FailureModeListing"
            >
              <Link className={`${Styles.navAnchor}`} to={FailureModeListing}>
                <div className={`${Styles.icon}`}>
                  {/* <Icon name="FMEAIcon" /> */}
                  <ActiveIcon icon="FMEAIcon" />
                </div>
                <div className={`${Styles.text}`}>FMEA Repository</div>
              </Link>
            </li>    
      </ul>

      <ul className={`${Styles.sideNav}`}>
        <li className={`${Styles.navItem} ${pagename === "settings" || pagename === "change-password" ? `${Styles.active} active` : ""} toggleIconOnHover`}>
          <Link className={`${Styles.navAnchor}`} to="#">
            <div className={`${Styles.icon}`}>
              <SettingIcon />
            </div>
            <div className={`${Styles.text}`}>Settings</div>
          </Link>
          <ul className={`${Styles.subMenu}`}>
              <li className={`${pagename === "change-password" ? `${Styles.active} active` : ""}`}>
                  <Link to="/settings/change-password">Change Password</Link>
              </li>
          </ul>
        </li>
      </ul>
    </div>
  );
}
export default Sidebar;
