// IMPORTING ROUTES
import AppRoutes from '../config/api.config.json';

export const UpdateNotificationGroupRoute = 'NotificationGroup/Details';
export const NotificationGroupRoute = 'NotificationGroup';
export const NotificationTemplateRoute = 'notification-template';

export const Idle = 'idle';
export const Loading = 'loading';
/* eslint max-len:0 */
export const emailValidateRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const mobileNoValRegex = /^(\+\d{1,4}[- ]?)?\d{10}$/;
export const nameValRegex = /^[a-zA-Z\d- ]+$/;

export const SuccessMessage = 'Record Saved Successfully';
export const ErrorMessage = 'Error in Saving Record';
export const DuplicateRecord = 'Duplicate Record';
export const APIUrl = "";  //'http://localhost:8086';

export const userId = localStorage.getItem('UserKey') !== undefined
&& localStorage.getItem('UserKey') !== 0
  ? localStorage.getItem('UserKey')
  : 1;

const {
  CmDashboard, NotificationGroup, NotificationTemplate, CmGeneralCondition, ExecutionResult, AlertNotificationLog,
} = AppRoutes;

export const getSubMenuRoute = (submenu) => {
  const obj = {
    CONDDASH: CmDashboard,
    CONDNOTGRP: NotificationGroup,
    CONDNOTTEM: NotificationTemplate,
    CONDMASTER: CmGeneralCondition,
    CONDER: ExecutionResult,
    CONDAN: AlertNotificationLog,
  };
  return obj[submenu] ? obj[submenu] : '';
};
