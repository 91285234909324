import React, {
  useState, useEffect, Fragment, useContext,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';
import moment from 'moment';
import MUITooltip from '@material-ui/core/Tooltip';
import { LanguageContext } from '../../../LanguageContext';
import CircularIndeterminate from '../../../common/CircularIndeterminate';
import PaginationTab from '../../../common/PaginationTab/PaginationTab';
import { fetchAlertNotificationListAsync, fetchAlertNotificationByIdAsync } from '../../../redux/slices/alertnotificationsSlice';
import AlertNotificationModal from './alert-notification-popup';
/* eslint no-nested-ternary:0 */
const AlertNotificationsTable = ({ filterValues, page, setPage }) => {
  const defaultCardCount = 15;
  const size = defaultCardCount;
  const [start, setStart] = useState();
  const [end, setEnd] = useState();
  const [editMode, setEditMode] = useState(false);
  const [selectedData, setSelectedData] = useState({});

  const dispatch = useDispatch();

  const [search, setSearch] = useState('');

  useEffect(() => {
    dispatch(fetchAlertNotificationListAsync({
      search,
      page,
      size,
      name: filterValues.machine_id || '',
      from: filterValues.From_Date ? moment(filterValues.From_Date).format('YYYY-MM-DD HH:mm:ss') : '' || '',
      to: filterValues.To_Date ? moment(filterValues.To_Date).format('YYYY-MM-DD HH:mm:ss') : '' || '',
      comType: filterValues.comType || '',
      comMode: filterValues.comMode || '',
    }));
  }, [search, page, size, filterValues]);

  const count = useSelector(
    (state) => state?.alertnotfications?.alertNotificationList?.count || 0,
  );

  const listData = useSelector(
    (state) => state?.alertnotfications?.alertNotificationList?.rows || [],
  );

  const isLoading = useSelector(
    (state) => state.alertnotfications.alertNotificationListStatus,
  ) === 'loading';

  useEffect(() => {
    const st = (page - 1) * size + 1;
    const en = st + (size - 1) > count ? count : st + (size - 1);
    setStart(st);
    setEnd(en);
  }, [page, size, count]);

  const onSearchChange = debounce((e) => {
    setPage(1);
    setSearch(e.target.value);
  }, 2000);

  // for language change
  // const [Lang] = useContext(LanguageContext);

  const onPrevious = (pageNumber) => {
    setPage(pageNumber - 1);
  };

  const onNext = (pageNumber) => {
    setPage(pageNumber + 1);
  };

  const onEditClicked = async (e) => {
    const index = parseInt(e.currentTarget.parentNode.getAttribute('index'), 10);
    const result = await dispatch(fetchAlertNotificationByIdAsync({ id: listData[index].ExecutionResult_ID }));
    setSelectedData(result.payload);
    setEditMode(true);
  };

  const onClosed = () => {
    setEditMode(false);
  };

  const extractContent = (html) => new DOMParser()
    .parseFromString(html, 'text/html')
    .documentElement.textContent;
  return (
    <>
      <div className="alert-notifications">
        <div className="cm-reports-bg">
          <div className="alarmBar padTopOnly dropRow altRow">
            <div className="input-group pull-right search-container">
              <svg className="searchIconGlobalSvg" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path className="searchP1" fillRule="evenodd" clipRule="evenodd" d="M6.125 2.82751C4.192 2.82751 2.625 4.39452 2.625 6.32751C2.625 8.26051 4.192 9.82751 6.125 9.82751C8.058 9.82751 9.625 8.26051 9.625 6.32751C9.625 4.39452 8.058 2.82751 6.125 2.82751ZM1.625 6.32751C1.625 3.84223 3.63972 1.82751 6.125 1.82751C8.61028 1.82751 10.625 3.84223 10.625 6.32751C10.625 8.8128 8.61028 10.8275 6.125 10.8275C3.63972 10.8275 1.625 8.8128 1.625 6.32751Z" fill="#b7b7b7" />
                <path className="searchP2" fillRule="evenodd" clipRule="evenodd" d="M8.5964 8.79896C8.79166 8.6037 9.10824 8.6037 9.3035 8.79896L11.4785 10.974C11.6738 11.1692 11.6738 11.4858 11.4785 11.6811C11.2832 11.8763 10.9667 11.8763 10.7714 11.6811L8.5964 9.50607C8.40114 9.3108 8.40114 8.99422 8.5964 8.79896Z" fill="#b7b7b7" />
              </svg>
              <input
                className="form-control py-2 form-control customForm input-md search-box"
                type="search"
                placeholder="Search..."
                onChange={(e) => onSearchChange(e.nativeEvent)}
              />
            </div>
          </div>
          <div className={listData && listData.length > 0 ? 'alert-notification-table' : 'alert-notification-table fmeatabb'}>
            <div className="scrollabletab">
              <div className="alertDivsBg">
                <div className="alertDivs">
                  <div className="headerTable">
                    {/* <span className="row0">{Lang.ID}</span>
                    <span className="row1">{Lang.ExecutionId}</span>
                    <span className="row2">{Lang.AlertType}</span>
                    <span className="row3">{Lang.CommunicationType}</span>
                    <span className="row4">{Lang.Description}</span>
                    <span className="row5">{Lang.AlertText}</span>
                    <span className="row6">{Lang.NotificationText}</span>
                    <span className="row7">{Lang.DeliveryStatus}</span>
                    <span className="row8">{Lang.EmailTo}</span>
                    <span className="row9">{Lang.EmailCC}</span>
                    <span className="row10">{Lang.PhoneNumber}</span>
                    <span className="row11">{Lang.TimeStamp}</span> */}
                    <span className="row0">ID</span>
                    <span className="row1">Execution Id</span>
                    <span className="row2">Alert Type</span>
                    <span className="row3">Communication Type</span>
                    <span className="row4">Description</span>
                    {/* <span className="row5">Alert Text</span> */}
                    {/* <span className="row6">Notification Text</span> */}
                    <span className="row7">Delivery Status</span>
                    <span className="row8">Email To</span>
                    <span className="row9">Email CC</span>
                    <span className="row10">Phone Number</span>
                    <span className="row11">Time Stamp</span>
                  </div>
                  {!isLoading && listData && listData.length > 0 && (
                    <div
                      className="pannelWrap overflowScrolls common-scroll"
                      id="pannelWrap"
                    >
                      <div className="bs-example">
                        <div className="panel-group" id="accordion1">
                          {
                            listData.map((item, i) => (
                              <div className="panel panel-default alarmRows" key={i}>
                                <div className="panel-heading">
                                  <h4 className="panel-title">
                                    <div index={i}>
                                      <span className="row0">{item.AlertNotificationLog_ID}</span>
                                      <MUITooltip
                                        className="conditional-monitoring"
                                        title="Click to view"
                                        PopperProps={{ className: 'MuiTooltip-popper MuiTooltip-popperInteractive toolstips' }}
                                        arrow
                                      >
                                        <span className="row1" onClick={onEditClicked}>{item.ExecutionResult_ID}</span>
                                      </MUITooltip>
                                      <MUITooltip
                                        className="conditional-monitoring"
                                        // title={item.Criticality === 'A' ? Lang.Alert : Lang.Notification}
                                        title={item.Criticality === 'A' ? 'Alert' : 'Notification'}
                                        PopperProps={{ className: 'MuiTooltip-popper MuiTooltip-popperInteractive toolstips' }}
                                        arrow
                                      >
                                        {/* <span className="row2">{item.Criticality === 'A' ? Lang.Alert : Lang.Notification}</span> */}
                                        <span className="row2">{item.Criticality === 'A' ? 'Alert' : 'Notification'}</span>
                                      </MUITooltip>
                                      <MUITooltip
                                        className="conditional-monitoring"
                                        // title={item.CommunicationType === 'E' ? Lang.Email : Lang.Message}
                                        title={item.CommunicationType === 'E' ? 'Email' : 'Message'}
                                        PopperProps={{ className: 'MuiTooltip-popper MuiTooltip-popperInteractive toolstips' }}
                                        arrow
                                      >
                                        {/* <span className="row3">{item.CommunicationType === 'E' ? Lang.Email : Lang.Message}</span> */}
                                        <span className="row3">{item.CommunicationType === 'E' ? 'Email' : 'Message'}</span>
                                      </MUITooltip>
                                      <MUITooltip
                                        className="conditional-monitoring"
                                        title={extractContent(item.Description)}
                                        PopperProps={{ className: 'MuiTooltip-popper MuiTooltip-popperInteractive toolstips' }}
                                        arrow
                                      >
                                        <span className="row4">{extractContent(item.Description)}</span>
                                      </MUITooltip>
                                      {/* <MUITooltip
                                        className="conditional-monitoring"
                                        title={item.Criticality === 'A' ? item.CommunicationType === 'E' ? extractContent(item.MessageText_Email) : extractContent(item.MessageText_SMS) : '-'}
                                        PopperProps={{ className: 'MuiTooltip-popper MuiTooltip-popperInteractive toolstips' }}
                                        arrow
                                      >
                                        <span className="row5">{item.Criticality === 'A' ? item.CommunicationType === 'E' ? extractContent(item.MessageText_Email) : extractContent(item.MessageText_SMS) : '-'}</span>
                                      </MUITooltip>
                                      <MUITooltip
                                        className="conditional-monitoring"
                                        title={item.Criticality === 'N' ? item.CommunicationType === 'E' ? extractContent(item.MessageText_Email) : extractContent(item.MessageText_SMS) : '-'}
                                        PopperProps={{ className: 'MuiTooltip-popper MuiTooltip-popperInteractive toolstips' }}
                                        arrow
                                      >
                                        <span className="row6">{item.Criticality === 'N' ? item.CommunicationType === 'E' ? extractContent(item.MessageText_Email) : extractContent(item.MessageText_SMS) : '-'}</span>
                                      </MUITooltip> */}
                                      <MUITooltip
                                        className="conditional-monitoring"
                                        title={item.Delivery_Status === 'S' ? "Y" : "N" }
                                        PopperProps={{ className: 'MuiTooltip-popper MuiTooltip-popperInteractive toolstips' }}
                                        arrow
                                      >
                                        <span className="row7">{item.Delivery_Status === 'S' ? "Y" : "N" }</span>
                                      </MUITooltip>
                                      <MUITooltip
                                        className="conditional-monitoring"
                                        title={item.ToEmail ? item.ToEmail : '-'}
                                        PopperProps={{ className: 'MuiTooltip-popper MuiTooltip-popperInteractive toolstips' }}
                                        arrow
                                      >
                                        <span className="row8">{item.ToEmail ? item.ToEmail : '-'}</span>
                                      </MUITooltip>
                                      <MUITooltip
                                        className="conditional-monitoring"
                                        title={item.CCEmail ? item.CCEmail : '-'}
                                        PopperProps={{ className: 'MuiTooltip-popper MuiTooltip-popperInteractive toolstips' }}
                                        arrow
                                      >
                                        <span className="row9">{item.CCEmail ? item.CCEmail : '-'}</span>
                                      </MUITooltip>
                                      <MUITooltip
                                        className="conditional-monitoring"
                                        title={item.PhoneNumber ? item.PhoneNumber : '-'}
                                        PopperProps={{ className: 'MuiTooltip-popper MuiTooltip-popperInteractive toolstips' }}
                                        arrow
                                      >
                                        <span className="row10">{item.PhoneNumber ? item.PhoneNumber : '-'}</span>
                                      </MUITooltip>
                                      <MUITooltip
                                        className="conditional-monitoring"
                                        title={item.Timestamp}
                                        PopperProps={{ className: 'MuiTooltip-popper MuiTooltip-popperInteractive toolstips' }}
                                        arrow
                                      >
                                        <span className="row11">{item.Timestamp}</span>
                                      </MUITooltip>

                                    </div>
                                  </h4>
                                </div>
                              </div>
                            ))
                          }
                        </div>
                      </div>
                    </div>
                  )}

                  {isLoading && (
                    <div className="pannelWrap overflowScrolls" id="pannelWrap">
                      <div
                        className="loaderHistoryAlarms"
                        style={{ marginTop: '100px' }}
                      >
                        <CircularIndeterminate />
                      </div>
                    </div>
                  )}

                  {!isLoading && (listData === undefined || listData.length === 0) && (
                    <div className="noHistoryAlarms">No Record Available</div>
                  )}
                </div>

              </div>
            </div>

          </div>
          <div className={`alarm-pagination ${count <= defaultCardCount ? 'cardBelowNine' : 'float-container'}`}>
            <div className="panellefttext">
              <div className="shownotext">
                {` ${start} to ${end} of ${count} `}
              </div>
            </div>
            <div className="panelrighttext">
              <PaginationTab
                Previous={onPrevious}
                Next={onNext}
                page={page}
                maxPage={Math.ceil(count / size)}
              />
            </div>
          </div>
        </div>
      </div>
      {editMode && <AlertNotificationModal currentDetails={selectedData} onClosed={onClosed} />}
    </>
  );
};

export default AlertNotificationsTable;
