/* eslint-disable react/jsx-props-no-spreading */
import React, {
  useEffect, useState, useContext, useRef, Fragment,
} from 'react';
import { Form, Field } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import moment from 'moment';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DateTimePicker } from '@material-ui/pickers';
import Button from '@material-ui/core/Button';
import '../../../styles/kpi.css';
import { LanguageContext } from '../../../LanguageContext';
import {
  fetchMachineModelAsync,
} from '../../../redux/slices/executionResultSlice';
// import InlineDateTimePickerDemo from "../../../components/dashboard/reports/InlineDateTimePickerDemo";
/* eslint no-unused-vars:0 */

const validate = () => { };

export default function ExecutionFilter({ filterVisible, setFilterVisible, onSubmited }) {
  const dispatch = useDispatch();
  const [isFilterActive, setIsFilterActive] = useState(false);
  const resolveTypes = [{
    resolveText: 'Resolved',
  }, {
    resolveText: 'Unresolved',
  }];

  const [model, setModel] = useState('');
  const [resolveType, setResolveType] = useState('');
  // const [endDate, setEndDate] = useState('');
  //   var dispatch = useDispatch();
  // const [Lang] = useContext(LanguageContext);
  const formRef = useRef(null);
  const onSubmit = async (values) => {
    if (values.MachineModel_Name || values.From_Date || values.To_Date || values.resolveType) {
      setIsFilterActive(true);
      onSubmited(values);
    } else {
      setIsFilterActive(false);
      onSubmited({});
    }
  };

  useEffect(() => {
    dispatch(fetchMachineModelAsync());
  }, []);

  useEffect(() => {
    const { values } = formRef.current.getState();
    onSubmit(values);
  }, []);

  const machineModels = useSelector(
    (state) => state.executionResult.machineModels,
  );

  const fromStart = new Date();
  fromStart.setDate(fromStart.getDate() - 30);
  const toEnd = new Date();

  return (
    <>
      <div
        className={`filterInnerDiv ${filterVisible ? 'active' : ''} 
            ${isFilterActive ? 'filter-is-on' : ''}`}
        onClick={() => setFilterVisible(!filterVisible)}
      >
        <span
          className="filterIcon pointer"
        >
          <svg
            width="17"
            height="17"
            viewBox="0 0 17 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14.418 2.5H2.41797L7.21797 8.80667V13.1667L9.61797 14.5V8.80667L14.418 2.5Z"
              stroke="#8F8F8F"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </span>
      </div>
      <div className="cm-filter conditionMonitoring">
        <div className={`filterDiv ${filterVisible ? '' : 'removedWidth'}`}>
          <div className="exResultOverflowScroll">
            <Form
              mutators={{
                setReset: (args, state, utils) => {
                  utils.changeValue(state, 'From_Date', () => fromStart);
                  utils.changeValue(state, 'To_Date', () => toEnd);
                  utils.changeValue(state, 'MachineModel_Name', () => '');
                  utils.changeValue(state, 'resolveType', () => '');
                  setModel('');
                  setResolveType('');
                  setIsFilterActive(false);
                },
              }}
              onSubmit={onSubmit}
              validate={validate}
              initialValues={{ From_Date: moment(fromStart).format('YYYY-MM-DDTHH:mm'), To_Date: moment(toEnd).format('YYYY-MM-DDTHH:mm') }}
              keepDirtyOnReinitialize
              render={({ handleSubmit, form, reset }) => {
              // render={({ handleSubmit, form }) => {
                formRef.current = form;
                return (
                  <div className="slick-carousel slickcarouselTop">

                    <form onSubmit={handleSubmit} id="failure-mode-form">

                      <div>
                        <span
                          className="resetBtn resetpost pointer"
                          onClick={() => {
                            form.mutators.setReset();
                            onSubmited({});
                          }}
                        >
                          {/* {Lang.Reset} */}
                          Reset
                        </span>

                        <div>
                          <div className="form-group mui-date-picker">
                            <div className="input-control">
                              <label className="font10">From</label>
                              <div className="input-group">
                                <Field
                                  name="From_Date"
                                  className="form-control"
                                >
                                  {(props) => (
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                      <DateTimePicker
                                        disableFuture
                                        variant="inline"
                                        format="dd-MM-yyyy HH:mm"
                                        margin="normal"
                                        id="from"
                                        autoOk
                                        maxDate={form.getFieldState('To_Date') && form.getFieldState('To_Date').value}
                                        value={props.input.value}
                                        onChange={(value) => {
                                          props.input.onChange(value);
                                        }}
                                      />
                                    </MuiPickersUtilsProvider>
                                  )}
                                </Field>
                              </div>
                            </div>
                          </div>

                          <div className="form-group mui-date-picker">
                            <div className="input-control">
                              <label className="font10">To</label>
                              <div className="input-group">
                                <Field
                                  name="To_Date"
                                  className="form-control"
                                >
                                  {(props) => (
                                    <DateTimePicker
                                      disableFuture
                                      variant="inline"
                                      format="dd-MM-yyyy HH:mm"
                                      margin="normal"
                                      id="to"
                                      autoOk
                                      minDate={form.getFieldState('From_Date') && form.getFieldState('From_Date').value}
                                      value={props.input.value}
                                      onChange={(value) => {
                                        props.input.onChange(value);
                                      }}
                                    />
                                  )}
                                </Field>
                              </div>
                            </div>
                          </div>

                          <div className="form-group">
                            <div className="input-control">
                              <label className="font10">
                                {/* {Lang.MachineModel} */}
                                Machine Model
                              </label>
                              <div className="input-group">
                                <div>
                                  <Field
                                    name="MachineModel_Name"
                                    className="form-control"
                                    placeholder="Select"
                                  >
                                    {(props) => (
                                      <Autocomplete
                                        value={(machineModels && machineModels.find((m) => m.MachineModel_Name === (props.input.value || model))) || ''}
                                        getOptionSelected={(option, value) => option.MachineModel_Name === value.MachineModel_Name}
                                        onChange={(event, value) => {
                                          setModel(value ? value.MachineModel_Name : '');
                                          props.input.onChange(value ? value.MachineModel_Name : '');
                                        }}
                                        onBlur={props.input.onBlur}
                                        options={machineModels}
                                        getOptionLabel={(option) => option.MachineModel_Name || ''}
                                        // placeholder={Lang.SelectMachineModel}
                                        renderInput={(params) => <TextField {...params} placeholder="Select Machine Model" variant="outlined" />}
                                        disablePortal
                                      />
                                    )}
                                  </Field>
                                  <span id="machinemodel" className="caret" />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="form-group">
                            <div className="input-control">
                              {/* <label className="font10">{Lang.Status}</label> */}
                              <label className="font10">Status</label>
                              <div className="input-group">
                                <div>
                                  <Field
                                    name="resolveType"
                                    className="form-control"
                                    placeholder="Select"
                                  >
                                    {(props) => (
                                      <Autocomplete
                                        value={(resolveTypes && resolveTypes.find((m) => m.resolveText === (props.input.value || resolveType))) || ''}
                                        getOptionSelected={(option, value) => option.resolveText === value.resolveText}
                                        onChange={(event, value) => {
                                          setResolveType(value ? value.resolveText : '');
                                          props.input.onChange(value ? value.resolveText : '');
                                        }}
                                        onBlur={props.input.onBlur}
                                        options={resolveTypes}
                                        getOptionLabel={(option) => option.resolveText || ''}
                                        // placeholder={Lang.SelectStatus}
                                        renderInput={(params) => <TextField {...params} placeholder="Select Status" variant="outlined" />}
                                        disablePortal
                                      />
                                    )}
                                  </Field>
                                  <span id="resolveType" className="caret" />
                                </div>

                              </div>
                            </div>
                          </div>
                          <Button
                            className="comnEditDel MuiButton-containedPrimary editGroupBtn floatLeft"
                            type="submit"
                          >
                            GO
                          </Button>
                        </div>

                      </div>

                    </form>
                  </div>
                );
              }}
            />

          </div>

        </div>
      </div>
    </ >
  );
}
