// import axios from 'axios';
// import { APIUrl } from '../../configs/config';
import { APIUrl } from '../../../../helper/constants';
// import authHeader from '../../../../config/auth.header';
import CallAPI from '../../../../services/conditionMonitoring.service';
// const headers = {
//   headers: authHeader(),
// };

// A mock function to mimic making an async request for data
export function fetchFailureModes(page = 1, size = 10, search, values, model, sortName, sortDirection) {
  const apiInfo = {
    url: `${APIUrl}/api/failureMode?page=${page}&size=${size}&search=${search}&groups=${values}
    &machineModelId=${model}&sortName=${sortName}&sortDirection=${sortDirection}`,
    method: 'GET',
    isMultiPart: false,
  };
  return CallAPI(apiInfo).then((res) => res.data);
  // return axios
  //   .get(

  //     `${APIUrl}/api/failureMode?page=${page}&size=${size}&search=${search}&groups=${values}
  //     &machineModelId=${model}&sortName=${sortName}&sortDirection=${sortDirection}`, headers,
  //   )
  //   .then((res) => res.data);
}

export function saveActiveStatus(id, status) {
  const apiInfo = {
    url: `${APIUrl}/api/saveActivate`,
    method: 'POST',
    postData: { FailureMode_ID: id, IsActive: status },
    isMultiPart: false,
  };
  return CallAPI(apiInfo).then((res) => res.data);
  // return axios
  //   .post(`${APIUrl}/api/saveActivate`, {
  //     FailureMode_ID: id,
  //     IsActive: status,
  //   }, headers);
  // .then((res) => console.log("Status updated"));
}
