import React, { useState, useContext } from 'react';
import { Dialog, Tooltip } from '@material-ui/core';
import { LanguageContext } from '../../../LanguageContext';
import TemplateField from './template-field';
import style from './edit-notification-template.module.css';
import Icon from '../../Dashboard/Sidebar/images/Icon';
/* eslint no-unused-expressions:0 */
/* eslint no-shadow:0 */

const EditNotificationTemplateModal = ({
  currentDetails, onSaved, onClosed, needsUpdate, placeholders,
}) => {
  // const [anchorEle, setAnchorEle] = useState('');
  const [content, setContent] = useState('');
  const [name, setName] = useState('');
  const [isEmailType, setEmailType] = useState(true);
  const [subject, setSubject] = useState('');
  const [errorTxt, setErrorTxtValue] = useState('');
  const [errorFields, setErrorFields] = useState([]);

  // const [Lang] = useContext(LanguageContext);
  if (needsUpdate.value && currentDetails) {
    setName(currentDetails.TemplateName);
    setSubject(currentDetails.Subject);
    setContent(currentDetails.Contents);
    setEmailType(currentDetails.CommunicationType === 'E');
    needsUpdate.value = false;
  }

  const onNameChanged = (e) => {
    setName(e.target.value);
  };
  const onSubjectChanged = (e) => {
    setSubject(e.target.value);
  };

  const onCommunicationTypeChanged = (e) => {
    setEmailType(e.target.id === 'email');
  };

  const onCloseBtnClicked = () => {
    onClosed();
  };

  const onSaveBtnClicked = async () => {
    let errorString = '';
    const errorFields = [];
    if (!name) {
      errorFields.push('name');
      // errorString = "Name is Mandatory.";
    }
    if (isEmailType && !subject) {
      errorFields.push('subject');
      // errorString = "Subject is Mandatory.";
    }
    if (!isEmailType && content.trim() === '') {
      errorFields.push('content');
      // errorString = "Content is Mandatory.";
    }
    if (!isEmailType && content.length > 1000) {
      errorFields.push('content');
      errorString = 'Content is should be less than 160 characters.';
    }
    setErrorFields(errorFields);
    if (errorString || errorFields.length > 0) {
      setErrorTxtValue(errorString);
      return;
    }
    const res = await onSaved(currentDetails, name, isEmailType ? 'E' : 'S', isEmailType ? subject : '', content);
    if (!res.Result) setErrorTxtValue(res.Message);
  };

  const onContentChanged = (value) => {
    setContent(value);
  };

  // const handleAutomCompleteClose = () => {
  //   setAnchorEle(null);
  // };
  console.log('name', name);
  return (
    <Dialog open disableEnforceFocus>
      <div className="modelboxopen" ref={(e) => { e && e.style.setProperty('z-index', 1000, 'important'); }}>
        <div className={`modelboxopenInnner ${style.modelBox}`} id="notification-dialog">
          <div className="wrapContent">
            <div className="containerIner2">
              <div className="popupCloseButton bbt">
                <h3 className="titleDiv">Notification Template</h3>
                <div
                  className="closepopup pointer"
                  onClick={onCloseBtnClicked}
                >
                  &#x2715;
                </div>
              </div>
              <div className="contentPopup notification-template-editor">
                <div className="innerContentPopup">
                  <div className="descriptionCreate">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group formgroup">
                          {/* <div className="newPin">{Lang.PleaseEnter}</div> */}
                          <div className="font14">
                            <div id="edit-notification-group-fields">
                              <div className="row-md-16">
                                <div className="form-group2">
                                  <label className="font12">Template Name</label>
                                  <span className="redStar">*</span>
                                  <input
                                    id="textbox1"
                                    name="textbox1"
                                    type="text"
                                    placeholder="Enter Name"
                                    onChange={onNameChanged}
                                    value={name}
                                    className={`form-control customForm input-md${errorFields.indexOf('name') !== -1 ? ' error-field' : ''}`}
                                  />
                                </div>
                              </div>
                              <div className="row-md-16 radio-label">
                                <div className="form-group2 mt-1">
                                  <label className="font12">Communication Type</label>
                                  <div>
                                    <input
                                      name="type"
                                      type="radio"
                                      checked={isEmailType}
                                      value="Email"
                                      className="form-control customForm input-md"

                                    />
                                    <label id="email" className={`font14 ${style.radio}`} onClick={onCommunicationTypeChanged}>Email</label>
                                    <input
                                      name="type"
                                      type="radio"
                                      checked={!isEmailType}
                                      value="SMS"
                                      className="form-control customForm input-md"
                                    />
                                    <label id="sms" className={`font14 ${style.radio}`} onClick={onCommunicationTypeChanged}>SMS</label>
                                  </div>
                                </div>
                              </div>
                              {isEmailType
                                && (
                                  <div className="row-md-16">
                                    <div className="form-group2">
                                      <label className="font12">Subject</label>
                                      <span className="redStar">*</span>
                                      <input
                                        id="textbox1"
                                        name="textbox1"
                                        type="text"
                                        placeholder="Enter Subject"
                                        onChange={onSubjectChanged}
                                        value={subject}
                                        className={`form-control customForm input-md${errorFields.indexOf('subject') !== -1 ? ' error-field' : ''}`}
                                      />
                                    </div>
                                  </div>
                                )}
                                {!isEmailType &&
                                  <div className="row-md-16">
                                    <div className={`form-group2${errorFields.indexOf('content') !== -1 ? ' error-field' : ''}`}>
                                      <label className="font12">Content</label>
                                      <span className="redStar">*</span>
                                      <span className="tooltip_formula">
                                        <Tooltip
                                          PopperProps={{ className: 'MuiTooltip-popper MuiTooltip-popperInteractive s' }}
                                          arrow
                                          title={(
                                            <div className="fia-desc">
                                              <div className="fia-desc-head">Points to consider when configuring placeholder:</div>
                                              <br />
                                              <span>Type @ and select from a list of placeholders relevant</span>
                                              <br />
                                            </div>
                                          )}
                                        >
                                          <Icon name="AlertIcon" />

                                        </Tooltip>
                                      </span>
                                      <TemplateField templatePlaceholder={placeholders} content={content} onDataChanged={onContentChanged} />
                                    </div>
                                  </div>
                                }
                            </div>
                          </div>
                          <div className="enterpins placeColor">
                            <div className="pinErrorTxt">{errorTxt}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="footerPopupWithBtn">
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <button
                        type="button"
                        id="cancelBtn"
                        name="cancel"
                        className="btn btn-default cancelBtn"
                        value="2"
                        onClick={onCloseBtnClicked}
                      >
                        {/* {Lang.Cancel} */}
                        Cancel
                      </button>
                      <button
                        type="button"
                        id="saveBtn"
                        name="Save"
                        className="btn btn-default saveBtn"
                        value="1"
                        onClick={onSaveBtnClicked}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default EditNotificationTemplateModal;
