import axios from 'axios';
import apiConfig from '../config/api.config.json';

export default async function CallUploadAPI(apiInfo) {
  //   try {
  const {
    method, url, postData: formData, isMultiPart,
  } = apiInfo;
  const token = localStorage.getItem('token'); // await getItem('token') // get token
  const UserKey = localStorage.getItem('UserID');
  let apiData = {};
  let headers = { 'x-access-token': token, UserKey };
  if (isMultiPart) {
    headers = { ...headers, 'Content-Type': 'multipart/form-data' };
    apiData = { ...apiData, responseType: 'blob' };
  }
  formData.append('User_ID', UserKey);
  apiData = { ...apiData, method, url };
  if (formData) { // set payload
    apiData = { ...apiData, data: formData };
  }
  if (![apiConfig.API_LOGIN].includes(url)) { // token excemption routes
    if (token) { // set token in headers
      apiData = {
        ...apiData,
        headers,
      };
    }
  }
  const response = await axios(apiData);
  return response;
}
