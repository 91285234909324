/* eslint-disable react/jsx-props-no-spreading */
import { Field, Form } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import React, { useState, useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { Drawer } from 'antd';
import { useHistory } from 'react-router-dom';
import { debounce } from 'lodash';
import Tooltip from '@material-ui/core/Tooltip';
// import { ReactComponent as AlertIcon } from '../../../components/dashboard/images/alert-circle.svg';
import { LanguageContext } from '../../../../LanguageContext';
import { fetchFailureModesAsync, saveActiveStatusAsync } from '../../../../redux/slices/failureModeListingSlice';

import { FailureModelAddComponent } from '../failure-mode/add/FailureModeAdd';
import { fetchMachineModelAsync } from '../../../../redux/slices/failureModeAddSlice';
// import PaginationTab from '../../../components/dashboard/common/PaginationTab';
// import CircularIndeterminate from '../../../components/dashboard/common/CircularIndeterminate';
import PaginationTab from '../../../../common/PaginationTab/PaginationTab';
import CircularIndeterminate from '../../../../common/CircularIndeterminate';
// import SearchIcon from '../images/searchIcon';
import { ReactComponent as Edit } from '../../../../images/editSpoc.svg';
import Icon from '../../../Dashboard/Sidebar/images/Icon';

import {
  editFailureModesAsync,
  clearEdit,
} from '../../../../redux/slices/failureModeDetailsSlice';
import 'antd/dist/antd.css';
import 'antd/dist/antd.dark.css';
/* eslint no-unused-vars:0 */
export function FailureModeListing() {
  const dispatch = useDispatch();
  const history = useHistory();

  // const pageSizes = [15, 25, 50, 100];
  const options = ['Machine Model', 'Severity', 'Status'];

  const rowsPerPage = 15;
  const defaultCardCount = rowsPerPage;
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(defaultCardCount);
  const [search, setSearch] = useState('');
  const [start, setStart] = useState(1);
  const [end, setEnd] = useState(1);
  const [values, setValues] = useState(options[0]);
  const [visible, setVisible] = useState(false);
  const [model, setModel] = useState('');
  const [sortName, setSortName] = useState('');
  const [sortDirection, setSortDirection] = useState('');
  // const [Lang] = useContext(LanguageContext);

  const required = (value) => (value ? undefined : 'Required');

  useEffect(() => {
    dispatch(fetchFailureModesAsync({
      page, size, search, values, model, sortName, sortDirection,
    }));
  }, [page, size, search, values, model, sortName, sortDirection]);

  const onSaveFailureMode = () => {
    dispatch(fetchFailureModesAsync({
      page, size, search, values, model, sortName, sortDirection,
    }));
  };

  const count = useSelector(
    (state) => state?.failureModeListing?.failureModes?.count || 0,
  );

  const listData = useSelector(
    (state) => state?.failureModeListing?.failureModes?.rows || [],
  );

  useEffect(() => {
    dispatch(fetchMachineModelAsync());
  }, []);

  const machineModels = useSelector(
    (state) => state.failureModeEntry.machineModels,
  );

  // let start = 1, end = 5;
  useEffect(() => {
    const st = (page - 1) * size + 1;
    const en = st + (size - 1) > count ? count : st + (size - 1);
    // let en = st + size - 1;
    setStart(st);
    setEnd(en);
  }, [page, size, count]);

  const onSearchChange = debounce((e) => {
    setPage(1);
    setSearch(e.target.value);
  }, 500);

  const onDetailsClick = (id) => {
    history.push(`/FMEA/Failure-Mode-Details/${id}`);
  };

  const onFailureModeClick = (id) => {
    dispatch(editFailureModesAsync({ id }));
    setVisible(true);
  };

  const onActivate = (id, status) => {
    dispatch(saveActiveStatusAsync({ id, status }));
  };

  const onAddEntryClose = () => {
    setVisible(false);
    dispatch(clearEdit());
  };

  const onSortHeaderClick = (fieldName) => {
    setPage(1);
    setSortName(fieldName);
    if (sortDirection === '') {
      setSortDirection('ASC');
    } else if (sortDirection === 'ASC') {
      setSortDirection('DESC');
    } else {
      setSortDirection('');
    }
  };

  const onPrevious = (pageNumber) => {
    setPage(pageNumber - 1);
  };

  const onNext = (pageNumber) => {
    setPage(pageNumber + 1);
  };

  const isLoading = false;
  return (
    <>
      <div className="fm failuremodulePage">
        <div className="contentWrap monitoringcontentWrap">
          <div className="alarmBar padTopOnly row dropRow">
            <div className="col-sm-9">
              {' '}
              <div className="pull-left model-container w-100">
                <Form
                  onSubmit={() => { }}
                  render={({ handleSubmit, form }) => (
                    <Field
                      name="OccurrenceCriteria_ID"
                      className="form-control"
                      validate={required}
                      placeholder="Occurrence Frequency"
                    >
                      {(props) => (
                        <Autocomplete
                          value={(machineModels && machineModels.find((m) => m.MachineModel_ID === props.input.value)) || ''}
                          getOptionSelected={(option, value) => option.MachineModel_ID === value.MachineModelID}
                          onChange={(event, value) => {
                            setPage(1);
                            if (value) setModel(value.MachineModel_ID);
                            else setModel('');
                            props.input.onChange(value ? value.MachineModel_ID : '');
                          }}
                          onBlur={props.input.onBlur}
                          options={machineModels}
                          getOptionLabel={(option) => option.MachineModel_Name || ''}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder="Select Machine Model"
                              className={`${props.input.value === '' && props.meta.touched
                                ? ''
                                : ''
                              }`}
                              variant="outlined"
                            />
                          )}
                          disabled={false}
                          disablePortal={false}
                        />
                      )}
                    </Field>
                  )}
                />
              </div>
              {' '}
              <div className="input-group search-container">
                <Icon name="searchIcon" />
                <input
                  // ref={searchBox}
                  // className="form-control py-2"
                  type="search"
                  className="form-control py-2 customForm input-md search-box"
                  placeholder="Search by Failure Mode Entry, Severity, Machine Model, Section and Status"
                  onChange={(e) => onSearchChange(e.nativeEvent)}
                />
              </div>
            </div>
            <div className="col-sm-3">

              <button type="button" className="pull-right btn btn-secondary add-entry-button" onClick={() => { setVisible(true); }}>
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5.99951 0.666504V11.3332" stroke="#5D97F6" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M0.666504 6H11.3332" stroke="#5D97F6" strokeLinecap="round" strokeLinejoin="round" />
                </svg>

                Add Entry
              </button>
            </div>

          </div>
          <div className="alertDivsBg hover-row">
            <div className="alertDivs">
              <div className="headerTable">
                <span
                  className="row1"
                  onClick={() => onSortHeaderClick('FailureMode_ID')}
                  style={{ cursor: 'pointer' }}
                >
                  Failure Mode Entry
                  {sortName === 'FailureMode_ID' && sortDirection === 'ASC' && <i className="fa fa-caret-up sort-place" />}
                  {sortName === 'FailureMode_ID' && sortDirection === 'DESC' && <i className="fa fa-caret-down sort-place" />}
                </span>
                <span
                  className="row2"
                  style={{ textAlign: 'center', cursor: 'pointer' }}
                  onClick={() => onSortHeaderClick('Severity')}
                >
                  Severity
                  {sortName === 'Severity' && sortDirection === 'ASC' && <i className="fa fa-caret-up sort-place" />}
                  {sortName === 'Severity' && sortDirection === 'DESC' && <i className="fa fa-caret-down sort-place" />}
                </span>
                <span
                  className="row3"
                  style={{ textAlign: 'center', cursor: 'pointer' }}
                  onClick={() => onSortHeaderClick('MachineModel_Name')}
                >
                  Machine Model
                  {sortName === 'MachineModel_Name' && sortDirection === 'ASC' && <i className="fa fa-caret-up sort-place" />}
                  {sortName === 'MachineModel_Name' && sortDirection === 'DESC' && <i className="fa fa-caret-down sort-place" />}
                </span>
                <span
                  className="row4"
                  style={{ textAlign: 'center', cursor: 'pointer' }}
                  onClick={() => onSortHeaderClick('MachineSection_Name')}
                >
                  Section
                  {sortName === 'MachineSection_Name' && sortDirection === 'ASC' && <i className="fa fa-caret-up sort-place" />}
                  {sortName === 'MachineSection_Name' && sortDirection === 'DESC' && <i className="fa fa-caret-down sort-place" />}
                </span>
                <span className="row5" style={{ textAlign: 'center', cursor: 'pointer' }} onClick={() => onSortHeaderClick('Status')}>
                  Status
                  {sortName === 'Status' && sortDirection === 'ASC' && <i className="fa fa-caret-up sort-place" />}
                  {sortName === 'Status' && sortDirection === 'DESC' && <i className="fa fa-caret-down sort-place" />}
                </span>
                <span className="row6" style={{ textAlign: 'center' }}>
                  Activate
                </span>
                <span className="row7">Action</span>
              </div>
              {!isLoading && listData && listData.length > 0 && (
                <div
                  className="pannelWrap overflowScrolls common-scroll"
                  id="pannelWrap"
                >
                  <div className="bs-example">
                    <div className="panel-group" id="accordion1">
                      {
                        listData.map((item) => (
                          <div className="panel panel-default alarmRows" key={item.GeneralCondition_ID}>
                            <div className="panel-heading">
                              <h4 className="panel-title">
                                <div>
                                  <span className="row1">
                                    <p
                                      className="failure-mode-listing-desc"
                                      onClick={() => onDetailsClick(item.FailureMode_ID)}
                                      style={{ cursor: 'pointer' }}
                                      title={item.Description}
                                    >
                                      {' '}
                                      {item.Description}
                                      {' '}
                                    </p>
                                    <p className="failure-mode-listing-sub-desc" onClick={() => onDetailsClick(item.FailureMode_ID)}>
                                      {' '}
                                      FM_
                                      {item.MachineModel_Name}
                                      _
                                      {item.FailureMode_ID}
                                    </p>
                                  </span>
                                  <span className="row2" style={{ textAlign: 'center', cursor: 'pointer' }} onClick={() => onDetailsClick(item.FailureMode_ID)}>{item.Severity}</span>
                                  <span className="row3" style={{ textAlign: 'center', cursor: 'pointer' }} onClick={() => onDetailsClick(item.FailureMode_ID)}>{item.MachineModel_Name}</span>
                                  <span className="row4" style={{ textAlign: 'center', cursor: 'pointer' }} onClick={() => onDetailsClick(item.FailureMode_ID)}>{item.MachineSection_Name}</span>

                                  <span className="row5" style={{ textAlign: 'center', cursor: 'pointer' }} onClick={() => onDetailsClick(item.FailureMode_ID)}>
                                    <div className="badge badge-secondary status-badge">
                                      <div
                                        className={
                                          item.Status === 'Completed'
                                            ? 'completed-badge'
                                            : 'draft-badge'
                                        }
                                      >
                                        {item.Status}
                                      </div>
                                    </div>
                                  </span>

                                  <span className="row6">

                                    <Form
                                      onSubmit={() => { }}
                                      render={({ handleSubmit, form }) => (
                                        <div className="form-group">
                                          <div className="input-control">
                                            <Tooltip
                                              PopperProps={{ className: 'MuiTooltip-popper MuiTooltip-popperInteractive toolstips' }}
                                              arrow
                                              title={(
                                                <div className="fia-desc">
                                                  {
                                              item.IsActive === false
                                                ? <span>Click to activate</span>
                                                : <span>Click to Deactivate</span>
                                            }

                                                  <br />
                                                </div>
)}
                                            >
                                              <label className="critical-label">
                                                <Field
                                                  name="Failuremode_Status"
                                                  component="input"
                                                  type="checkbox"
                                                  className="form-control"
                                                  id="Failuremode_Status"
                                                  initialValue={item.IsActive}
                                                />
                                                <OnChange name="Failuremode_Status">
                                                  {(e) => {
                                                    // page unresponsive issue fix
                                                    if (e !== item.IsActive) onActivate(item.FailureMode_ID, e);
                                                  }}
                                                </OnChange>
                                                <span className="icon" />

                                              </label>
                                            </Tooltip>

                                          </div>
                                        </div>
                                      )}
                                    />

                                    {/* <label className="switch">
                                    <input
                                      type="checkbox"
                                      checked={item.IsActive}
                                      onChange={() =>
                                        onActivate(item.FailureMode_ID, !item.IsActive)
                                      }
                                    />
                                    <span className="slider round"></span>
                                  </label> */}

                                  </span>
                                  <span className="row7 edit">
                                    <label
                                      onClick={() => onFailureModeClick(item.FailureMode_ID)}
                                      style={{ cursor: 'pointer', color: '#5d97f6' }}
                                    >
                                      {' '}
                                      {/* <Icon name="EditIcon" /> */}
                                      <Edit />
                                      {' '}
                                    </label>
                                  </span>
                                </div>
                              </h4>
                            </div>
                          </div>
                        ))
}
                    </div>
                  </div>
                </div>
              )}

              {isLoading && (
                <div className="pannelWrap overflowScrolls" id="pannelWrap">
                  <div
                    className="loaderHistoryAlarms"
                    style={{ marginTop: '100px' }}
                  >
                    <CircularIndeterminate />
                  </div>
                </div>
              )}

              {!isLoading && (listData === undefined || listData.length === 0) && (
                // <div className="noHistoryAlarms">{Lang.NoRecord}</div>
                <div className="noHistoryAlarms">No Record Available</div>
              )}
            </div>
            {(count > defaultCardCount)
              && (
              <div
                className={`
            alarm-pagination
            ${count <= 15
                  ? 'cardBelowNine'
                  : 'float-container'
                  }`}
              >
                <div className="panellefttext">
                  <div className="shownotext">
                    {` ${start} to ${end} of ${count} `}
                  </div>
                </div>
                <div className="panelrighttext">
                  <PaginationTab
                    Previous={onPrevious}
                    Next={onNext}
                    page={page}
                    maxPage={Math.ceil(count / size)}
                  />
                </div>
              </div>
              )}
          </div>
          <Drawer
            title="Failure Mode Entry"
            placement="right"
            height="100%"
            width="400px"
            closable
            visible={visible}
            className="failuremodulePage"
            closeIcon={<div className="closepopup pointer"> &#x2715;</div>}
            onClose={
              onAddEntryClose
            }
            destroyOnClose
            push={{ distance: 378 }}
          >
            <FailureModelAddComponent
              onSaveFailureMode={onSaveFailureMode}
              onAddEntryClose={onAddEntryClose}
            />
            {' '}
          </Drawer>
        </div>
      </div>
    </>
  );
}
export default FailureModeListing;
