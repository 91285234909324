import { Form } from 'react-final-form';
import React, { useState, useEffect } from 'react';
import 'antd/dist/antd.css';
import 'antd/dist/antd.dark.css';
import { Spin } from 'antd';
import { Mention, MentionsInput } from 'react-mentions';
import { defaultStyle, defaultMentionStyle } from '../mentions';
import '../../../styles/failureMode.css';

const style = defaultStyle;
const inputEl = React.createRef();

const TemplateField = ({ content, templatePlaceholder, onDataChanged }) => {
  // const onChange = (event, newValue, newPlainTextValue, mentions) => {
  const onChange = (event, newValue) => {
    onDataChanged(newValue);
  };

  const [result, setResult] = useState(true);

  useEffect(() => {
    setResult(
      templatePlaceholder.map((m) => ({
        id: m.TemplatePlaceHolder_ID,
        display: m.Name,
        subText: m.Placeholder,
      })),
    );
  }, [templatePlaceholder]);
  return (
    <Spin spinning={false}>
      <Form
        onSubmit={() => { }}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <div className="form-group formula-dropdown" id="template-field">
              <MentionsInput
                value={content}
                onChange={onChange}
                style={style}
                inputRef={inputEl}
                placeholder="Enter Content"
                a11ySuggestionsListLabel="Suggested mentions"
              >
                <Mention
                  id="template-field-popup"
                  markup="@{__display__}"
                  displayTransform={(id, display) => `${display}`}
                  // data={users}
                  // data={(search, callback) => {
                  data={(search) => {
                    if (search === '') return result;
                    return result.filter((r) => r.display.toLowerCase().indexOf(search.toLowerCase()) >= 0);
                  }}
                  style={defaultMentionStyle}
                  renderSuggestion={(suggestion, search, highlightedDisplay, index, focused) => (
                    <div key={index} className={`user ${focused ? 'focused' : ''}`}>
                      <span>{highlightedDisplay}</span>
                      <div key={index} />
                    </div>
                  )}
                />
              </MentionsInput>
            </div>
          </form>
        )}
      />
    </Spin>
  );
};

export default TemplateField;
