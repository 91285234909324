import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
// import { store, persistedStore } from "./store";

import { QueryClient, QueryClientProvider } from 'react-query';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import { PersistGate } from "redux-persist/integration/react";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { store } from './redux/store';
import 'react-toastify/dist/ReactToastify.css';

const queryClient = new QueryClient();


ReactDOM.render(
  // <React.StrictMode>
  <QueryClientProvider client={queryClient}>
    <Provider store={store}>
      {/* <PersistGate persistor={persistedStore}> */}
        <App />
      {/* </PersistGate> */}
    </Provider>
  </QueryClientProvider>,
  // </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
