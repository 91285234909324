import React, { useState, useContext, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { handleURLRouteParameter } from '../../../services/handleRoute';
/* eslint no-unused-vars:0 */
/* eslint no-nested-ternary:0 */
// import { LanguageContext } from '../../../LanguageContext';
import { UpdateNotificationGroupRoute } from '../../../helper/constants';

import CircularIndeterminate from '../../../common/CircularIndeterminate';
import PaginationTab from '../../../common/PaginationTab/PaginationTab';

// import { AuditLogContext } from "../../../context/auditLogList";
// import add from '../../../../src/images/plus.svg';
// import { getIcon } from '../../../common/Icons/getIcon';
import Icon from '../../Dashboard/Sidebar/images/Icon';

import { fetchNotificationGroupAsync, deleteNotificationGroupAsync } from '../../../redux/slices/notificationGroupSlice';

import styles from './notification-group.module.css';
import ConfirmationDialog from '../../../common/ConfirmationDialog/ConfirmationDialog';

import AppRoutes from '../../../config/api.config.json';
// import { ReactComponent as Edit } from '../../Reports/ECR/svgs/editSpoc.svg';
// import { ReactComponent as Delete } from '../../Reports/ECR/svgs/editSpoc.svg';

const NotificationGroup = () => {
  const searchBox = useRef();
  // const [dispatchAuditLog] = useContext(AuditLogContext);
  // const { setSelectedTab, setActivePage } = pc;
  const history = useHistory();
  const dispatch = useDispatch();
  // const [Lang] = useContext(LanguageContext);
  const rowsPerPage = 15;
  const defaultCardCount = rowsPerPage;
  const { NotificationGroup: NotificationGroupRoute } = AppRoutes;
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const [load, setLoad] = useState(false);
  const [startCount, setStartCount] = useState(1);
  const [totalCount, setTotalCount] = useState(defaultCardCount);
  const [data, setData] = useState(1);
  const [currentData, setCurrentData] = useState(null);
  const [showConfirmation, setConfirmation] = useState({});

  useSelector((state) => {
    const dt = state.notificationGroup.notificationGroup;
    if (dt === data) return;
    setData(dt);
    setMaxPage(Math.ceil(dt.length / defaultCardCount));
    setCurrentData(dt.slice(0, defaultCardCount));
    setStartCount(1);
    setTotalCount(defaultCardCount);
    setPage(1);
    // setLoad(true);
  });

  const Previous = (pageNumber) => {
    if (pageNumber - 1 > 0) {
      const currentList = data.slice(
        (pageNumber - 2) * defaultCardCount,
        (pageNumber - 1) * defaultCardCount,
      );
      setCurrentData(currentList);
      setPage(pageNumber - 1);
      if (pageNumber === 2) {
        setStartCount(1);
        setTotalCount(
          data.length < defaultCardCount ? data.length : defaultCardCount,
        );
      } else {
        setStartCount(startCount - defaultCardCount);
        if (pageNumber === maxPage) {
          setTotalCount(defaultCardCount - 1 - (defaultCardCount - startCount));
        } else {
          setTotalCount(totalCount - defaultCardCount);
        }
      }
    }
  };

  const Next = (pageNumber) => {
    if (pageNumber + 1 <= maxPage) {
      const currentList = data.slice(
        pageNumber * defaultCardCount,
        (pageNumber + 1) * defaultCardCount,
      );
      setCurrentData(currentList);
      setPage(pageNumber + 1);
      if (pageNumber + 1 === maxPage) {
        setStartCount(data.length - currentList.length + 1);
        setTotalCount(data.length);
      } else {
        setStartCount(startCount + defaultCardCount);
        if (data.length > defaultCardCount + totalCount) {
          setTotalCount(totalCount + defaultCardCount);
        } else setTotalCount(data.length);
      }
    }
  };

  async function fetchData() {
    if (data !== 1) return;
    // setLoad(true);
    const searchKey = searchBox ? (searchBox.current ? searchBox.current.value : '') : '';

    dispatch(fetchNotificationGroupAsync({
      id: '', searchKey, pageIndex: '', pageCount: '',
    }));
  }

  function onEditClicked(e) {
    /* eslint max-len: 0 */
    const index = parseInt(e.currentTarget.parentNode.parentNode.getAttribute('index'), 10) + startCount - 1;
    history.push({ pathname: handleURLRouteParameter(UpdateNotificationGroupRoute, ''), state: { groupData: data[index], groupList: data } });
    // setActivePage(UpdateNotificationGroupRoute);
  }

  function addEntryBtnClicked() {
    history.push({ pathname: handleURLRouteParameter(UpdateNotificationGroupRoute, ''), state: { groupData: '', groupList: data } });
    // setActivePage(UpdateNotificationGroupRoute);
  }

  function showDialog(title, text, onDone) {
    setConfirmation({
      show: true,
      title,
      text,
      onClose: () => {
        setConfirmation({ show: false });
      },
      onDone: () => {
        onDone();
        setConfirmation({ show: false });
      },
    });
  }

  function onDeleteClicked(e) {
    const { id } = e.currentTarget.parentNode.parentNode;
    const seletedEntry = data.filter((item) => item.NotificationGroup_ID === +id); // + converts string to number
    showDialog('Confirm', 'Are you sure want to delete this?', () => {
      dispatch(deleteNotificationGroupAsync(seletedEntry[0].NotificationGroup_ID));
      setTimeout(() => {
        setData(1);
        fetchData();
        history.replace(NotificationGroupRoute);
        // history.go(0);
      }, 500);
    });
  }

  let searchDelayTimer;
  function onSearchTextChanged(e) {
    const text = e.target.value;
    clearTimeout(searchDelayTimer);
    searchDelayTimer = setTimeout(() => {
      dispatch(fetchNotificationGroupAsync({
        id: '', searchKey: text, pageIndex: '', pageCount: '',
      }));
    }, 500);
  }

  fetchData();
  console.log('currentData', currentData);
  return (
    <>
      <div className={`wrapper ${styles.notiGrp}`}>
        <div className="contentWrap conditionMonitoring monitoringcontentWrap monitoringPage notification-group">
          <div className={styles.headContainer}>
            <div className="tabArea">
              <ul id="navList" className="nav nav-tabs tabsName">
                <li className="active">
                  <a data-toggle="tab">Notification Group</a>
                </li>
              </ul>
              <div className="tab-content">
                <div className="tab-pane fade in active">
                  {/* <div className="alarmBar padTopOnly row dropRow"> */}
                  <div className="alarmBar padTopOnly dropRow cmRow">
                    <div className="input-group pull-right search-container">
                      {/* <SearchIcon /> */}
                      <Icon name="searchIcon" />
                      <input
                        // className="py-2 form-control"
                        ref={searchBox}
                        type="search"
                        className="form-control customForm input-md search-box"
                        placeholder="Search by Name and Description"
                        onChange={onSearchTextChanged}
                      />
                    </div>
                    <button type="button" className="pull-right btn btn-secondary add-entry-button" onClick={addEntryBtnClicked}>
                      <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.99951 0.666504V11.3332" stroke="#5D97F6" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M0.666504 6H11.3332" stroke="#5D97F6" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>

                      Add Entry
                    </button>
                  </div>
                  <div className="alertDivsBg">
                    <div className="alertDivs">
                      <div className={`headerTable  ${styles.headerTable}`}>
                        <span className="row-0">Name</span>
                        <span className="row-1">Description</span>
                        <span className="row-2">Actions</span>
                      </div>

                      {data.length > 0 ? (
                        <div
                          className={`pannelWrap overflowScrolls common-scroll ${styles.fullHeight}`}
                          id="pannelWrap"
                        >
                          {(currentData || []).map((row, i) => (
                            <div className={`panel panel-default alarmRows ${styles.notiPanelDefault}`} key={i}>
                              <div className="panel-heading">
                                <h4 className="panel-title">
                                  <div id={row.NotificationGroup_ID} index={i}>
                                    <span className="row-0">{row.NotificationGroupName}</span>
                                    <span className="row-1">{row.Description}</span>
                                    <div className="row-2 edit">
                                      <span onClick={onEditClicked}><Icon name="EditIcon" /></span>
                                      <span onClick={onDeleteClicked}><Icon name="DeleteIcon" /></span>
                                    </div>
                                  </div>
                                </h4>
                              </div>
                            </div>
                          ))}
                        </div>
                      ) : load ? (
                        <div className="pannelWrap overflowScrolls" id="pannelWrap">
                          <div
                            className="loaderHistoryAlarms"
                          >
                            <CircularIndeterminate />
                          </div>
                        </div>
                      ) : (
                        // <div className="noHistoryAlarms">{Lang.NoRecord}</div>
                        <div className="noHistoryAlarms">No Record Available</div>
                      )}
                    </div>
                    {data.length > defaultCardCount
                        && (
                        <div className="alarm-pagination float-container">
                          <div className="panellefttext">
                            <div className="shownotext">
                              {` ${startCount} to ${totalCount} of ${data.length} `}
                            </div>
                          </div>
                          <div className="panelrighttext">
                            <PaginationTab
                              Previous={Previous}
                              Next={Next}
                              page={page}
                              maxPage={maxPage}
                            />
                          </div>
                        </div>
                        )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showConfirmation.show
            && (
            <ConfirmationDialog
              onClosed={showConfirmation.onClose}
              title={showConfirmation.title}
              text={showConfirmation.text}
              onDoneClicked={showConfirmation.onDone}
            />
            )}
    </>
  );
};

export default NotificationGroup;
