import { useState, useRef } from "react";
// import { connect } from "react-redux";
import { useDispatch } from 'react-redux';
import Logo from "./images/Logo";
import Styles from "./style.module.css";
import axios from "../../helper/axios";
import { useEffect } from "react";
import apijson from "./../../config/api.config.json";
import { Redirect, useHistory } from "react-router-dom";
import { setUser } from "../../redux/slices/userSlice";

function Login(props) {
  // const { setUser } = props;
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const history = useHistory();
  const dispatch = useDispatch();
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (email === "" && password === "") {
      setError("Email ID and Password is Required!");
    } else if (email === "") {
      setError("Email ID is Required!");
    } else if (password === "") {
      setError("Password is Required!");
    } else {
      setError("");
      axios
        .post(apijson.API_LOGIN, { email, password })
        .then((res) => {
          console.log(res.data,"kranthiiii123")
          const { token, email, firstname, lastname } = res.data;
          // setUser({
          //   user:{
          //     email,
          //     firstname,
          //     lastname,
          //   },
          // });
          dispatch(setUser({
              user:{
                  email,
                  firstname,
                  lastname,
              },
          }));
          localStorage.setItem("UserID", res.data.UserID);
          localStorage.setItem("token", res.data.token);
          localStorage.setItem("email", res.data.email);
          localStorage.setItem("firstname", res.data.firstname);
          localStorage.setItem("lastname", res.data.lastname);
          localStorage.setItem("SolarPlant", res.data.SolarPlant);
          localStorage.setItem("SlitterOEE", res.data.SlitterOEE);
          localStorage.setItem("EnergyManagement", res.data.EnergyManagement);
          setError("");
          history.replace("/home");
        })
        .catch((err) => {
          if (err.response) {
            setError("Please Enter Valid Credential");
          } else {
            setError("Internal Server Error");
          }
        });
    }
  };

  const isValidUser = (token) => {
    axios
      .post("/api/verifyToken", { token: token })
      .then((res) => {
        history.replace("/home");
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    // const token = localStorage.getItem("token");
    // if (token) {
    //   isValidUser(token, "/");
    // }
    localStorage.clear()
  }, []);
  return (
    <div className={Styles.Login}>
      <div className={Styles.loginContainer}>
        <div className={Styles.logo}>
          <Logo />
        </div>
        <div className={`${Styles.loginBox}`}>
          <div className={`${Styles.login__header}`}>Welcome</div>
          <div className={`${Styles.login__body}`}>
            {error ? (
              <div className={`${Styles.login__error}`}>{error}</div>
            ) : (
              ""
            )}

            <form className={`${Styles.login__form}`} onSubmit={handleSubmit}>
              <div className={`${Styles.login__inputControl}`}>
                <div className={`${Styles.login__fieldGroup}`}>
                  <div className={`${Styles.login__label}`}>email id</div>
                  <input
                    type="email"
                    value={email}
                    placeholder="Enter email id"
                    className={`${Styles.login__input}`}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className={`${Styles.login__fieldGroup}`}>
                  <div className={`${Styles.login__label}`}>password</div>
                  <input
                    type="password"
                    value={password}
                    placeholder="Enter password"
                    className={`${Styles.login__input}`}
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                  />
                </div>
              </div>
              {/* <div className={`${Styles.login__forgetPassword}`}>
                <a href="#">Forget Password</a>
              </div> */}
              <div className={`${Styles.login__submitControl}`}>
                <input type="submit" value="Login" />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

// const mapDispatchToProps = (dispatch) => {
//   return {
//     setUser: (data) => {
//       console.log("data",data)
//       dispatch({
//         type: "SET_USER",
//         payload: data,
//       });
//     },
//   };
// };
// export default connect(null, mapDispatchToProps)(Login);
export default Login;