import React, {
  useState, useEffect, Fragment, useContext,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';
import moment from 'moment';
import Tooltip from '@material-ui/core/Tooltip';
import { LanguageContext } from '../../../LanguageContext';
import CircularIndeterminate from '../../../common/CircularIndeterminate';
import PaginationTab from '../../../common/PaginationTab/PaginationTab';
// import CircularIndeterminate from "../../../components/dashboard/common/CircularIndeterminate";
// import PaginationTab from "../../../components/dashboard/common/PaginationTab";
import { fecthExecutionResultListAsync, saveExecutionResultAsync } from '../../../redux/slices/executionResultSlice';
import AddExecutionResultModal from './add-execution-result';
import '../Condition-Monitoring/condition-monitoring.css';

const ExecutionResultTable = ({ filterValues, page, setPage }) => {
  const defaultCardCount = 15;
  const size = defaultCardCount;
  const [start, setStart] = useState();
  const [end, setEnd] = useState();
  const [editMode, setEditMode] = useState(false);
  const dispatch = useDispatch();
  const [selectedData, setSelectedData] = useState({});
  const [updatePopup, setUpdatePopup] = useState({ value: false });
  const [search, setSearch] = useState('');

  useEffect(() => {
    dispatch(fecthExecutionResultListAsync({
      search,
      page,
      size,
      name: filterValues.MachineModel_Name || '',
      from: filterValues.From_Date ? moment(filterValues.From_Date).format('YYYY-MM-DD HH:mm:ss') : '' || '',
      to: filterValues.To_Date ? moment(filterValues.To_Date).format('YYYY-MM-DD HH:mm:ss') : '' || '',
      resolved: filterValues.resolveType || '',
    }));
  }, [search, page, size, filterValues]);

  const count = useSelector(
    (state) => state?.executionResult?.executionResultList?.count || 0,
  );

  const listData = useSelector(
    (state) => state?.executionResult?.executionResultList?.rows || [],
  );

  const isLoading = useSelector(
    (state) => state.generalConditionsView.status,
  ) === 'loading';

  useEffect(() => {
    const st = (page - 1) * size + 1;
    const en = st + (size - 1) > count ? count : st + (size - 1);
    setStart(st);
    setEnd(en);
  }, [page, size, count]);

  const onSearchChange = debounce((e) => {
    setPage(1);
    setSearch(e.target.value);
  }, 2000);

  // for language change
  // const [Lang] = useContext(LanguageContext);

  const onPrevious = (pageNumber) => {
    setPage(pageNumber - 1);
  };

  const onNext = (pageNumber) => {
    setPage(pageNumber + 1);
  };

  const onEditPopUpCloseInvoked = () => {
    setEditMode(false);
  };

  const onEditPopUpSaveInvoked = async (currentDetails, name, isResolved, content, selectedDate) => {
    const newData = {
      ExecutionResult_ID: currentDetails.ExecutionResult_ID, isResolved, ResolvedBy: name, ResolutionDescription: content, ResolvedOnTimestamp: selectedDate,
    };

    const response = await dispatch(saveExecutionResultAsync(newData));
    if (response.error && response.error.name.toLowerCase() === 'error') return { Result: false };
    setSelectedData({});
    const pageNumber = page;
    setPage(-1);
    setPage(pageNumber);
    setEditMode(false);
    return { Result: true };
  };

  const onEditClicked = async (e) => {
    const index = parseInt(e.currentTarget.parentNode.getAttribute('index'), 10);
    setSelectedData(listData[index]);
    setUpdatePopup({ value: true });
    setEditMode(true);
  };
  return (
    <>
      <div className="cm-reports-bg">
        <div className="alarmBar padTopOnly dropRow exResultRow">
          <div className="input-group pull-right search-container">
            <svg className="searchIconGlobalSvg" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path className="searchP1" fillRule="evenodd" clipRule="evenodd" d="M6.125 2.82751C4.192 2.82751 2.625 4.39452 2.625 6.32751C2.625 8.26051 4.192 9.82751 6.125 9.82751C8.058 9.82751 9.625 8.26051 9.625 6.32751C9.625 4.39452 8.058 2.82751 6.125 2.82751ZM1.625 6.32751C1.625 3.84223 3.63972 1.82751 6.125 1.82751C8.61028 1.82751 10.625 3.84223 10.625 6.32751C10.625 8.8128 8.61028 10.8275 6.125 10.8275C3.63972 10.8275 1.625 8.8128 1.625 6.32751Z" fill="#b7b7b7" />
              <path className="searchP2" fillRule="evenodd" clipRule="evenodd" d="M8.5964 8.79896C8.79166 8.6037 9.10824 8.6037 9.3035 8.79896L11.4785 10.974C11.6738 11.1692 11.6738 11.4858 11.4785 11.6811C11.2832 11.8763 10.9667 11.8763 10.7714 11.6811L8.5964 9.50607C8.40114 9.3108 8.40114 8.99422 8.5964 8.79896Z" fill="#b7b7b7" />
            </svg>
            <input
              className="form-control  customForm input-md search-box py-2"
              type="search"
              placeholder="Search..."
              onChange={(e) => onSearchChange(e.nativeEvent)}
            />
          </div>
        </div>
        <div className="alertDivsBg">
          <div className="alertDivs">
            <div className="headerTable">
              {/* <span className="row1">{Lang.Machine_ID}</span>
              <span className="row2">{Lang.ConditionId}</span>
              <span className="row3">{Lang.ConditionDescription}</span>
              <span className="row4">{Lang.FormulaDescription}</span> */}
              <span className="row1">Machine ID</span>
              <span className="row2">ID</span>
              <span className="row3">Condition Description</span>
              <span className="row4">Formula Description</span>
              {/* <span className="row5">{Lang.FormulaSubstitutedValue}</span> */}
              {/* <span className="row6">{Lang.TimeStamp}</span>
              <span className="row7">{Lang.isResolved}</span> */}
              <span className="row6">Time Stamp</span>
              <span className="row7">Is Resolved</span>
            </div>
            {!isLoading && listData && listData.length > 0 && (
            <div
              className="pannelWrap overflowScrolls common-scroll"
              id="pannelWrap"
            >
              <div className="bs-example">
                <div className="panel-group" id="accordion1">
                  {
                                            listData.map((item, i) => (
                                              <div className="panel panel-default alarmRows" key={i}>
                                                <div className="panel-heading">
                                                  <h4 className="panel-title">
                                                    <div index={i}>
                                                      <Tooltip
                                                        className="conditional-monitoring"
                                                        title={(
                                                          <div className="fia-desc">
                                                            <span>{item.Machine_ID}</span>

                                                          </div>
                                                                    )}
                                                        PopperProps={{ className: 'MuiTooltip-popper MuiTooltip-popperInteractive toolstips' }}
                                                        arrow
                                                      >
                                                        <span className="row1">{item.Machine_ID}</span>
                                                      </Tooltip>

                                                      <Tooltip
                                                        className="conditional-monitoring"
                                                        title={(
                                                          <div className="fia-desc">
                                                            <span>{item.Condition_ID}</span>

                                                          </div>
                                                                    )}
                                                        PopperProps={{ className: 'MuiTooltip-popper MuiTooltip-popperInteractive toolstips' }}
                                                        arrow
                                                      >
                                                        <span className="row2">{item.Condition_ID}</span>
                                                      </Tooltip>

                                                      <Tooltip
                                                        className="conditional-monitoring"
                                                        title={(
                                                          <div className="fia-desc">
                                                            <span>{item.ConditionDescription}</span>

                                                          </div>
                                                                    )}
                                                        PopperProps={{ className: 'MuiTooltip-popper MuiTooltip-popperInteractive toolstips' }}
                                                        arrow
                                                      >
                                                        <span className="row3">{item.ConditionDescription}</span>
                                                      </Tooltip>

                                                      <Tooltip
                                                        className="conditional-monitoring"
                                                        title={(
                                                          <div className="fia-desc">
                                                            <span>{item.Formula_Description}</span>

                                                          </div>
                                                                    )}
                                                        PopperProps={{ className: 'MuiTooltip-popper MuiTooltip-popperInteractive toolstips' }}
                                                        arrow
                                                      >
                                                        <span className="row4">{item.Formula_Description}</span>
                                                      </Tooltip>

                                                      {/* <Tooltip className="conditional-monitoring" title={<div className="fia-desc"

                                                                >
                                                                    <span>{item.FormulaSubstitutedValue}</span>

                                                                </div>} PopperProps={{ className: "MuiTooltip-popper MuiTooltip-popperInteractive toolstips" }}
                                                                    arrow={true}

                                                                >
                                                                   <span className="row5">{item.FormulaSubstitutedValue}</span>
                                                                </Tooltip> */}

                                                      <span className="row6">{new Date(item.Timestamp).toISOString([]).split(',')}</span>

                                                      <Tooltip
                                                        className="conditional-monitoring"
                                                        title={(
                                                          <div className="fia-desc">
                                                            <span>Click here to edit</span>

                                                          </div>
                                                                    )}
                                                        PopperProps={{ className: 'MuiTooltip-popper MuiTooltip-popperInteractive toolstips' }}
                                                        arrow
                                                      >
                                                        <span className="row7" onClick={onEditClicked}>{item.isResolved === true ? 'Yes' : 'No'}</span>
                                                      </Tooltip>

                                                    </div>
                                                  </h4>
                                                </div>
                                              </div>
                                            ))
                                        }
                </div>
              </div>
            </div>
            )}

            {isLoading && (
            <div className="pannelWrap overflowScrolls" id="pannelWrap">
              <div
                className="loaderHistoryAlarms"
                style={{ marginTop: '100px' }}
              >
                <CircularIndeterminate />
              </div>
            </div>
            )}

            {!isLoading && (listData === undefined || listData.length === 0) && (
            // <div className="noHistoryAlarms">{Lang.NoRecord}</div>
              <div className="noHistoryAlarms">No Record Available</div>
            )}
          </div>

          <div
            className={`
            alarm-pagination exepaginations
            ${count <= defaultCardCount
              ? 'cardBelowNine'
              : 'float-container'
                            }`}
          >
            <div className="panellefttext">
              <div className="shownotext">
                {` ${start} to ${end} of ${count} `}
              </div>
            </div>
            <div className="panelrighttext">
              <PaginationTab
                Previous={onPrevious}
                Next={onNext}
                page={page}
                maxPage={Math.ceil(count / size)}
              />
            </div>
          </div>
        </div>
      </div>
      {
                editMode
                && <AddExecutionResultModal currentDetails={selectedData} onSaved={onEditPopUpSaveInvoked} onClosed={onEditPopUpCloseInvoked} needsUpdate={updatePopup} />
            }
    </>
  );
};

export default ExecutionResultTable;
