// import axios from 'axios';
// import { APIUrl } from "../../../configs/config";
import { APIUrl } from '../../../helper/constants';
import CallAPI from '../../../services/conditionMonitoring.service';

// import authHeader from '../../../config/auth.header';

// const headers = {
//   headers: authHeader(),
// };

// A mock function to mimic making an async request for data
export function getFailureNotifications(page = 1, size = 10, search, sortName, sortDirection) {
  const apiInfo = {
    url: `${APIUrl}/api/getFailureNotifications?page=${page}&size=${size}&search=${search}
         &sortName=${sortName}&sortDirection=${sortDirection}`,
    method: 'GET',
    isMultiPart: false,
  };
  return CallAPI(apiInfo).then((res) => res.data);

  // return axios
  //   .get(
  //     `${APIUrl}/api/getFailureNotifications?page=${page}&size=${size}&search=${search}
  //     &sortName=${sortName}&sortDirection=${sortDirection}`, headers,
  //   )
  //   .then((res) => res.data);
}

export function saveActivateFailureNotification(id, status) {
  const apiInfo = {
    url: `${APIUrl}/api/saveActivateFailureNotification`,
    method: 'POST',
    postData: { FailureNotification_ID: id, IsActive: status },
    isMultiPart: false,
  };
  return CallAPI(apiInfo).then((res) => res.data);

  // return axios
  //   .post(`${APIUrl}/api/saveActivateFailureNotification`, {
  //     // GeneralCondition_ID: id,
  //     FailureNotification_ID: id,
  //     IsActive: status,
  //   }, headers);
  // .then((res) => console.log("Status updated"));
}
