import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  // DataFetched: false,
  // Menus: {},
  // Reports: {},
  // ReportCodes: [],
  // DefaultRoute: '/',
  // RoleName: null,
  user: {},
};
/* eslint  no-param-reassign:0 */
export const userManagement = createSlice({
  name: 'userManagement',
  initialState,
  reducers: {
    // setMenuAndReport: (state, action) => {
    //   const {
    //     Menus, Reports, ReportCodes, RoleName,
    //   } = action.payload;
    //   state.Menus = {
    //     ...Menus,
    //   };
    //   state.Reports = Reports;
    //   state.ReportCodes = ReportCodes;
    //   state.DataFetched = true;
    //   state.RoleName = RoleName;
    // },
    setUser : (state, action) => {
      console.log('actions', action);
      return  {
        ...state,
        user:action.payload.user,
      }
    }    
  },
});
    
// export const { setMenuAndReport, resetState, setDefaultRoute } = userManagement.actions;
export const { setUser } = userManagement.actions;

export default userManagement.reducer;
