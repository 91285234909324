// import axios from 'axios';
// import { user_id, APIUrl } from '../../configs/config';
import { APIUrl } from '../../../../helper/constants';
// import authHeader from '../../../../config/auth.header';
import CallAPI from '../../../../services/conditionMonitoring.service';
// const headers = authHeader();

// A mock function to mimic making an async request for data
export function viewFailureIndicators(id) {
  const apiInfo = {
    url: `${APIUrl}/api/getFailureIndicators?failureModeId=${id}`,
    method: 'GET',
    isMultiPart: false,
  };
  return CallAPI(apiInfo).then((res) => res.data);
  // return axios
  //   .get(`${APIUrl}/api/getFailureIndicators?failureModeId=${id}`, { headers })
  //   .then((res) => res.data);
}

export function deleteFailureIndicator(failureIndicatorId) {
  // failureIndicator.CreatedUser_ID=user_id;
  const apiInfo = {
    url: `${APIUrl}/api/deleteFailureIndicator?failureIndicatorId=${failureIndicatorId}`,
    method: 'DELETE',
    isMultiPart: false,
  };
  return CallAPI(apiInfo).then((res) => res.data);
  // return axios
  //   .delete(
  //     `${APIUrl}/api/deleteFailureIndicator?failureIndicatorId=${failureIndicatorId}`, { headers },
  //   )
  //   .then((res) => res.data);
}
