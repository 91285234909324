import { memo, useEffect, useRef } from "react";
// import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import UserIcon from "./images/UserIcon";
import LogoutIcon from "./images/LogoutIcon";
import { useState } from "react";
import Styles from "./style.module.css";
import Logo from "./images/Logo";
function Header(props) {
  const firstname = localStorage.getItem("firstname");
  const lastname = localStorage.getItem("lastname");
  const email = localStorage.getItem("email");
  const tile = firstname[0] + lastname[0];
  // const tile = "kk";
  const history = useHistory();
  // const pageName = history.location.pathname.split("/")[1];
  const pageNameArr = history.location.pathname.split("/");
  console.log('pathName', history.location.pathname);
  // console.log('pageName', pageName);

  const title = getTitle(pageNameArr);

  // const pageName = history.location.pathname.split('/')[1];
  // const subPageName = history.location.pathname.split('/').length > 3 ? history.location.pathname.split('/')[2] : '';
  // const subTitle = subPageName ? subPageName.split('-').join(' ') : '';

  const [isShowPanel, setIsShowPanel] = useState(false);
  const profileInfo = useRef();

  const togglePanel = (e) => {
    setIsShowPanel(!isShowPanel);
  };
  const handleDocumentClick = (e) => {
    if (profileInfo.current.contains(e.target) === false) {
      setIsShowPanel(false);
    }
  };
  const logout = (e) => {
    // setIsShowPanel(false)
    localStorage.clear();
    // <Redirect to="/" />
    history.replace("/");
  };

  useEffect(() => {
    document.addEventListener("click", handleDocumentClick);
    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  // function getTitle(pagename){
  //   switch(pageName){
  //     case "slitter": return "Films and Foils, Slitter OEE";
  //     case "en1m": return "Films and Foils, Energy Monitoring System";
  //     case "NotificationGroup": return "Notification Group";
  //     case "NotificationTemplate": return "Notification Template";
  //     case "ConditionMasters/General-Conditions": return "Condition Monitoring";
  //     case "ExecutionResult": return "Execution Result";
  //     case "AlertNotificationLog": return "Alert/NotificationLog";
  //     default: return "Solar Power Plant Dashboard, Tuljapur";
  //   }
  // }
  function getTitle(pageNameArr){
    let pageNames = pageNameArr.filter(Boolean);

    if(pageNames.length === 3){
      return `${getPageName(pageNames[0])} > ${getPageName(pageNames[2])}`;
    } else if(pageNames.length === 2) {
      if(pageNames[1].toLowerCase().includes('details')){
        return `${getPageName(pageNames[0])} > ${getPageName(pageNames[1])}`;  
      } 
      return getPageName(pageNames[0]);
    } else {
      return getPageName(pageNames[0]);
    }
  }
  function getPageName(routeName){
    let headerNameObj = {
      "slitter": "Films and Foils, Slitter OEE",
      "en1m": "Films and Foils, Energy Monitoring System",
      "NotificationGroup": "Notification Group",
      "Details": "Details",
      "NotificationTemplate": "Notification Template",
      "ConditionMasters": "Condition Monitoring",
      "Add-General-Condition": "General Condition",
      "Add-Failure-Notification": "Failure Conditions",
      "Add-Maintenance-Masters": "Maintenance Masters",
      "ExecutionResult": "Execution Result",
      "AlertNotificationLog": "Alert/NotificationLog",
      "FMEA": "FMEA Repository"
    }
    return headerNameObj[routeName] ? headerNameObj[routeName] : "Solar Power Plant Dashboard, Tuljapur";
  }
  return (
    <header className={`${Styles.header}`}>
      <div className={`${Styles.header__left}`}>
        <div className={`${Styles.header__logo}`}>
          <Logo />
        </div>
        <div className={`${Styles.header__pageInfo}`}>
          {title}
        </div>
      </div>
      <div className={`${Styles.profileInfo}`} ref={profileInfo}>
        <div
          className={`${Styles.profileInfo__ShortName} ${
            isShowPanel ? `${Styles.active}` : ""
          }`}
          onClick={togglePanel}
        >
          {tile}
        </div>
        <div
          className={`${Styles.profileInfo__panel} ${
            isShowPanel ? `${Styles.showPanel}` : ""
          }`}
        >
          <div className={`${Styles.profileInfo__infoContainer}`}>
            <div className={`${Styles.profileInfo__icon}`}>
              <UserIcon />
            </div>
            <div className={`${Styles.profileInfo__info}`}>
              <span className={`${Styles.profileInfo__infoLabel}`}>
                {firstname} {lastname}
              </span>
              <span className={`${Styles.profileInfo__email}`}>{email}</span>
            </div>
          </div>
          <div
            className={`${Styles.profileInfo__infoContainer} ${Styles.profileInfo__logout}`}
            onClick={logout}
          >
            <div className={`${Styles.profileInfo__icon}`}>
              <LogoutIcon />
            </div>
            <div className={`${Styles.profileInfo__info}`}>
              <span className={`${Styles.profileInfo__infoLabel}`}>Logout</span>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}
// const maptStateToProps = (state) => {
//   return {
//     user: state.use.user,
//   };
// };
export default Header;
