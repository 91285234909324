import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { detailsOfFailureModes, editFailureModes, getPotentialCauses } from '../../components/ConditionMonitoring/FMEA/failure-mode-details/failureModeDetailsAPI';

const initialState = {
  failureModeFullDetails: {},
  status: 'idle',
  editFailureMode: {},
  editFailureModeStatus: 'idle',
  editPotentialCauseId: undefined,
  editCausalParameterId: undefined,
  potentialCauseDetails: {},
  potentialCauseDetailsStatus: 'idle',
};

export const getPotentialCausesAsync = createAsyncThunk(
  'potentialCauseDetails/getPotentialCauses',
  async () => {
    const response = await getPotentialCauses();
    return response;
  },
);

export const detailsOfFailureModesAsync = createAsyncThunk(
  'failureModeDetails/detailsOfFailureModes',
  async ({ search }) => {
    const response = await detailsOfFailureModes(search);
    return response;
  },
);

export const editFailureModesAsync = createAsyncThunk(
  'failureModeDetailsEdit/editFailureModes',
  async ({ id }) => {
    const response = await editFailureModes(id);
    return response;
  },
);

export const failureModeDetailsSlice = createSlice({
  name: 'failureModeDetails',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    clearEdit: (state) => {
      state.editFailureMode = {};
    },
    setEditDetails: (state, action) => {
      state.editFailureMode = action.payload;
    },
    setFailureIndicator: (state, action) => {
      const existingIndicator = state.editFailureMode.failureindicators
        && state.editFailureMode.failureindicators.findIndex(
          (i) => i.FailureIndicator_ID === action.payload.FailureIndicator_ID,
        );

      if (existingIndicator >= 0) {
        state.editFailureMode.failureindicators[existingIndicator] = action.payload;
      } else if (state.editFailureMode.failureindicators) {
        state.editFailureMode.failureindicators.push(action.payload);
      } else {
        state.editFailureMode.failureindicators = [action.payload];
      }
    },
    removeFailureIndicator: (state, action) => {
      const existingIndicator = state.editFailureMode.failureindicators.findIndex(
        (i) => i.FailureIndicator_ID === action.payload,
      );
      if (existingIndicator >= 0) {
        state.editFailureMode.failureindicators.splice(existingIndicator, 1);
      }
    },

    setCausalParameter: (state, action) => {
      const potentialCauseIndex = state.editFailureMode.potentialcauses.findIndex(
        (p) => p.PotentialCause_ID === action.payload.PotentialCause_ID,
      );
      state.editCausalParameterId = action.payload.CausalParameter_ID;
      if (potentialCauseIndex >= 0) {
        const causalParameterIndex = state.editFailureMode.potentialcauses[
          potentialCauseIndex
        ].causualparameters && state.editFailureMode.potentialcauses[
          potentialCauseIndex
        ].causualparameters.findIndex(
          (i) => i.CausalParameter_ID === action.payload.CausalParameter_ID,
        );

        if (causalParameterIndex >= 0) {
          state.editFailureMode.potentialcauses[
            potentialCauseIndex
          ].causualparameters[causalParameterIndex] = action.payload;
        } else if (state.editFailureMode.potentialcauses[
          potentialCauseIndex
        ].causualparameters) {
          state.editFailureMode.potentialcauses[
            potentialCauseIndex
          ].causualparameters.push(action.payload);
        } else {
          state.editFailureMode.potentialcauses[
            potentialCauseIndex
          ].causualparameters = [action.payload];
        }
      }
    },
    removeCausualParameter: (state, action) => {
      const potentialCauseIndex = state.editFailureMode.potentialcauses.findIndex(
        (p) => p.PotentialCause_ID === action.payload.PotentialCause_ID,
      );
      if (potentialCauseIndex >= 0) {
        const causalParameterIndex = state.editFailureMode.potentialcauses[
          potentialCauseIndex
        ].causualparameters.findIndex(
          (i) => i.CausalParameter_ID === action.payload.CausalParameter_ID,
        );

        if (causalParameterIndex >= 0) {
          state.editFailureMode.potentialcauses[
            potentialCauseIndex
          ].causualparameters.splice(causalParameterIndex, 1);
        }
      }
    },

    setEditCausalParameter: (state, action) => {
      state.editCausalParameterId = action.payload;
    },

    setPotentialCause: (state, action) => {
      const existingPotentialCause = state.editFailureMode.potentialcauses
        && state.editFailureMode.potentialcauses.findIndex(
          (i) => i.PotentialCause_ID === action.payload.PotentialCause_ID,
        );
      state.editPotentialCauseId = action.payload.PotentialCause_ID;
      if (existingPotentialCause >= 0) {
        state.editFailureMode.potentialcauses[existingPotentialCause] = action.payload;
      } else if (state.editFailureMode.potentialcauses) {
        state.editFailureMode.potentialcauses.push(action.payload);
      } else {
        state.editFailureMode.potentialcauses = [action.payload];
      }
    },
    setEditPotentialCause: (state, action) => {
      state.editPotentialCauseId = action.payload;
    },
    setRecommendedAction: (state, action) => {
      const potentialCauseIndex = state.editFailureMode.potentialcauses
        && state.editFailureMode.potentialcauses.findIndex(
          (i) => i.PotentialCause_ID === action.payload.PotentialCause_ID,
        );
      if (potentialCauseIndex >= 0) {
        const recommendActionIndex = state.editFailureMode.potentialcauses[
          potentialCauseIndex
        ].recommendedactions && state.editFailureMode.potentialcauses[
          potentialCauseIndex
        ].recommendedactions.findIndex(
          (i) => i.RecommendedAction_ID === action.payload.RecommendedAction_ID,
        );
        if (recommendActionIndex >= 0) {
          state.editFailureMode.potentialcauses[
            potentialCauseIndex
          ].recommendedactions[recommendActionIndex] = action.payload;
        } else if (state.editFailureMode.potentialcauses[
          potentialCauseIndex
        ].recommendedactions) {
          state.editFailureMode.potentialcauses[
            potentialCauseIndex
          ].recommendedactions.push(action.payload);
        } else {
          state.editFailureMode.potentialcauses[
            potentialCauseIndex
          ].recommendedactions = [action.payload];
        }
      }
    },
    removeRecommendedAction: (state, action) => {
      const potentialCauseIndex = state.editFailureMode.potentialcauses.findIndex(
        (p) => p.PotentialCause_ID === action.payload.PotentialCause_ID,
      );
      if (potentialCauseIndex >= 0) {
        const recommendActionIndex = state.editFailureMode.potentialcauses[
          potentialCauseIndex
        ].recommendedactions.findIndex(
          (i) => i.RecommendedAction_ID === action.payload.RecommendedAction_ID,
        );

        if (recommendActionIndex >= 0) {
          state.editFailureMode.potentialcauses[
            potentialCauseIndex
          ].recommendedactions.splice(recommendActionIndex, 1);
        }
      }
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(detailsOfFailureModesAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(detailsOfFailureModesAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.failureModeFullDetails = action.payload;
      })
      // .addCase(detailsOfFailureModesAsync.rejected, (state, action) => {
      .addCase(detailsOfFailureModesAsync.rejected, (state) => {
        state.status = 'idle';
      })

      .addCase(getPotentialCausesAsync.pending, (state) => {
        state.potentialCauseDetailsStatus = 'loading';
      })
      .addCase(getPotentialCausesAsync.fulfilled, (state, action) => {
        state.potentialCauseDetailsStatus = 'idle';
        state.potentialCauseDetails = action.payload;
      })

      .addCase(editFailureModesAsync.pending, (state) => {
        state.editFailureModeStatus = 'loading';
      })
      .addCase(editFailureModesAsync.fulfilled, (state, action) => {
        state.editFailureModeStatus = 'idle';
        state.editFailureMode = action.payload;
      })
      // .addCase(editFailureModesAsync.rejected, (state, action) => {
      .addCase(editFailureModesAsync.rejected, (state) => {
        state.editFailureModeStatus = 'idle';
      });
  },
});

export const {
  clearEdit,
  setEditDetails,
  setFailureIndicator,
  removeFailureIndicator,
  setCausalParameter,
  setEditCausalParameter,
  removeCausualParameter,
  setPotentialCause,
  setEditPotentialCause,
  setRecommendedAction,
  removeRecommendedAction,
} = failureModeDetailsSlice.actions;
export default failureModeDetailsSlice.reducer;
