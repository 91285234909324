import React, {
  useState, useContext, useEffect,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { handleURLRouteParameter } from '../../../../services/handleRoute';
import { LanguageContext } from '../../../../LanguageContext';
import {
  UpdateNotificationGroupRoute, Loading, Idle, NotificationGroupRoute,
} from '../../../../helper/constants';

import CircularIndeterminate from '../../../../common/CircularIndeterminate';
import PaginationTab from '../../../../common/PaginationTab/PaginationTab';
import {
  fetchNotificationGroupDetailsAsync, saveNotificationGroupAsync, saveNotificationGroupDetailsAsync, deleteNotificationGroupDetailsAsync, fetchRoleAsync, replaceRolesAsync, deleteRolesInNotificationGroupDetailsAsync,
} from '../../../../redux/slices/notificationGroupDetailsSlice';
import styles from './update-notification-group.module.css';
import EditGroupDetailsModal from '../popup/EditGroupDetailsModal';
import ConfirmationDialog from '../../../../common/ConfirmationDialog/ConfirmationDialog';
import Icon from '../../../Dashboard/Sidebar/images/Icon';
import AppRoutes from '../../../../config/api.config.json';

/* eslint max-len:0 */
/* eslint react/jsx-no-bind:0 */
/* eslint no-nested-ternary:0 */
/* eslint no-unused-expressions:0 */
/* eslint no-shadow:0 */

const NotificationGroup = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  // const [Lang] = useContext(LanguageContext);
  // const Lang = 'English';
  // const { setActivePage } = pc;
  const { NotificationGroup } = AppRoutes;
  const rowsPerPage = 15;
  const defaultCardCount = rowsPerPage;

  const [expanded, setExpanded] = useState('general');
  const [selectedData, setSelectedData] = useState({});
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const [load, setLoad] = useState(true);
  const [startCount, setStartCount] = useState(1);
  const [totalCount, setTotalCount] = useState(defaultCardCount);
  const [editModel, setEditModel] = useState(0);
  const [needsUpdate, setNeedsUpdate] = useState({ value: true });
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [lastSaveStaus, setlastSaveStaus] = useState('Idle');
  const [lastDeleteStatus, setlastDeleteStaus] = useState('Idle');
  const [lastGrpSaveState, setlastSaveState] = useState('Idle');
  const [errorFields, setErrorFields] = useState([]);
  const [isActualData, setIsActualData] = useState(false);
  const [errorTxt, setErrorTxt] = useState('');

  const [currentGroupData, setCurrentGrpData] = useState({});
  const [groupList, setCurrentGrpList] = useState({});
  const [roles, setRoles] = useState([]);
  // const [selectedRoles, setSelectedRoles] = useState([]);
  // const [setSelectedRoles] = useState([]);

  const [data, setData] = useState(1);
  const [currentData, setCurrentData] = useState([]);

  const [showConfirmation, setConfirmation] = useState({});

  function groupDetailsListPreparation(grpDtList) {
    const newList = [];
    const rolesValues = {};
    roles.forEach((e) => { rolesValues[e.Role_ID] = e.Role_Description; });
    let role = { Type: 'R', Role: '' };
    const length = 0;
    for (let i = 0; i < grpDtList.length; i += 1) {
      if (grpDtList[i].Type === 'U') newList.push(grpDtList[i]);
      else {
        if (length === 0) {
          role.NotificationGroupDetail_ID = grpDtList[i].NotificationGroupDetail_ID;
          role.NotificationGroup_ID = grpDtList[i].NotificationGroup_ID;
          role.Role = rolesValues[grpDtList[i].Role_ID];
          newList.push(role);
          role = {};
        } else if (length > 2) role.Role += '...';
        else if (length <= 2) role.Role += `, ${rolesValues[grpDtList[i].Role_ID]}`;
        length + 1;
      }
    }
    return newList;
  }

  useSelector((state) => {
    const ngSlice = state.notificationGroupDetails;
    const dt = ngSlice.notificationGroupDetails;
    const grpData = ngSlice.notificationGroup;
    if (roles.length !== ngSlice.roles.length) {
      setRoles(ngSlice.roles);
    }

    if (ngSlice.deleteStatus !== lastDeleteStatus) {
      setlastDeleteStaus(ngSlice.deleteStatus);
      history.replace({ pathname: handleURLRouteParameter(UpdateNotificationGroupRoute, ''), state: { groupData: location.state.groupData, groupList: location.state.groupList } });
    }

    if (ngSlice.notificationGroupDetailsSaveStatus !== lastSaveStaus) {
      setlastSaveStaus(ngSlice.notificationGroupDetailsSaveStatus);
      history.replace({ pathname: handleURLRouteParameter(UpdateNotificationGroupRoute, ''), state: { groupData: location.state.groupData, groupList: location.state.groupList } });
    }
    if (lastGrpSaveState === Loading && ngSlice.notificationGroupSaveStatus === Idle) {
      const currentGroupData = location.state.groupData;
      if (!grpData.NotificationGroup_ID && currentGroupData && currentGroupData.NotificationGroup_ID) {
        history.replace({ pathname: handleURLRouteParameter(UpdateNotificationGroupRoute, ''), state: { groupData: currentGroupData, groupList: location.state.groupList } });
      } else if (grpData.NotificationGroup_ID) history.replace({ pathname: handleURLRouteParameter(UpdateNotificationGroupRoute, ''), state: { groupData: grpData, groupList: location.state.groupList } });
      setlastSaveState(Idle);
    }

    if (dt === data) return;
    setIsActualData(dt.length > 0 && currentGroupData && dt[0].NotificationGroup_ID === currentGroupData.NotificationGroup_ID);
    setData(dt);
    setMaxPage(Math.ceil(dt.length / defaultCardCount));
    const modifiedData = groupDetailsListPreparation(dt);
    setCurrentData(modifiedData.slice(0, defaultCardCount));
    setLoad(false);
  });
  useEffect(() => {
    if (!location.state) {
      history.replace(handleURLRouteParameter(NotificationGroupRoute, NotificationGroupRoute));
      return;
    }
    const grpData = location.state.groupData;
    setName(grpData.NotificationGroupName);
    setDescription(grpData.Description);
    setCurrentGrpData(grpData);
    setCurrentGrpList(location.state.groupList);
    if (!grpData.NotificationGroup_ID) setData([]);
    else setData(1);
  }, [location]);

  const Previous = (pageNumber) => {
    if (pageNumber - 1 > 0) {
      const currentList = data.slice(
        (pageNumber - 2) * defaultCardCount,
        (pageNumber - 1) * defaultCardCount,
      );
      setCurrentData(currentList);
      setPage(pageNumber - 1);
      if (pageNumber === 2) {
        setStartCount(1);
        setTotalCount(
          data.length < defaultCardCount ? data.length : defaultCardCount,
        );
      } else {
        setStartCount(startCount - defaultCardCount);
        if (pageNumber === maxPage) {
          setTotalCount(defaultCardCount - 1 - (defaultCardCount - startCount));
        } else {
          setTotalCount(totalCount - defaultCardCount);
        }
      }
    }
  };

  const Next = (pageNumber) => {
    if (pageNumber + 1 <= maxPage) {
      const currentList = data.slice(
        pageNumber * defaultCardCount,
        (pageNumber + 1) * defaultCardCount,
      );
      setCurrentData(currentList);
      setPage(pageNumber + 1);
      if (pageNumber + 1 === maxPage) {
        setStartCount(data.length - currentList.length + 1);
        setTotalCount(data.length);
      } else {
        setStartCount(startCount + defaultCardCount);
        if (data.length > defaultCardCount + totalCount) {
          setTotalCount(totalCount + defaultCardCount);
        } else setTotalCount(data.length);
      }
    }
  };

  async function FetchData() {
    if (data !== 1 || !currentGroupData.NotificationGroup_ID) {
      // if(data === 1 || data !== []) setData([]);
      return;
    }
    setLoad(true);
    dispatch(fetchRoleAsync());
    dispatch(fetchNotificationGroupDetailsAsync({
      id: currentGroupData.NotificationGroup_ID, searchKey: '', pageIndex: '', pageCount: '',
    }));
  }

  function onEditClicked(e) {
    const index = parseInt(e.currentTarget.parentNode.parentNode.getAttribute('index'), 10) + startCount - 1;
    // let indexString = e.target.parentNode.parentNode.getAttribute("index");
    // let index = parseInt(indexString, 10);
    setSelectedData(data[index]);
    setNeedsUpdate({ value: true });
    setEditModel(true);
  }

  // function onEditClicked(e) {
  //     let index = parseInt(e.currentTarget.parentNode.parentNode.getAttribute("index"), 10) + startCount - 1;
  //     setSelectedData(data[index]);
  //     setUpdatePopup({value:true});
  //     showEditMode();
  // }

  function showDialog(title, text, onDone) {
    setConfirmation({
      show: true,
      title,
      text,
      onClose: () => {
        setConfirmation({ show: false });
      },
      onDone: () => {
        onDone();
        setConfirmation({ show: false });
      },
    });
  }

  const rolesValues = {};
  roles.forEach((e) => { rolesValues[e.Role_ID] = e.Role_Description; });
  function onDeleteClicked(e) {
    const { id } = e.currentTarget.parentNode.parentNode;
    const seletedEntry = data.filter((e) => e.NotificationGroupDetail_ID === +id);
    showDialog('Confirm', 'Are you sure want to delete this?', () => {
      if (seletedEntry[0].Type === 'U') dispatch(deleteNotificationGroupDetailsAsync(seletedEntry[0].NotificationGroupDetail_ID));
      else dispatch(deleteRolesInNotificationGroupDetailsAsync(seletedEntry[0].NotificationGroupDetail_ID));
      setlastDeleteStaus('loading');
    });
  }

  function addEntryBtnClicked() {
    setSelectedData({});
    setNeedsUpdate({ value: true });
    setEditModel(true);
  }

  /**
     * The following function will call after save button clicked in the EditGroupModal popup
     * @author Tamil Prakash
     * @param {NotificationGroupDetails} actualData - Current selected data in EditGroupModal popup if its null the entry is new
     * @param {String} type - "R" || "U"
     * @param {String} || {Role[]}name - if Type is "R" this will be type of Role[] else it will be the contact name
     * @param {String} email - Contact Email
     * @param {String} phoneNumber - Contact Phone Number
     * @retrun {Result : true, Message : ""}  Status with error message
     * * */
  async function OnGroupDetailSaveBtnClicked(actualData, type, name, email, phoneNumber) {
    let response = null;
    if (type === 'R') {
      if (actualData.NotificationGroupDetail_ID && actualData.Type === 'U') response = await dispatch(deleteNotificationGroupDetailsAsync(actualData.NotificationGroupDetail_ID));
      response = await dispatch(replaceRolesAsync(name.map((e) => ({
        NotificationGroupDetail_ID: actualData.NotificationGroupDetail_ID, NotificationGroup_ID: currentGroupData.NotificationGroup_ID, Type: type, Role_ID: e.Role_ID,
      }))));
    } else {
      if (data.filter((d) => (d.EmailID && d.EmailID.toLowerCase() === email.toLowerCase())).length > (actualData.NotificationGroupDetail_ID ? 1 : 0)) return { Result: false, Message: 'Email already exist' };
      if (phoneNumber && data.filter((d) => (d.PhoneNumber && d.PhoneNumber === phoneNumber)).length > (actualData.NotificationGroupDetail_ID ? 1 : 0)) return { Result: false, Message: 'Phone Number already exist' };
      // if (!actualData.NotificationGroupDetail_ID) {
      //     if (data.map(d => d.EmailID).indexOf(email) !== -1) return { Result: false, Message: "Email already exist" };
      //     if (phoneNumber && data.map(d => d.PhoneNumber).indexOf(phoneNumber) !== -1) return { Result: false, Message: "Phone Number already exist" };
      // }
      response = await dispatch(saveNotificationGroupDetailsAsync({
        NotificationGroupDetail_ID: actualData.NotificationGroupDetail_ID, NotificationGroup_ID: currentGroupData.NotificationGroup_ID, Type: type, Name: name, EmailID: email, PhoneNumber: phoneNumber,
      }));
    }
    if (response && response.error && response.error.name.toLowerCase() === 'error') return { Result: false };
    setEditModel(false);
    setlastSaveStaus(Loading);
    return { Result: true };
  }

  function OnNameChanged(e) {
    setName(e.target.value);
    setErrorTxt('');
    if (e.target.value && errorFields.indexOf('name') !== -1) setErrorFields([]);
  }

  function OnDescriptionChanged(e) {
    setDescription(e.target.value);
  }

  function onSaveBtnClicked() {
    if (!name) {
      setErrorFields(['name']);
      // message.warning("Name is madatory");
      return;
    }
    const filteredData = groupList.filter((e) => e.NotificationGroupName.toLowerCase() === name.toLowerCase());
    if (filteredData.length > 0 && (!currentGroupData.NotificationGroup_ID || filteredData[0].NotificationGroup_ID !== currentGroupData.NotificationGroup_ID)) {
      setErrorFields(['name']);
      setErrorTxt('Name already exist');
      return;
    }
    setErrorTxt('');
    if (currentGroupData.Description !== description || currentGroupData.NotificationGroupName !== name) {
      dispatch(saveNotificationGroupAsync({ NotificationGroup_ID: currentGroupData.NotificationGroup_ID, Description: description, NotificationGroupName: name }));
      setlastSaveState(Loading);
    } else {
      // message.warning("")
    }
  }

  function goBack() {
    if (currentGroupData.Description === description && currentGroupData.NotificationGroupName === name) history.replace(NotificationGroup);
    // history.replace(handleURLRouteParameter(NotificationGroupRoute, NotificationGroupRoute));
    showDialog('Message', 'Are you sure go back with unsaved data?', () => {
      history.replace(handleURLRouteParameter(NotificationGroupRoute, ''));
    });
  }

  FetchData();
  return (
    <>
      <div className="wrapper cm-wrapper">
        <div className="contentWrap conditionMonitoring monitoringcontentWrap monitoringPage notification-group">
          <div className="panel panel-default alarmRows">
            <div
              className={`panel-heading pointer mb-5 ${expanded === 'general' ? 'active' : ''}`}
              onClick={() => setExpanded(expanded === 'general' ? '' : 'general')}
            >
              <h4 className="panel-title">
                <div data-toggle="collapse" data-parent="#accordion1">
                  <div className="back arrow" onClick={(e) => { e.preventDefault(); goBack(); }}>
                    <svg onClick={(e) => { e.preventDefault(); goBack(); }} width="32" height="32" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fillRule="evenodd" clipRule="evenodd" d="M14.9999 8.00014C14.9999 7.86753 14.9472 7.74036 14.8535 7.64659C14.7597 7.55282 14.6325 7.50014 14.4999 7.50014L2.70692 7.50014L5.85392 4.35414C5.90041 4.30766 5.93728 4.25247 5.96244 4.19173C5.9876 4.13099 6.00055 4.06589 6.00055 4.00014C6.00055 3.9344 5.9876 3.8693 5.96244 3.80856C5.93728 3.74782 5.90041 3.69263 5.85392 3.64614C5.80743 3.59966 5.75224 3.56278 5.6915 3.53762C5.63076 3.51246 5.56566 3.49951 5.49992 3.49951C5.43417 3.49951 5.36907 3.51246 5.30833 3.53762C5.24759 3.56278 5.1924 3.59966 5.14592 3.64614L1.14592 7.64614C1.09935 7.69259 1.06241 7.74776 1.0372 7.80851C1.012 7.86925 0.999023 7.93438 0.999023 8.00014C0.999023 8.06591 1.012 8.13103 1.0372 8.19178C1.06241 8.25252 1.09935 8.3077 1.14592 8.35414L5.14592 12.3541C5.1924 12.4006 5.24759 12.4375 5.30833 12.4627C5.36907 12.4878 5.43417 12.5008 5.49992 12.5008C5.56566 12.5008 5.63076 12.4878 5.6915 12.4627C5.75224 12.4375 5.80743 12.4006 5.85392 12.3541C5.90041 12.3077 5.93728 12.2525 5.96244 12.1917C5.9876 12.131 6.00055 12.0659 6.00055 12.0001C6.00055 11.9344 5.9876 11.8693 5.96244 11.8086C5.93728 11.7478 5.90041 11.6926 5.85392 11.6461L2.70692 8.50014H14.4999C14.6325 8.50014 14.7597 8.44747 14.8535 8.3537C14.9472 8.25993 14.9999 8.13275 14.9999 8.00014Z" fill="white" />
                    </svg>
                  </div>
                  <span className="row2">Notification Group Details</span>
                </div>
              </h4>
            </div>
            <div className={`panel-collapse collapse ${expanded === 'general' ? 'in' : ''}`}>
              <div className="panel-body">
                <div className={styles.ngContainer}>
                  <div />
                  <div className={styles.groupDetails}>
                    <div className={` ${styles.groupDetails}`}>
                      <div className="row">
                        <div className="col-md-4">
                          <div className="form-group" id="notification-group-name-text">
                            <label className="font12">Name</label>
                            <span className="redStar">*</span>
                            <input
                              name="textbox1"
                              type="text"
                              placeholder="Enter Name"
                              value={name}
                              onChange={OnNameChanged}
                              className={`form-control customForm input-md${errorFields.indexOf('name') !== -1 ? ' error-field' : ''}`}
                            />
                            <div className="enterpins placeColor">
                              <div className="pinErrorTxt">{errorTxt}</div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-8">
                          <div className="form-group">
                            <label className="font12">Description</label>
                            <textarea
                              id="textbox1"
                              name="textbox1"
                              placeholder="Enter Description"
                              value={description}
                              onChange={OnDescriptionChanged}
                              className="form-control customForm input-md"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="grid-header slick-carousel slickcarouselTop ">
                      <button type="button" className="pull-right btn btn-primary" onClick={onSaveBtnClicked}> Save </button>
                      <button type="button" className="pull-right btn btn-secondary" onClick={goBack}> Cancel </button>
                    </div>

                  </div>
                  {currentGroupData.NotificationGroup_ID
                    && (
                      <div className="row">
                        <div className="col-sm-12 contact-details">
                          <div className="row child-header">
                            <div className="col-sm-6"><label className="font12">Contact Details</label></div>
                            {' '}
                            <div className="col-sm-6">
                              <div>
                                <button type="button" className="pull-right btn btn-secondary add-entry-button" onClick={addEntryBtnClicked}>
                                  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5.99951 0.666504V11.3332" stroke="#5D97F6" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M0.666504 6H11.3332" stroke="#5D97F6" strokeLinecap="round" strokeLinejoin="round" />
                                  </svg>

                                  Add Entry
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className={`${styles.gridContainer} ${isActualData && data.length > 0 && currentGroupData.NotificationGroup_ID ? 'record present' : 'no-records'}`}>

                            <div className="alertDivsBg cntact">
                              <div className="alertDivs">
                                <div className={`headerTable  ${styles.headerTable}`}>
                                  <span className="row-1">Type</span>
                                  {/* <span className="row-2">Role</span> */}
                                  <span className="row-3">Name</span>
                                  <span className="row-4">Email</span>
                                  <span className="row-5">Phone Number</span>
                                  <span className="row-6">Actions</span>
                                </div>
                                {isActualData && data.length > 0 && currentGroupData.NotificationGroup_ID ? (
                                  <div
                                    className={`pannelWrap overflowScrolls common-scroll ${styles.fullHeight}`}
                                    id="pannelWrap"
                                  >
                                    {currentData.map((row, i) => (
                                      <div className="panel panel-default alarmRows" key={i}>
                                        <div className="panel-heading">
                                          <h4 className="panel-title">
                                            <div id={row.NotificationGroupDetail_ID} index={i}>
                                              <span className="row-1">{row.Type === 'U' ? 'Contact' : 'Role'}</span>
                                              {/* <span className="row-2">{row.Role}</span> */}
                                              <span className="row-3">{row.Name}</span>
                                              <span className="row-4">{row.EmailID}</span>
                                              <span className="row-5">{row.PhoneNumber}</span>
                                              <div className="row-6">
                                                <span onClick={onEditClicked}><Icon name="EditIcon" /></span>
                                                <span onClick={onDeleteClicked}><Icon name="DeleteIcon" /></span>
                                              </div>
                                            </div>
                                          </h4>
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                ) : load ? (
                                  <div className="pannelWrap overflowScrolls" id="pannelWrap">
                                    <div
                                      className="loaderHistoryAlarms"
                                    >
                                      <CircularIndeterminate />
                                    </div>
                                  </div>
                                ) : (
                                  // <div className="noHistoryAlarms">{Lang.NoRecord}</div>
                                  <div className="noHistoryAlarms">No Record Available</div>
                                )}
                              </div>
                              {data.length > defaultCardCount
                                && (
                                  <div className="alarm-pagination float-container">
                                    <div className="panellefttext">
                                      <div className="shownotext">
                                        {` ${startCount} to ${totalCount} of ${data.length} `}
                                      </div>
                                    </div>
                                    <div className="panelrighttext">
                                      <PaginationTab
                                        Previous={Previous}
                                        Next={Next}
                                        page={page}
                                        maxPage={maxPage}
                                      />
                                    </div>
                                  </div>
                                )}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                </div>
              </div>
            </div>
          </div>
          <div className="panel-body slick-carousel slickcarouselTop" />
        </div>
      </div>
      {editModel && (
        <EditGroupDetailsModal
          onClosed={() => setEditModel(false)}
          onSaved={OnGroupDetailSaveBtnClicked}
          groupDetails={selectedData}
          needsUpdate={needsUpdate}
          roles={roles}
          selecRoles={data !== 1 ? data.filter((e) => e.Type === 'R').map((e) => e.Role_ID) : []}
        />
      )}
      {showConfirmation.show
        && (
          <ConfirmationDialog
            onClosed={showConfirmation.onClose}
            title={showConfirmation.title}
            text={showConfirmation.text}
            onDoneClicked={showConfirmation.onDone}
          />
        )}
    </>
  );
};

export default NotificationGroup;
