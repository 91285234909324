import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getGeneralConditions, saveActiveStatus } from '../../components/ConditionMonitoring/general-condition/generalConditionViewApi';

const initialState = {
  generalConditions: {},
  status: 'idle',
};

export const getGeneralConditionsAsync = createAsyncThunk(
  'generalConditions/getGeneralConditions',
  async ({
    page, size, search, sortName, sortDirection,
  }) => {
    const response = await getGeneralConditions(page, size, search, sortName, sortDirection);
    console.log('ffff', response);
    return response;
  },
);

export const saveActiveStatusAsync = createAsyncThunk(
  'generalConditions/saveActiveStatus',
  async ({ id, status }) => {
    const request = await saveActiveStatus(id, status);
    return request;
  },
);

export const generalConditionsSlice = createSlice({
  name: 'generalConditions',
  initialState,
  reducers: {
    AddGeneralConditionViewStatus: (state, action) => {
      console.log('state==>', action);
      state.generalConditionsEditStatus = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getGeneralConditionsAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getGeneralConditionsAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.generalConditions = action.payload;
      })

      .addCase(saveActiveStatusAsync.pending, (state, action) => {
        state.status = 'loading';
        const { id, status } = action.meta.arg;
        const generalCondition = state.generalConditions.rows.find((r) => r.GeneralCondition_ID === id);
        generalCondition.IsActive = status;
      })
      // .addCase(saveActiveStatusAsync.fulfilled, (state, action) => {
      .addCase(saveActiveStatusAsync.fulfilled, (state) => {
        state.status = 'idle';
      });
  },
});
export const { AddGeneralConditionViewStatus } = generalConditionsSlice.actions;
export default generalConditionsSlice.reducer;
